/**
 *                        .::::.
 *                      .::::::::.
 *                     :::::::::::
 *                  ..:::::::::::'
 *               '::::::::::::'
 *                 .::::::::::
 *            '::::::::::::::..
 *                 ..::::::::::::.
 *               ``::::::::::::::::
 *                ::::``:::::::::'        .:::.
 *               ::::'   ':::::'       .::::::::.
 *             .::::'      ::::     .:::::::'::::.
 *            .:::'       :::::  .:::::::::' ':::::.
 *           .::'        :::::.:::::::::'      ':::::.
 *          .::'         ::::::::::::::'         ``::::.
 *      ...:::           ::::::::::::'              ``::.
 *     ````':.          ':::::::::'                  ::::..
 *                        '.:::::'                    ':'````..
 * 
 * ----------------------
 * |
 * |  常用工具类
 * |
 * ----------------------
 */

/**
 * 
 * 正则类
 * 
 */
export const regexp = {

  //  手机号
  phone(str) {
    if (!(/^1\d{10}$/.test(str))) {
      return false;
    } else {
      return true;
    }
  },
  //  邮箱
  email(str) {
    if (!(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(str))) {
      return false;
    } else {
      return true;
    }
  },
  //  纯数字
  allNumber(str) {
    if (!(/^[0-9]*$/.test(str))) {
      return false;
    } else {
      return true;
    }
  },
  //  正数 - (大于等于0的整数或小数)
  positiveNumber(str) {
    if (!(/^(0|[1-9][0-9]*)(\.\d+)?$/.test(str))) {
      return false;
    } else {
      return true;
    }
  },
  //  金额
  price(str) {
    if (!(/((^[1-9]\d*)|^0)(\.\d{1,2}){0,1}$/.test(str))) {
      return false;
    } else {
      return true;
    }
  },
  //  中文字符
  character(str) {
    if (!(/^([\u4e00-\u9fa5])$/.test(str))) {
      return false;
    } else {
      return true;
    }
  },
  //  身份证号
  IDCard(str) {
    if (!(/^\d{15}|\d{18}$/.test(str))) {
      return false;
    } else {
      return true;
    }
  },
  //  用户名 - (字母开头,4-16位,允许字母数字和下划线)
  username(str) {
    if (!(/^[a-zA-Z][a-zA-Z0-9_]{3,15}$/.test(str))) {
      return false;
    } else {
      return true;
    }
  },
  //  密码 - (6-18位,只允许字母数字和下划线)
  password(str) {
    if (!(/^[a-zA-Z0-9_]{6,18}$/.test(str))) {
      return false;
    } else {
      return true;
    }
  },

}

/**
 * 
 * 图片类
 * 
 */

export const image = {

  /**
  * 图片压缩
  * @params { base64:base64对象(必填)  width:宽度  height:高度  quality:质量  success:回调 }
  * @return void
  */
  compress(params) {
    const { base64, width, height, quality = 0.7, success = () => { } } = params;

    let img = new Image();
    img.src = base64;
    img.onload = function () {
      let that = this;

      let w = that.width,
        h = that.height,
        scale = w / h;
      w = width || w;
      h = height || (w / scale);

      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      let anw = document.createAttribute("width");
      anw.nodeValue = w;
      let anh = document.createAttribute("height");
      anh.nodeValue = h;
      canvas.setAttributeNode(anw);
      canvas.setAttributeNode(anh);
      ctx.drawImage(that, 0, 0, w, h);

      success(canvas.toDataURL('image/png', quality));
    }
  },

  /**
  * base64转file
  * @params dataurl:base64对象  filename:文件名
  * @return file
  */
  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },

  /**
  * base64转blob
  * @params dataurl:base64对象
  * @return blob
  */
  dataURLtoBlob(dataurl) {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  },

  /**
  * blob转base64
  * @params blob:blob对象 callback:回调
  * @return void
  */
  blobToDataURL(blob, callback) {
    let a = new FileReader();
    a.onload = function (e) { callback(e.target.result); }
    a.readAsDataURL(blob);
  }

}

/**
 * 
 * 时间类
 * 
 */

export const date = {

  /**
  * 时间戳转换为时间
  * @params timestamp:时间戳  13位需要*1000
  * @return string
  */
  timestampToTime(timestamp) {
    var date = new Date(timestamp)
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
    var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())

    var res = Y + M + D + h + m + s
    return res
  },

  /**
  * 获取当前时间
  * @params void
  * @return string
  */
  getDateTimeNow() {
    var time = new Date();
    var day = ("0" + time.getDate()).slice(-2)
    var month = ("0" + (time.getMonth() + 1)).slice(-2)

    var hour = ("0" + time.getHours()).slice(-2)
    var minute = ("0" + time.getMinutes()).slice(-2)
    var second = ("0" + time.getSeconds()).slice(-2)

    var today = time.getFullYear() + "-" + (month) + "-" + (day) + " " + (hour) + ":" + (minute) + ":" + second
    return today
  },

  /**
  * 获取指定天数时间
  * @params int:昨日-1 今日0 明日1 以此类推
  * @return array
  */
  getDay(day) {
    var weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    var today = new Date();
    var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(targetday_milliseconds); //注意，这行是关键代码
    var tYear = today.getFullYear();
    var tMonth = today.getMonth();
    var tDate = today.getDate();
    var tWeek = today.getDay()

    const doHandleMonth = (month) => {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    }

    tMonth = doHandleMonth(tMonth + 1);
    tDate = doHandleMonth(tDate);

    return [tYear, tMonth, tDate, weeks[tWeek]];
  },

  
  /**
   * 日期格式化（参考若依框架）
   * @params time:时间(时间对象、时间字符串、时间数字) pattern:时间格式(y|m|d|h|i|s|a)
   * @return 字符串
   */
   parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
      return null
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
        time = parseInt(time)
      } else if (typeof time === 'string') {
        time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
      }
      if ((typeof time === 'number') && (time.toString().length === 10)) {
        time = time * 1000
      }
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      // Note: getDay() returns 0 on Sunday
      if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
    return time_str
  },

}

/**
 *
 * 浏览器类
 *
 */

export const browser = {

  /**
  * 判断客户端pc,wap类型
  * @params void
  * @return int:1(PC) 0(WAP)
  */
  getClientType: function () {
    let sUserAgent = navigator.userAgent.toLowerCase();
    let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    let bIsMidp = sUserAgent.match(/midp/i) == "midp";
    let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    let bIsAndroid = sUserAgent.match(/android/i) == "android";
    let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
      return 0;
    } else {
      return 1;
    }
  },

  /**
   * 判断是不是微信内核
   * @params void
   * @return true false
   */
  changeWeChat() {
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
  },

  /**
  * 获取url参数
  * @params name:参数名
  * @return string | null
  */
  getLocationOption: function (name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null)
      return (r[2]);
    return null;
  },

  /**
  * 动态加载js脚本
  * @params src:地址 callback:加载完成回调
  * @return void
  */
  loadScript: function (src, callback) {
    var script = document.createElement('script');
    var head = document.getElementsByTagName('head')[0];
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.src = src;
    if (script.addEventListener) {
      script.addEventListener('load', function () {
        callback();
      }, false);
    } else if (script.attachEvent) {
      script.attachEvent('onreadystatechange', function () {
        var target = window.event.srcElement;
        if (target.readyState == 'loaded') {
          callback();
        }
      });
    }
    head.appendChild(script);
  },

  /**
   * 查询搜索记录
   * @params type:类别
   * @return Array
   */
  getSearchRecords: function (type = 0) {
    var list = JSON.parse(localStorage.getItem(`SEARCH-RECORDS-${type}`));
    !list && (list = []);
    return list;
  },

  /**
   * 添加搜索记录
   * @params type:类别 value:内容
   * @return Array
   */
  addSearchRecords: function (type = 0, value) {
    var list = JSON.parse(localStorage.getItem(`SEARCH-RECORDS-${type}`));
    !list && (list = []);
    list = list.slice(0, 9);
    list.unshift(value);
    list = [...new Set(list)];
    localStorage.setItem(`SEARCH-RECORDS-${type}`, JSON.stringify(list));
    return list;
  },

  /**
   * 清空搜索记录
   * @params type:类别
   * @return Array
   */
  emptySearchRecords: function (type = 0) {
    localStorage.removeItem(`SEARCH-RECORDS-${type}`)
    return [];
  }

}

/**
 * 
 * 其他类
 * 
 */

export const other = {

  /**
  * 将元素以中心点圆形排列坐标(常用于抽奖轮盘)
  * @params nodeSize:元素个数 center:圆心{x:1,y:1} radius:半径
  * @return array:排列坐标数据
  */
  calcCircularLayout(nodeSize, center, radius) {
    let i, _i, _layouts = [];
    for (i = _i = 0; _i < nodeSize; i = ++_i) {
      let x = center.x + radius * Math.sin(2 * Math.PI * i / nodeSize),
        y = center.y + radius * Math.cos(2 * Math.PI * i / nodeSize);

      _layouts.push({ 'x': x, 'y': y });
    }
    return _layouts;
  },

}




