<template>
  <div>
    <!-- 面包屑区域 -->
    <div class="wCrumbsArea">
      <!-- 面包屑导航 -->
      <div class="wCrumbs-box">
        <div class="wCrumbs-item" v-for="(item, index) in wCrumbs" :key="index">
          <div class="wCrumbs-line" v-if="index > 1">\</div>
          <div class="wCrumbs-text" :class="{ wActive: index == wCrumbs.length - 1 }"
            @click="wToCrumbsPage(index == 0 ? '' : item.path)" v-if="index != 0">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="message" @click="showMessage = true">
        <el-badge :value="orderList.length" :max="99">
          <i class="el-icon-bell"></i>
        </el-badge>
      </div>
    </div>

    <el-dialog title="订单实时消息通知" :visible.sync="showMessage" append-to-body width="80%" top="5vh">
      <div class="wDialogForm">
        <vue-scroll :ops="wVueScrollOps">
          <div class="wDialogForm-box">
            <el-table :data="orderList" border style="width: 100%">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div>
                    <el-table border :data="props.row.goodsList">
                      <el-table-column label="商品图片" align="center">
                        <template slot-scope="scope">
                          <el-image style="width: 50px; height: 50px" :src="scope.row.headImg" fit="cover"
                            :preview-src-list="[scope.row.headImg]"></el-image>
                        </template>
                      </el-table-column>
                      <el-table-column prop="goodsName" label="商品名称" align="center">
                      </el-table-column>
                      <el-table-column prop="price" label="单价（元）" align="center">
                      </el-table-column>
                      <el-table-column prop="num" label="数量" align="center">
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="orderName" label="订单名称" align="center">
              </el-table-column>
              <el-table-column prop="tableName" label="桌名" align="center">
              </el-table-column>
              <el-table-column prop="createTime" label="下单时间" align="center">
                <template slot-scope="scope">
                  <div>{{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
                </template>
              </el-table-column>
              <el-table-column label="用户" align="center">
                <template slot-scope="scope">
                  <div style="text-align: left" v-if="scope.row.userSnapshot">
                    <div style="display: flex; align-items: center">
                      <span>头像：</span>
                      <el-avatar size="small" :src="scope.row.userSnapshot.avatar"></el-avatar>
                    </div>
                    <div>昵称：{{ scope.row.userSnapshot.nickName }}</div>
                    <div>手机：{{ scope.row.userSnapshot.phone }}</div>
                    <div v-if="scope.row.userSnapshot.realName">
                      真实姓名：{{ scope.row.userSnapshot.realName }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="realMoney" label="订单金额(元)" align="center">
              </el-table-column>
              <el-table-column prop="couponName" label="优惠券" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.coupon">{{ scope.row.coupon.name }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="orderState" label="订单状态" align="center">
                <template slot-scope="scope">
                  <div>
                    <template v-for="(item, index) in orderState">
                      <span :key="index" v-if="scope.row.state == item.id">
                        <el-tag :type="(scope.row.state == 9 || scope.row.state == 11) ? 'warning' : 'success'"> {{
                          item.name }}</el-tag>
                      </span>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="支付方式" align="center">
                <template slot-scope="scope">
                  <div>
                    <div v-if="scope.row.payWay == 201">线下支付</div>
                    <div v-if="scope.row.payWay == 102">微信支付</div>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="操作"
                align="center"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      size="small"
                      v-if="scope.row.payWay == 201 && ( scope.row.state == 9 || scope.row.state == 10 )"
                      @click="wFun_confirmOrderById(scope.row.id,scope.row.storeId,scope.$index)"
                    >
                      <span style="color: #ff6321">线下支付确认收款</span>
                    </el-button>
                  </div>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </vue-scroll>
      </div>
    </el-dialog>

    <audio ref="audioTip" src="@/assets/tip.mp3" hidden></audio>
    <!-- 小票预览 -->
    <el-dialog title="小票预览" :visible.sync="printView" width="800px" append-to-body :close-on-click-modal="false"
      v-loading.fullscreen.lock="fullscreenLoading" @open="openEvnts">
      <span class="operation">
        <el-button @click="printView = false">返回</el-button>
        <!-- <el-button type="primary" v-print="printOption">确定打印</el-button> -->
        <!-- <el-button type="primary" @click="clickPrint" >确定打印</el-button> -->
      </span>
      <div id="printID" style="padding: 15px; font-size: 18px;">

        <div class="title" style="font-size: 30px;font-weight: bold;display: flex;justify-content: center;padding: 15px 0;">{{ printData.title }}</div>
        <div class="top-info">

          <div class="info-row" style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
            <div class="row-name" style="width: 100%;padding: 5px 0;">单号：{{ printData.id }}</div>
          </div>

          <div class="info-row" style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
            <div class="row-name" style="width: 50%;padding: 5px 0;">属性：{{ printData.identity }}</div>
          </div>

          <div class="info-row" style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
            <div class="row-name" style="width: 50%;padding: 5px 0;">服务员：{{ waiter }}</div>
            <div class="row-name" style="width: 50%;padding: 5px 0;">收银员：{{ cashier }}</div>
          </div>

          <div class="info-row" style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
            <div class="row-name" style="width: 50%;padding: 5px 0;">桌位：{{ printData.tableId }}</div>
            <div class="row-name" style="width: 50%;padding: 5px 0;">人数：{{ printData.dinersNum }}</div>
          </div>


          <div class="info-row" style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
            <div class="row-name" style="width: 100%;padding: 5px 0;">时间：{{ printData.createTime }}</div>
          </div>
        </div>
        <div class="goods" style="border-top: 1px solid;">
          <div class="goods-row" style="display: flex;flex-direction: row;align-items: center;text-align: center;justify-content: space-around;padding: 10px 0;">
            <div class="row-title" style="width: 220px;">菜品</div>
            <div class="row-title" style="width: 80px;">单价</div>
            <div class="row-title" style="width: 80px;">数量</div>
            <div class="row-title" style="width: 80px;">总额</div>
          </div>
          <!-- 非酒水 -->
          <div class="goods-list" v-for="(item, index) in printData.goodsListnojs" :key="index" style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;text-align: center;padding: 5px 0;">
            <div class="row-value" style="width: 220px ;">{{ item.goodsName }}</div>
            <div class="row-value" style="width: 80px;">{{ item.price }}</div>
            <div class="row-value" style="width: 80px;">{{ item.num }}</div>
            <div class="row-value" style="width: 80px;">{{ item.price * item.num }}</div>
          </div>
          <div style="border-bottom: 1px solid;"></div>
          <!-- 酒水 -->
          <div class="goods-list" v-for="(item_, index_) in printData.goodsListisjs" :key="index_+'a'" style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;text-align: center;padding: 5px 0;">
            <div class="row-value" style="width: 220px ;">{{ item_.goodsName }}</div>
            <div class="row-value" style="width: 80px;">{{ item_.price }}</div>
            <div class="row-value" style="width: 80px;">{{ item_.num }}</div>
            <div class="row-value" style="width: 80px;">{{ item_.price * item_.num }}</div>
          </div>
        </div>
        <div class="bottom-info" style="padding: 10px 0; border-top: 1px solid;">
          <div class="info-title" style="padding: 5px 0;" v-if="printData.identity == `会员`">优惠信息：指定酒水第2瓶半价</div>
          <div class="info-title" style="padding: 5px 0;" v-if="printData.identity == `VIP`">优惠信息：指定酒水第2瓶半价，第3瓶免费</div>
          <div class="info-title" style="padding: 5px 0;" v-if="printData.identity == `渠道代理`">优惠信息：指定酒水第2瓶半价，第3瓶免费</div>
          <div class="info-title" style="padding: 5px 0;" v-if="printData.identity == `社区团长`">优惠信息：指定酒水第2瓶半价，第3瓶免费</div>

          <div class="info-title" style="padding: 5px 0;">共计：{{ printData.totalMoney }}</div>
          <div class="info-title" style="padding: 5px 0;">优惠：{{ printData.amountDiscount }}</div>
          <div class="info-title" style="padding: 5px 0;">实付：{{ printData.realMoney }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import eventBus from "@/utils/event-bus";
import { confirmCashPayApi , getProductTypeApi } from "@/network/wApi.js";
import { getLodop } from "@/utils/LodopFuncs.js"

export default {
  data() {
    return {
      fullscreenLoading: false,
      printOption: {
        id: 'printID', // 打印元素的id 不需要携带#号
        type: "html", // 打印类型
        // preview: true, // 开启打印预览
        previewBeforeOpenCallback: () => {
          console.log("触发打印预览打开前回调");
        },
        previewOpenCallback: () => {
          console.log("触发打开打印预览回调");
        },
        beforeOpenCallback: () => {
          console.log("触发打印工具打开前回调");
        },
        openCallback: () => {
          console.log("触发打开打印工具回调");
        },
        closeCallback: () => {
          console.log("触发关闭打印工具回调");
        },
        clickMounted: () => {
          console.log("触发点击打印回调");
        }
      },
      printView: false,
      // 打印任务列表
      // printList:[],
      printStatus: false,
      printData: {
        id: "",
        createTime: "",
        tableId: "",
        dinersNum: "",
        identity: "",
        totalMoney: "",
        amountDiscount: "",
        realMoney: "",
        goodsListnojs: [],
        goodsListisjs:[]
      },
      showMessage: false,
      websock: null, //建立的连接
      lockReconnect: false, //是否真正建立连接
      timeout: 60 * 1000, //60秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时

      orderList: [],

      // 滚动条的配置
      wVueScrollOps: {
        scrollPanel: {
          scrollingX: false
        }
      },

      orderState: [
        {
          id: 9,
          name: "待支付"
        },
        {
          id: 10,
          name: "支付中"
        },
        {
          id: 11,
          name: "已完成"
        },
        {
          id: 12,
          name: "已退款"
        }
      ]
    };
  },
  computed: {
    
    // 服务员
    waiter() {
      return JSON.parse(localStorage.getItem('LOGININFO')).account
    },
    // 收银员
    cashier() {
      return JSON.parse(localStorage.getItem('LOGININFO')).account
    },
    // 面包屑导航
    wCrumbs() {
      // 所有路径
      let { routes } = this.$router.options;
      let wAdminRoutes = routes.find(value => {
        return value.path == "/admin";
      });
      let wAdminRoutesAll = wAdminRoutes.children;
      // console.log(wAdminRoutesAll);
      // 当前路径
      let wRoutePath = this.$route.path.replace("/admin/", "");
      // console.log(wRoutePath);

      // 面包屑导航生成
      let wCrumbsArr = [];
      let wRoutePathStr = "";
      for (let i = 0; i < wRoutePath.length; i++) {
        if (wRoutePath[i] == "/") {
          wCrumbsArr.push(wRoutePathStr);
        }
        wRoutePathStr = wRoutePathStr + wRoutePath[i];
        if (i == wRoutePath.length - 1) {
          wCrumbsArr.push(wRoutePathStr);
        }
      }
      // console.log(wCrumbsArr);

      let wCrumbsArr_new = [];
      for (let i = 0; i < wCrumbsArr.length; i++) {
        let wCrumbsObj = wAdminRoutesAll.find(value => {
          return value.path == wCrumbsArr[i];
        });
        if (wCrumbsObj) {
          wCrumbsArr_new.push({
            name: wCrumbsObj.meta.title,
            path: `/admin/${wCrumbsObj.path}`
          });
        }
      }
      // console.log(wCrumbsArr_new);
      return wCrumbsArr_new;

      // console.log(this.$route.matched);
      // let wCrumbsArr = this.$route.matched;
      // let wCrumbsArr_new = [];
      // for (let i = 0; i < wCrumbsArr.length; i++) {
      //   let { path: wRouter_path } = wCrumbsArr[i];
      //   // 判断路径是否是已"/admin/"开头,只有这个开头的路径才被纳入面包屑导航,面包屑导航的文本是路由的"meta.title"属性值
      //   if (wRouter_path.startsWith("/admin/")) {
      //     if (wCrumbsArr[i].meta.title) {
      //       wCrumbsArr_new.push({
      //         name: wCrumbsArr[i].meta.title,
      //         path: wRouter_path,
      //       });
      //     }
      //   }
      // }

      // return wCrumbsArr_new;
    }
  },
  created() {
    const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      if (userInfo.roleId == 7) {
        console.log("门店账户,连接websock")
        this.initWebSocket();
      } else {
        console.log("非门店账户,无需建立websock")
      }
  },
  methods: {

    
    /**
     * wFun_confirmOrderById
     * 线下支付确认收款
     */
    wFun_confirmOrderById(wP_id, storeId, index) {
      console.log(index);
      this.$confirm("此操作将更改该订单的状态, 是否继续?", "完成订单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 请求函数
          confirmCashPayApi({
            id: wP_id,
            storeId: storeId
          })
            .then(res => {
              this.$message.success("操作成功!");
              this.orderList[index].state = 11;
              eventBus.$emit("getOrderData");
            })
            .catch(err => { });
        })
        .catch(() => { });
    },
    initWebSocket() {
      // const wsuri = process.env.VUE_APP_WEBSOCKT + "/ws/websocket?token=";
      // const wsuri = "ws://47.108.214.112:9201" + "/api/websocket?token=";
      // const wsuri = "ws://192.168.1.2:9203" + "/websocket?token=";
      const wsuri = "ws://8.137.96.186:9201" + "/api/websocket?token=";
      // const wsuri = `wss://${location.host}` + "/cs/api/websocket?token=";

      //建立连接
      this.websock = new WebSocket(wsuri + localStorage.getItem("USER_TOKEN"));
      //连接成功
      this.websock.onopen = this.websocketonopen;
      //连接错误
      this.websock.onerror = this.websocketonerror;
      //接收信息
      this.websock.onmessage = this.websocketonmessage;
      //连接关闭
      this.websock.onclose = this.websocketclose;
    },
    //重新连接
    reconnect() {
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.initWebSocket();
        that.lockReconnect = false;
      }, 10000);
    },
    //重置心跳
    reset() {
      var that = this;
      //清除时间
      clearTimeout(that.timeoutObj);
      clearTimeout(that.serverTimeoutObj);
      //重启心跳
      that.start();
    },
    //开启心跳
    start() {
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (self.websock.readyState == 1) {
          //如果连接正常
          self.websock.send("heartCheck");
        } else {
          //否则重连
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function () {
          //超时关闭
          self.websock.close();
        }, self.timeout);
      }, self.timeout);
    },
    //连接成功事件
    websocketonopen() {
      console.log("WebSocket连接成功");
      //开启心跳
      this.start();
    },
    //连接失败事件
    websocketonerror(e) {
      //错误
      console.log("WebSocket连接发生错误");
      //重连
      this.reconnect();
    },
    //连接关闭事件
    websocketclose(e) {
      //关闭
      console.log("connection closed (" + e.code + ")");
      //重连
      this.reconnect();
    },
    //接收服务器推送的信息
    websocketonmessage(event) {
      console.log("收到信息",event)
      let data = JSON.parse(event.data);
      if (data.type == 1) {
        
      } else if (data.type == 2) {
        this.$nextTick(() => {
          this.$refs.audioTip.play(); // 播放语音
        });
        // 小票打印
        console.log("收到订单", data.body)
        // 定义一个ary空数组
        let ary = new Array()
        if (localStorage.getItem("printList") == null) {
          // 如果没有打印缓存,则创建
          localStorage.setItem("printList", JSON.stringify(ary))
        } else {
          // 否则将打印缓存数据赋值给空数组ary
          ary = JSON.parse(localStorage.getItem("printList"))
        }
        // 将收到的打印消息push到ary数组
        ary.push(data.body)
        // 将ary数据set到本地打印缓存
        localStorage.setItem("printList", JSON.stringify(ary))
        console.log("本地打印缓存",JSON.parse(localStorage.getItem("printList")))

        if (this.printStatus == false) {
          //如果当前没有打印任务则开启打印状态
          this.printStatus = true
          // 执行打方法
          this.printOrderList()
        }
        // 通知提示
        let instance = this.$notify({
          type: "info",
          title: "通知",
          message: "您有新的订单，请注意查收！",
          onClick: () => {
            if (this.$route.path == "/admin/order/list") {
              eventBus.$emit("getOrderData");
            } else {
              this.$router.push("/admin/order/list");
            }
            instance.close();
          }
        });
        this.orderList.unshift(JSON.parse(event.data).body);
      }

      //收到服务器信息，心跳重置
      this.reset();
    },
    //向服务器发送信息
    websocketsend(msg) {
      //数据发送
      this.websock.send(msg);
    },
    // 开始打印订单
    printOrderList(){
      // 当前打印订单数组
      const ary = JSON.parse(localStorage.getItem("printList")) // 打印任务列表
      console.log("开始打印")
      // 如果这个数组存在订单
      if (ary.length != 0) {
        console.log('准备打印的订单',ary[0])
        this.printOrder(ary[0])
      } else {
        this.printStatus = false // 打印状态关闭，准备接收下一个订单消息
        console.log("停止打印")
        return
      }
    },
    // 打印内容赋值
    printOrder(e) {
      // 临时存放订单信息
      this.fullscreenLoading = true
      // 获取用餐人数、用户身份
      this.getPrintData(e, res => {
        const t = new Date(e.createTime)
        const time = t.getFullYear() + `-` + (t.getMonth() + 1) + `-` + t.getDate() + ` ` + t.getHours()+ `:` + t.getMinutes() + `:` + t.getSeconds()
        // console.log("返回参数",res)
        this.fullscreenLoading = false
        this.printData.title = e.orderName
        this.printData.id = e.id
        this.printData.createTime = time
        this.printData.tableId = e.tableName
        this.printData.dinersNum = res.dinersNum
        this.printData.identity = res.identity
        this.printData.totalMoney = res.totalMoney  // 订单总计金额
        this.printData.amountDiscount = res.totalMoney - e.realMoney   // 优惠金额 = 订单总计金额 - 订单实付金额 
        this.printData.realMoney = e.realMoney      // 订单实付金额
        // 获取酒水分类id
        getProductTypeApi({orderId: e.id}).then(res=>{
          this.printData.goodsListnojs = []
          this.printData.goodsListisjs = []          
          if (res.length == 0) {
            // console.log('不包含酒水商品id',res)
            this.printData.goodsListnojs = JSON.parse(JSON.stringify(e.goodsList))
            this.printView = true
          } else {
            // console.log("包含酒水",res)
            const goodsAll = JSON.parse(JSON.stringify(e.goodsList))  //所有商品 
            let nojs       = JSON.parse(JSON.stringify(e.goodsList))  //非酒水商品  
            let isjs       = []                                       //酒水商品
            let isjsid     = []                                       //酒水商品id
            for (let i = 0; i < goodsAll.length; i++) {
              for (let j = 0; j < res.length; j++) {
                if (goodsAll[i].goodsId == res[j]) { //存在酒水商品
                  isjs.push(goodsAll[i])
                  isjsid.push(i)
                  break
                }
              }
            }
            // 删除所有商品中的酒水商品
            for (let m = 0; m < isjsid.length; m++) {             
              if (m == 0) {
                nojs.splice(isjsid[m],1)
              } else {                
                nojs.splice(isjsid[m]-m,1)  // 第一个数组元素删除后，接下来的元素下标依次-m
              }
            }
            // console.log("酒水商品",isjs,"非酒水商品",nojs)
            this.printData.goodsListnojs = nojs
            this.printData.goodsListisjs = isjs
            this.printView = true
          }
        })
      })
    },
    getPrintData(e, result) {
      // 处理用餐人数 & 获取总价 & !小票菜单分类排序
      let obj = { dinersNum: 0, identity: "", totalMoney: 0, goodsList: [] }
      let m = null
      const ary = e.goodsList
      // console.log("菜品", ary)
      for (let i = 0; i < ary.length; i++) {
        // 计算用餐人数
        if (ary[i].goodsName.includes(`餐具`)) {
          obj.dinersNum = ary[i].num
        }
        // 计算订单总价
        m = ary[i].price * ary[i].num
        obj.totalMoney = obj.totalMoney + m
      }
      // 查询用户身份
      this.$api.member_list({
        data: { phone: e.userSnapshot.phone },
        success: res => {
          obj.identity = res.records[0].identityName
          result(obj)
        }
      })
    },
    // 打开弹窗 开始打印 
    openEvnts(e){
      // 延迟0.4秒加载打印
      let LODOP = getLodop()
      setTimeout(() => {
        LODOP.PRINT_INIT(this.printData.id)
        LODOP.ADD_PRINT_HTM("10mm", "0mm", "75mm", "270mm", document.getElementById("printID").innerHTML);
        // LODOP.PREVIEW()
        LODOP.PRINT()
        let ary = JSON.parse(localStorage.getItem("printList"))
        for (let i = 0; i < ary.length; i++) {
          if (this.printData.id == ary[i].id) {
            console.log("删除前的打印列表",ary)
            // 删除已打印的订单
            ary.splice(i,1)
            console.log("删除后的打印列表",ary)
            // 重新set打印缓存
            localStorage.setItem("printList", JSON.stringify(ary))
            console.log("删除后的打印缓存",JSON.parse(localStorage.getItem("printList")))
            // 关闭打印弹窗
            this.printView = false
            // 执行下一个打印
            console.log("执行下一次打印")
            this.printOrderList()
            // 结束循环
            break
          }
        }
      }, 400);
    },

    /**
     * wToCrumbsPage
     * 跳转至面包屑导航页
     */
    wToCrumbsPage(wP_path) {
      // 跳转至面包屑导航页
      wP_path && this.$router.push({ path: wP_path });
    },
  },
  beforeDestroy() {
    // this.websocketclose();
    var that = this;
    //清除时间
    clearTimeout(that.timeoutObj);
    clearTimeout(that.serverTimeoutObj);
    if (this.websock) {
      this.websock.close();
      this.websock.onclose = evt => {
        console.log("websocket已关闭");
      };
    }
  }
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 面包屑区域
.wCrumbsArea {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
  padding: 0 16px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  // 面包屑导航
  .wCrumbs-box {
    display: flex;
    align-items: center;

    .wCrumbs-item {
      flex: none;
      display: flex;
      justify-items: center;

      .wCrumbs-line {
        flex: none;
        margin: 0 8px;
        color: #dddee1;
      }

      .wCrumbs-text {
        flex: none;
        font-size: @wFontSize;
        color: #495060;
        cursor: pointer;

        &:hover {
          color: @wColor_theme;
        }

        &.wActive {
          cursor: auto;
          font-weight: 700;
          color: #495060;
        }
      }
    }
  }

  .message {
    padding-right: 30px;
    cursor: pointer;

    .el-icon-bell {
      font-size: 26px;
    }
  }
}

.print {
  padding: 15px;
  font-size: 18px;

  .title {
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding: 15px 0;
  }

  .top-info {
    .info-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .row-name {
        width: 50%;
        padding: 5px 0;
      }
    }
  }

  .goods {
    border-top: 1px solid;

    .goods-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      justify-content: space-around;
      padding: 10px 0;


      .row-title {}
    }

    .goods-list {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      padding: 5px 0;

      .row-value {}
    }
  }

  .bottom-info {
    padding: 10px 0;
    border-top: 1px solid;

    .info-title {
      padding: 5px 0;
    }
  }

}

.wDialogForm {
  display: block;
  width: 100%;
  height: 550px;

  .wDialogForm-box {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
}
</style>