<template>
  <!-- 修改改密 -->
  <div>
    <div class="wPassword-page">
      <div class="wPWD-title">密码修改</div>
      <el-form
        ref="wPWD_form"
        status-icon
        :rules="wPWD_rules"
        :model="wPWD"
        label-width="100px"
      >
        <el-form-item label="账号:" prop="account">
          <el-input
            v-model="wPWD.account"
            placeholder="用户账号"
            style="width: 100%; max-width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="oldPwd">
          <el-input
            v-model="wPWD.oldPwd"
            placeholder="用户密码"
            show-password
            autocomplete="off"
            style="width: 100%; max-width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="newPwd">
          <el-input
            v-model="wPWD.newPwd"
            placeholder="用户新密码"
            show-password
            autocomplete="off"
            style="width: 100%; max-width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码:" prop="confirmPwd">
          <el-input
            v-model="wPWD.confirmPwd"
            placeholder="确认新密码"
            show-password
            autocomplete="off"
            style="width: 100%; max-width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('wPWD_form')">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { other_doEditPWD } from "@/network/wApi.js";

export default {
  data() {
    return {
      // 修改密码
      wPWD: {
        account: "", // 账号
        oldPwd: "", // 旧密码
        newPwd: "", // 新密码
        confirmPwd: "", // 确认密码
      },

      // 修改密码的表单验证
      wPWD_rules: {
        account: [
          { required: true, message: "请输入您的账户", trigger: "blur" },
        ],
        oldPwd: [
          { required: true, message: "请输入您的密码", trigger: "blur" },
        ],
        newPwd: [
          { required: true, message: "请输入您的新密码", trigger: "blur" },
        ],
        confirmPwd: [
          { required: true, message: "请确认您的新密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (this.wPWD.newPwd === value) {
                callback();
              } else {
                callback(new Error("两次输入密码不一致!"));
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 提交保存修改密码
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 请求参数
          let wData = {};

          // 获取值
          wData.account = this.wPWD.account;
          wData.oldPwd = this.wPWD.oldPwd;
          wData.newPwd = this.wPWD.newPwd;

          // 请求函数
          other_doEditPWD(wData).then((res) => {
            this.$message.success("修改成功!");
            // 清除登录
            localStorage.removeItem("USER_TOKEN");
            localStorage.removeItem("USER_INFO");
            localStorage.removeItem("printList");
            // 退出登录
            this.$router.replace({ name: "login" });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

.wPassword-page {
  display: block;
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  user-select: none;

  .wPWD-title {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: bolder;
    color: #333333;
    margin-bottom: 15px;
  }
}
</style>