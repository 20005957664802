<template>
  <div>
    <!-- 登录页面 -->
    <div class="wLogin-page">
      <div class="wLogin-main">
        <div class="wLogin-form">
          <div class="wLoginForm-box">
            <div class="wLF-head">后台管理系统</div>
            <!-- 手机号 -->
            <div class="wLF-block">
              <div class="wLF-inputBox">
                <div class="wLF-inputIconBox">
                  <img class="wLF-inputIcon" src="@/assets/image/ldhg_login_icon001.png" alt="图标" />
                </div>
                <div class="wLF-inputLine"></div>
                <input class="wLF-input" type="text" placeholder="账号" v-model="wAccount" />
              </div>
            </div>
            <!-- 密码 -->
            <div class="wLF-block">
              <div class="wLF-inputBox">
                <div class="wLF-inputIconBox">
                  <img class="wLF-inputIcon" src="@/assets/image/ldhg_login_icon002.png" alt="图标" />
                </div>
                <div class="wLF-inputLine"></div>
                <input class="wLF-input" type="password" placeholder="密码" v-model="wPassword" />
              </div>
            </div>
            <!-- 记住密码 -->
            <div class="wLF-block">
              <div class="wLF-remember">
                <!-- wActive 控制是否选择记住密码 -->
                <div class="wLFR-selcetBox" :class="{ wActive: wRemember }" @click="wDoRememberPassword">
                  <i class="el-icon-check"></i>
                </div>
                <div class="wLFR-text" @click="wDoRememberPassword">
                  记住密码
                </div>
              </div>
            </div>
            <!-- 登录按钮 -->
            <div class="wLF-block">
              <div class="wLF-submitBtn zAnimationBtn" @click="wDoSubmitLogin">
                登录
              </div>
            </div>
          </div>
        </div>
        <div class="wLogin-imgBox">
          <img class="wLogin-img" src="@/assets/image/ldhg_login_img001.png" alt="图片" draggable="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { other_queryUserInfo } from "@/network/wApi.js";
export default {
  name: "login",
  data() {
    return {
      // 账户
      wAccount: "",
      // 密码
      wPassword: "",
      // 是否记住密码
      wRemember: false
    };
  },
  mounted() {
    // 获取缓存中的账号密码信息
    let loginInfo = localStorage.getItem("LOGININFO");
    // 存在缓存信息
    if (loginInfo) {
      loginInfo = JSON.parse(loginInfo);
      this.wAccount = loginInfo.account;
      this.wPassword = loginInfo.password;
      this.wRemember = loginInfo.password ? true : false;
    } else {
      this.wAccount = "";
      this.wPassword = "";
      this.wRemember = false;
    }
    console.log(this.$router);
  },
  methods: {
    /**
     * wDoRememberPassword
     * 记住密码
     */
    wDoRememberPassword() {
      this.wRemember = !this.wRemember;
    },

    /**
     * wDoSubmitLogin
     * 登录
     */
    wDoSubmitLogin() {
      // 账号
      if (!this.wAccount) {
        this.$message.error("请输入账号");
        return false;
      }
      // 密码
      if (!this.wPassword) {
        this.$message.error("请输入密码");
        return false;
      }

      // 请求函数
      // let param = new URLSearchParams();
      // param.append("account", this.wAccount);
      // param.append("pwd", this.wPassword);
      this.$api.login_login({
        data: {
          account: this.wAccount,
          pwd: this.wPassword
        },
        success: res => {
          console.log("denglu", res)
          // 数据处理
          // 侧边导航
          // let wSidebarArr = [];
          // res.navigation &&
          //   res.navigation.forEach((element) => {
          //     wSidebarArr.push(element);
          //   });
          // 记住账号密码
          let loginInfo = {
            nickName: res.name,
            userImgHP: "",
            account: this.wAccount,
            password: this.wRemember ? this.wPassword : ""
            // wSidebarArr,
          };
          // // 侧边导航
          // this.$store.commit("sidebarArr", wSidebarArr);
          // 存入缓存
          localStorage.setItem("LOGININFO", JSON.stringify(loginInfo));
          // 存入用户token
          localStorage.setItem("USER_TOKEN", res.token);

          // 缓存当前账号信息
          other_queryUserInfo().then((res) => {
              // console.log(res)
              localStorage.setItem("USER_INFO", JSON.stringify(res));

              // 进入首页
              this.$router.replace("/");
          });

          // 进入首页
          // this.$router.replace("/");
          // // 登录成功
          // if (window.history.length <= 1) {
          //   // 进入首页
          //   this.$router.replace("/");
          // } else {
          //   // 返回上一页
          //   this.$router.go(-1);
          // }

        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 登录页面
.wLogin-page {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  user-select: none;

  .wLogin-main {
    flex: none;
    display: flex;
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 25px 0 rgba(225, 232, 238, 0.7);
    border-radius: 10px;
    overflow: hidden;

    .wLogin-form {
      flex: none;
      padding: 0 100px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;

      .wLoginForm-box {
        flex: none;

        .wLF-head {
          display: block;
          width: 100%;
          height: 67px;
          font-size: 32px;
          font-weight: 500;
          color: #ff6321;
          line-height: 67px;
          letter-spacing: 1px;
          margin-bottom: 30px;
          text-align: center;
        }

        .wLF-block {
          display: block;
          width: 300px;
          margin: 0 auto 15px;

          // 输入框
          .wLF-inputBox {
            display: flex;
            width: 100%;
            height: 60px;
            align-items: center;
            background-color: #f3f3f3;
            border-radius: 5px;

            .wLF-inputIconBox {
              flex: none;
              display: flex;
              width: 60px;
              justify-content: center;
              align-items: center;

              .wLF-inputIcon {
                flex: none;
                width: 20px;
              }
            }

            .wLF-inputLine {
              flex: none;
              width: 1px;
              height: 26px;
              background-color: #d6d6d6;
            }

            .wLF-input {
              flex: auto;
              height: 50px;
              min-width: 0;
              border: none;
              outline: none;
              background-color: #f3f3f3;
              padding: 0 20px;
              box-sizing: border-box;
              font-size: 18px;

              &::placeholder {
                font-size: 18px;
              }
            }
          }

          // 记住密码
          .wLF-remember {
            display: flex;
            align-items: center;

            .wLFR-selcetBox {
              flex: none;
              width: 16px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              border: 1px solid #979797;
              cursor: pointer;
              margin-right: 10px;
              font-size: 0;

              &.wActive {
                color: @wColor_theme;
                // border-color: @wColor_theme;
                font-size: 16px;
              }
            }

            .wLFR-text {
              flex: none;
              height: 30px;
              font-size: 16px;
              font-weight: 400;
              color: #a1a1a1;
              line-height: 30px;
              letter-spacing: 1px;
              cursor: pointer;
            }
          }

          // 登录按钮
          .wLF-submitBtn {
            display: block;
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            border-radius: 25px;
            background-color: @wColor_theme;
            box-shadow: 0px 2px 10px 0px @wColor_theme;
            font-size: 20px;
            font-weight: 600;
            color: #ffffff;
            letter-spacing: 5px;
            cursor: pointer;

            // 修改按钮动画
            &.zAnimationBtn:hover:before {
              transform: translateX(350px) rotate(45deg);
            }

            &.zAnimationBtn:hover {
              border-color: @wColor_theme;
            }
          }
        }
      }
    }

    .wLogin-imgBox {
      flex: none;
      width: 500px;
      height: 500px;

      .wLogin-img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>