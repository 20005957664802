import Vue from 'vue'
import Vuex from 'vuex'
// Vuex 依赖 Promise (opens new window)。如果你支持的浏览器并没有实现 Promise (比如 IE)，那么你可以使用一个 polyfill 的库，例如 es6-promise (opens new window)。
import 'es6-promise/auto'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true, // 严格模式
  state: {
    // 请求引用计数
    loading: false,
    loadingCount: 0,

    // 侧边导航二级导航开与关
    sidebarClassB: false,
    // 侧边导航数据
    sidebarArr1: [
      // {
      //   id: "column_01", // 与后台数据控制标量，column_01-会员,column_02-商品,column_03-门店,column_04-活动,column_05-订单,column_06-运营,column_07-统计,column_08-设置,
      //   icon: "el-icon-s-tools", // 一级导航的图标
      //   index: "set", // 唯一标志 eg: http://localhost:8080/admin/set/index 这个地址中的admin后面的set
      //   title: "设置", // 一级导航的文字
      //   // 子导航，总共层级不超过二级，没有这没有这个字段
      //   subs: [
      //     {
      //       index: "index", // 唯一标志 eg: http://localhost:8080/admin/set/index 这个地址中的admin后面的set后面的index
      //       title: "测试栏目", // 导航内容
      //     },
      //   ],
      // },
      {
        icon: 'el-icon-user-solid',
        index: 'member',
        title: '会员',
        subs: [
          {
            index: 'list',
            title: '会员列表'
          },
          {
            index: 'withdrawal',
            title: '提现审核'
          },
          {
            index: 'withdrawalList',
            title: '提现列表'
          }
          // {
          //   index: "level",
          //   title: "会员等级"
          // },
        ]
      },
      {
        icon: 'el-icon-shopping-bag-1',
        index: 'goods',
        title: '商品',
        subs: [
          {
            index: 'list',
            title: '商品列表'
          },
          {
            index: 'category',
            title: '商品类别'
          }
          // {
          //   index: "menu",
          //   title: "菜单设置",
          // },
        ]
      },
      {
        icon: 'el-icon-s-shop',
        index: 'store',
        title: '门店',
        subs: [
          {
            index: 'list',
            title: '门店列表'
          },
          {
            index: 'staffManage',
            title: '员工管理'
          }
        ]
      },
      {
        icon: 'el-icon-present',
        index: 'activity',
        title: '活动',
        subs: [
          {
            index: 'spellList',
            title: '拼团列表'
          }
          // {
          //   index: 'spellSet',
          //   title: '拼团设置',
          // },
        ]
      },
      {
        icon: 'el-icon-s-order',
        index: 'order',
        title: '订单',
        subs: [
          {
            index: 'list',
            title: '订单列表'
          },
          {
            index: 'site',
            title: '订单设置'
          },
          {
            index: 'service',
            title: '退款服务'
          }
          // {
          //   index: 'reason',
          //   title: '退款设置',
          // }
        ]
      },
      {
        icon: 'el-icon-data-line',
        index: 'operate',
        title: '运营',
        subs: [
          {
            index: 'adlist',
            title: '广告列表'
          }
        ]
      },
      {
        icon: 'el-icon-s-data',
        index: 'statistics',
        title: '统计',
        subs: [
          {
            index: 'transaction',
            title: '交易统计'
          }
        ]
      },
      {
        icon: 'el-icon-s-tools',
        index: 'set',
        title: '设置',
        subs: [
          {
            index: 'role',
            title: '角色管理'
          },
          {
            index: 'user',
            title: '用户管理'
          },
          {
            index: 'menu',
            title: '菜单管理'
          }
        ]
      }
    ],
    sidebarArr: [],

    // 分页配置
    wPage: {
      wLayout: 'total, sizes, prev, pager, next, jumper', // 组件布局，子组件名用逗号分隔
      wPageSizes: [5, 10, 15, 20, 50] // 每页显示个数选择器的选项设置
    }
  },
  mutations: {
    // axios引用计数
    axiosRequest(state, b) {
      b ? state.loadingCount++ : state.loadingCount--
      state.loadingCount === 0 ? (state.loading = false) : (state.loading = true)
    },

    // 更新左边导航数组
    sidebarArr(state, wP_arr) {
      state.sidebarArr = wP_arr
    },

    // 侧边导航开和关,wP_truthy —— 1-true,其他-false
    sidebarClassB(state, wP_truthy) {
      if (wP_truthy) {
        if (wP_truthy == 1) {
          state.sidebarClassB = true
        } else {
          state.sidebarClassB = false
        }
      } else {
        state.sidebarClassB = !state.sidebarClassB
      }
    }
  },
  actions: {},
  modules: {}
})
