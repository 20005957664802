<template>
  <!-- 商品列表 -->
  <div>
    <!-- 页面布局 -->
    <!-- <wPageCrumbs> -->
    <!-- 筛选查询 -->
    <div class="wML-screen">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>筛选查询</span>
        </div>
        <!-- 筛选列表 -->
        <div class="wMLS-list">
          <el-form label-position="left" label-width="90px" :model="wScreen">
            <el-row :gutter="24">
              <!-- 关键字 -->
              <el-col :span="6">
                <el-form-item label="关键字">
                  <el-input style="width: 100%;" type="text" v-model="wScreen.search" placeholder="请输入关键字" clearable
                    @input="changeInputSearch"></el-input>
                </el-form-item>
              </el-col>
              <!-- 店铺名称 -->
              <el-col :span="6">
                <el-form-item label="店铺名称" prop="storeId">
                  <el-select v-model="wScreen.storeId" placeholder="请选择店铺" :clearable="clearableStoreId"
                    :disabled="disabledStoreId" @change="changeSelectStoreId" style="width: 100%;">
                    <el-option v-for="(item, index) in optionsStore" :key="item.value" :label="item.label"
                      :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 商品分类 -->
              <el-col :span="6">
                <el-form-item label="菜品分类">
                  <el-select v-model="wScreen.categoryId" :placeholder="placeholderCategory" clearable
                    :disabled="disabledCategory" @change="changeSelectCategory" style="width: 100%;">
                    <el-option v-for="item in optionsCategory" :key="item.value" :label="item.label"
                      :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 商品状态 -->
              <el-col :span="6">
                <el-form-item label="菜品状态">
                  <el-select v-model="wScreen.state" placeholder="请选择状态" clearable @change="changeSelectState"
                    style="width: 100%;">
                    <el-option v-for="item in optionsState" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

          </el-form>
          <!-- 操作按钮 -->
          <el-row type="flex" justify="space-between">
            <el-col :span="20">
              <el-button size="medium" @click="resetwScreen" v-if="showView"> 重置 </el-button>
              <el-button size="medium" @click="submitwScreen"> 查询 </el-button>

            </el-col>
            <div>
              <el-button type="primary" @click="addGoods" v-if="showView">添加菜品</el-button>
            </div>

          </el-row>
        </div>
      </el-card>
    </div>
    <!-- 商品列表区域 -->
    <div class="wGoodsList-area">
      <!-- 数据列表 -->
      <div class="wGL-goods">
        <div class="wGLG-table">
          <el-table :data="tableGoods" border style="width: 100%;">
            <el-table-column label="菜品名称" prop="name"> </el-table-column>
            <el-table-column label="菜品分类" prop="categoryId" align="center" width="140">
              <template slot-scope="scope">
                <div v-for="(item, index) in goodsCategory" :key="index" v-if="scope.row.categoryId == item.value">
                  {{ item.label }}</div>
              </template>
            </el-table-column>
            <el-table-column label="商品类型" align="center" width="140">
              <template slot-scope="scope">
                <div v-for="(item, index) in goodsType" :key="index" v-if="scope.row.atype == item.value">{{ item.label
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="店铺" prop="storeId">
              <template slot-scope="scope">
                <div v-for="(item, index) in optionsStore" :key="index" v-if="scope.row.storeId == item.value">
                  {{ item.label }}</div>
              </template>
            </el-table-column>
            <el-table-column label="图片" width="100" align="center">
              <template slot-scope="scope">
                <el-image style="width:80px;height:80px;" fit="contain" :src="scope.row.headImg"
                  :preview-src-list="[scope.row.headImg]"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="价格" prop="retailPrice" width="140" align="center"></el-table-column>
            <el-table-column label="销量" prop="sales" width="140" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.sales">{{ scope.row.sales }}</span>
                <span v-else>0</span>
              </template>
            </el-table-column>
            <el-table-column label="排序" prop="sort" width="140" align="center"></el-table-column>
            <el-table-column label="限购数量" width="140" align="center">
              <template slot-scope="scope" v-if="scope.row.atype == 3">
                <el-input v-model="scope.row.groupHotSaleNum" :disabled="true" v-if="scope.row.isGroupHotSale" />
                <el-input v-model="scope.row.groupHotSaleNum" type="number" :min="0" placeholder="请设置数量" v-else />
              </template>
            </el-table-column>
            <el-table-column label="限购" align="center" width="140">
              <template slot-scope="scope"
                v-if="scope.row.atype == 3 && (scope.row.groupHotSaleNum && scope.row.groupHotSaleNum >= 1)">
                <el-switch v-model="scope.row.isGroupHotSale" :active-value="1" :inactive-value="0"
                  :active-text="scope.row.isGroupHotSale ? '开启' : '关闭'" @change="changeIsGroupHotSale(scope)" />
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center" width="140">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.state" :active-value="1" :inactive-value="0"
                  :active-text="scope.row.state ? '已上架' : '已下架'" @change="changeGoodsState(scope)" />
              </template>
            </el-table-column>
            <!-- <el-table-column label="发布时间" prop="createTime"></el-table-column> -->
            <!-- <el-table-column label="供应商联系方式" prop="supplierPhone"></el-table-column> -->
            <!-- <el-table-column label="备注" prop="remark"></el-table-column> -->
            <el-table-column label="操作" align="center" width="140" v-if="showView">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="updateGoods(scope)"><span>修改</span></el-button>
                <el-button type="text" size="small" @click="deleteGoods(scope)"><span>删除</span></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="wPagination">
          <el-pagination @current-change="changePaginationCurrent" @size-change="changePaginationSize"
            :current-page="pagination.current" :page-sizes="pagination.sizes" :page-size="pagination.size"
            :total="pagination.total" layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 添加菜品 -->
    <el-dialog width="1000px" append-to-body :title="title" :visible.sync="visibleAddGoods"
      :close-on-press-escape="false" :close-on-click-modal="false">
      <el-form :rules="rulesAddGoods" ref="formAddGoods" :model="formAddGoods" label-width="80px">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="商品类型" prop="atype">
              <el-select v-model="formAddGoods.atype" placeholder="请选择菜品类型" @change="changeSupplyCategory" clearable
                style="width: 100%;">
                <el-option v-for="(item, index) in goodsType" :key="item.value" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所在门店" prop="storeId">
              <el-select v-model="formAddGoods.storeId" placeholder="请选择门店" clearable @change="changeSelectStoreId"
                style="width: 100%;">
                <el-option v-for="(item, index) in optionsStore" :key="item.value" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="菜品分类" prop="categoryId">
              <el-select v-model="formAddGoods.categoryId" :placeholder="placeholderCategory" clearable
                @change="changeSelectCategory" :disabled="disabledCategory" style="width: 100%;">
                <el-option v-for="item in optionsCategory" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜品售价" prop="retailPrice">
              <el-input v-model="formAddGoods.retailPrice" type="number" placeholder="请输入菜品名称" maxlength="8"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="菜品名称" prop="name">
              <el-input v-model="formAddGoods.name" type="text" placeholder="请输入菜品名称" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜品序号" prop="sort">
              <el-input v-model="formAddGoods.sort" type="number" placeholder="请输入菜品名称" maxlength="8"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="菜品规格" prop="unit">
              <div style="display: flex;flex-direction: row;">
                <el-input v-model="unitNum" type="number" placeholder="请输入数量" @input="changeSelectInput"
                  maxlength="8"></el-input>
                <el-select v-model="goodsUnit" placeholder="请选择单位" @change="changeSelectUnit" style="width:100px">
                  <el-option v-for="(item, index) in optionsUnits" :key="item.index" :label="item.label"
                    :value="item.value" />
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜品简介" prop="details">
              <el-input v-model="formAddGoods.details" type="textarea" placeholder="请输入内容" autosize maxlength="600"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="运费金额" prop="freight">
              <el-input v-model="formAddGoods.freight" type="text" placeholder="请输入运费" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <!-- <el-row :gutter="24" v-if="onShowSkuList">
          <el-col :span="6">
            <el-form-item :label="`规格${index + 1}`" label-width="48px" prop="'name'"
              v-for="(item, index) in formAddGoods.skuList" :key="item.key">
              <el-input v-model="item.name" type="text" placeholder="请输入规格名称" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="原价" label-width="40px" prop="'membershipPrice'"
              v-for="(item, index) in formAddGoods.skuList" :key="item.key">
              <el-input v-model="item.membershipPrice" type="number" placeholder="原价" maxlength="8"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="现价" label-width="40px" prop="'price'" v-for="(item, index) in formAddGoods.skuList"
              :key="item.key">
              <el-input v-model="item.price" type="number" placeholder="现价" maxlength="8"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="vip价" label-width="45px" prop="'vipPrice'"
              v-for="(item, index) in formAddGoods.skuList" :key="item.key">
              <el-input v-model="item.vipPrice" type="number" placeholder="vip价" maxlength="8"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="'库存'" label-width="40px" prop="'stock'" class="specification"
              v-for="(item, index) in formAddGoods.skuList" :key="item.key">
              <el-input v-model="item.stock" type="number" placeholder="库存" maxlength="8">
              </el-input>
              <el-button @click.prevent="removeSpecification(item)">删除</el-button>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <el-button type="primary" @click="addSpecification">添加规格</el-button>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="菜品主图" prop="headImg">
              <el-upload ref="mainUpload" list-type="picture-card" :limit="limitMainImg" :file-list="mainFileList"
                :class="{ hide: hideMainUpload }" :action="getUploadUrl()" :before-upload="uploadBefore"
                :on-success="uploadSuccessMain" :on-remove="uploadRemoveMain" :show-file-list="true">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col>
            <el-form-item label="菜品配图">
              <el-upload ref="nextUpload" list-type="picture-card" :limit="limitNextImg" :file-list="nextFileList"
                :class="{ hide: hideNextUpload }" :action="getUploadUrl()" :before-upload="uploadBefore"
                :on-success="uploadSuccessNext" :on-remove="uploadRemoveNext" :show-file-list="true">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleAddGoods = false">取 消</el-button>
        <el-button type="primary" @click="submitAddGoods('formAddGoods')">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getClassify, getSupplyOptions} from "@/network/api.js";

import { goods_GCEditGoods, goods_GCAddGoods, goods_GCRemoveGoodsById, goods_GCGetGoodsInfo, pub_fileUpload_file, changeGroupHotSaleApi } from '@/network/wApi';

export default {
  components: {
  },
  data() {
    return {
      // 菜品筛选表单
      wScreen: {
        search: null,             // 关键字
        categoryId: null,         // 菜品分类
        state: null,              // 菜品状态
        storeId: null,            // 所属店铺
      },

      // 菜品所属店铺options
      optionsStore: [],
      clearableStoreId: true, // 是否可清空
      disabledStoreId: false, // 是否禁用
      // 菜品分类options
      optionsCategory: [],
      placeholderCategory: "请先选择商品类型和门店",
      disabledCategory: true, // 是否禁用
      // 菜品状态options
      optionsState: [{ label: "全部", value: "" }, { label: "已上架", value: "1" }, { label: "未上架", value: "0" }],

      // 商品类型
      goodsType: [
        // { label: "堂食商品", value: 1 },
        // { label: "供应链商品", value: 3 }
      ],
      // 所有菜品分类（遍历商品列表中对应的分类id）
      goodsCategory: [],
      // 菜品数据列表
      tableGoods: [],
      // 分页配置
      pagination: {
        current: 1,
        size: 20,
        sizes: [10, 20, 50, 100],
        total: 0,
      },

      // 添加&修改 菜品
      title: "",                // 标题
      visibleAddGoods: false,   // 弹出层状态
      visibleSupply: false,     // 是否选择商品类型
      formAddGoods: {           // 表单
        storeId: "",
        categoryId: "",
        name: "",
        retailPrice: "",
        atype: "",
        sort: "",
        unit: "",
        details: "",
        headImg: "",
        imgList: [],
        freight: 0,
        skuList: [{
          name: '', //规格
          membershipPrice: 0, //原价
          price: 0, //现价
          vipPrice: 0, //vip价
          stock: 0, //库存
        }]
      },
      onShowSkuList: false,
      // 表单校验
      rulesAddGoods: {
        storeId: [{ required: true, message: '请选择所属门店', trigger: 'blur' }],
        // categoryId: [{ required: true, message: '请选择商品分类', trigger: 'blur' }],
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        retailPrice: [{ required: true, message: '请输入商品售价', trigger: 'blur' }],
        atype: [{ required: true, message: '请选择商品类型', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入商品排序', trigger: 'blur' }],
        unit: [{ required: true, message: '请选择商品规格', trigger: 'blur' }],
        // details: [{ required: true, message: '请输入菜品简介', trigger: 'blur' }],
        headImg: [{ required: true, message: '请上传商品主图', trigger: 'blur' }],
      },
      optionsUnits: [           // 菜品规格下拉框
        { label: "份", value: "份" },
        { label: "位", value: "位" },
        { label: "锅", value: "锅" },
        { label: "碗", value: "碗" },
        { label: "盒", value: "盒" },
        { label: "条", value: "条" },
        { label: "根", value: "根" },
        { label: "盘", value: "盘" },
        { label: "桶", value: "桶" },
        { label: "包", value: "包" },
        { label: "袋", value: "袋" },
        { label: "件", value: "件" },
        { label: "箱", value: "箱" },
        { label: "罐", value: "罐" },
        { label: "瓶", value: "瓶" },
        { label: "吨", value: "吨" },
        { label: "斤", value: "斤" },
        { label: "两", value: "两" },
        { label: "克", value: "克" },
        { label: "升", value: "升" }
      ],
      unitNum: 1,
      goodsUnit: "份",

      mainFileList: [],         // 主图
      limitMainImg: 1,          // 上传数量限制
      hideMainUpload: false,    // 是否隐藏上传按钮

      nextFileList: [],         // 详情图
      limitNextImg: 4,          // 上传数量限制
      hideNextUpload: false,    // 是否隐藏上传按钮
      // 操作按钮
      showView: false,
    };
  },
  created() {
    this.changePermission()
  },

  methods: {
    // 权限判断
    changePermission() {
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      if (userInfo.roleId == 7) {
        console.log("门店账户")
        this.showView = false
        this.clearableStoreId = false             // 是否可清空
        this.disabledStoreId = true               // 是否禁用
        this.disabledCategory = false             // 开启分类下拉框状态
        this.wScreen.storeId = userInfo.storeId   // 门店id筛选参数
        this.getOptionsCategoryAll()              // 获取所有门店商品分类
        this.getSupplyOptionsAll()                // 获取所有商品类型
        this.getOptionsStore()                    // 获取门店列表
        this.getOptionsCategory(userInfo.storeId) // 获取门店分类
        this.getGoodsData(this.wScreen)           // 获取商品数据
      } else {
        console.log("非门店账户")
        this.showView = true
        this.getOptionsCategoryAll()  // 获取所有门店商品分类
        this.getSupplyOptionsAll()    // 获取所有商品类型
        this.getOptionsStore()        // 获取门店列表
        this.getGoodsData()           // 获取商品列表
      }
    },
    /**
     * 获取菜品数据
    */
    getGoodsData(e) {
      // console.log("请求参数",e)
      this.$api.goods_GCList({
        data: e,
        success: res => {
          console.log("菜品列表", res.records)
          this.tableGoods = res.records
          this.pagination.total = res.total
        }
      })
    },
    /**
     * 获取门店数据
    */
    getOptionsStore() {
      let obj = {
        "pageModel.pageNo": 1,
        "pageModel.pageSize": 2000,
      }
      this.$api.store_list({
        data: obj,
        success: res => {
          // console.log("门店列表", res.records)
          let ary = []
          for (let i = 0; i < res.records.length; i++) {
            const item = {
              label: res.records[i].storeName,
              value: res.records[i].id
            }
            ary.push(item)
          }
          this.optionsStore = ary
        }
      })
    },
    /**
     * 获取分类列表
    */
    getOptionsCategoryAll() {
      // 获取所有分类(用于遍历商品列表数据分类对应名称)
      this.$api.goods_GCGetCategory({
        success: res => {
          let ary = []
          for (let i = 0; i < res.length; i++) {
            const item = {
              label: res[i].name,
              value: res[i].id
            }
            ary.push(item)
          }
          this.goodsCategory = ary
        }
      })
    },
    /**
     * 获取商品类型列表
     */
    getSupplyOptionsAll() {
      // 获取所有分类(用于遍历商品类型数据分类对应名称)
      getSupplyOptions().then(res => {
        console.log(res);
        let ary = []
        for (let i = 0; i < res.length; i++) {
          const item = {
            label: res[i].name,
            value: res[i].id
          }
          ary.push(item)
        }
        this.goodsType = ary
      })
    },
    getOptionsCategory(e) {
      let obj = { storeId: e }
      // 根据门店获取
      getClassify(obj).then(res => {
        let ary = []
        for (let i = 0; i < res.length; i++) {
          const item = {
            label: res[i].name,
            value: res[i].id
          }
          ary.push(item)
        }
        this.optionsCategory = ary
      })
    },

    /**
     * 筛选下拉框
    */
    changeInputSearch(e) {
      this.submitwScreen()
    },
    changeSupplyCategory(e) {
      console.log(e);
      this.onShowSkuList = true
      if (e == 16) {
        this.visibleSupply = true
        this.onShowSkuList = false
      }
    },
    changeSelectStoreId(e) {      // 选择菜品所属店铺
      console.log("门店", e)
      // 如果门店id不存在，并且弹出层为false
      if (!e && this.visibleAddGoods == false) {
        this.wScreen.categoryId = null          // 筛选分类id赋为null
        this.placeholderCategory = "请先选择门店"
        this.disabledCategory = true             // 禁用分类选择下拉框
        this.submitwScreen()                    // 请求提交筛选
      }
      // 如果门店id不存在，并且弹出层为true
      if (!e && this.visibleAddGoods == true && this.visibleSupply) {
        this.formAddGoods.categoryId = null     // 筛选分类id赋为null
        this.placeholderCategory = "请先选择门店和商品类型"
        this.disabledCategory = true             // 禁用分类选择下拉框
      }
      // 如果门店id存在，并且弹出层为false
      if (e && this.visibleAddGoods == false) {
        this.wScreen.categoryId = null
        this.placeholderCategory = "请选择分类"
        this.disabledCategory = false           // 开启分类选择下拉框
        this.getOptionsCategory(e)              // 获取这个门店的分类
        this.submitwScreen()                    // 请求提交筛选
      }
      // 如果门店id存在，并且弹出层为true
      if (e && this.visibleAddGoods == true && this.visibleSupply == false) {
        this.formAddGoods.categoryId = null     // 筛选分类id赋为null
        this.placeholderCategory = "只有堂食商品才需要分类"
        this.disabledCategory = true           // 开启分类选择下拉框
      }
      if (e && this.visibleAddGoods == true && this.visibleSupply == true) {
        this.formAddGoods.categoryId = null     // 筛选分类id赋为null
        this.placeholderCategory = "请选择分类"
        this.disabledCategory = false           // 开启分类选择下拉框
        this.getOptionsCategory(e)              // 获取这个门店的分类
      }
    },

    changeSelectCategory(e) {     // 选择菜品分类
      this.submitwScreen()
    },

    changeSelectState(e) {        // 选择菜品状态
      this.submitwScreen()
    },


    /**
     * 筛选操作
    */
    // 重置筛选表单
    resetwScreen() {
      this.resetPagination()          // 重置分页参数
      this.wScreen.search = null,     // 关键字
        this.wScreen.storeId = null,    // 所属店铺
        this.wScreen.categoryId = null, // 菜品分类
        this.wScreen.state = null,      // 菜品状态
        this.wScreen["pageModel.pageNo"] = this.pagination.current
      this.wScreen["pageModel.pageSize"] = this.pagination.size

      this.placeholderCategory = "请先选择门店"
      this.disabledCategory = true
      this.goods_GCAddGoods(this.wScreen)
    },
    // 提交筛选表单
    submitwScreen() {
      if (this.visibleAddGoods == false) {
        this.resetPagination()        // 重置分页参数
        this.wScreen["pageModel.pageNo"] = this.pagination.current
        this.wScreen["pageModel.pageSize"] = this.pagination.size
        this.getGoodsData(this.wScreen)
      }
    },
    /**
     * 商品数据操作
    */
    changeGoodsState(e) {     // 商品上架下架
      this.$api.goods_GCUpdateState({
        data: {
          goodsId: e.row.id
        },
        success: res => {
          // console.log("商品状态修改",res)
          if (res) {
            this.$message.success('商品上架成功')
          } else {
            this.$message.info('商品已下架')
          }
        },
      })
    },
    changeIsGroupHotSale(e) {

      changeGroupHotSaleApi({ goodsId: e.row.id, isGroupHotSale: e.row.isGroupHotSale, groupHotSaleNum: e.row.groupHotSaleNum }).then(res => {
        console.log("默认", res)
      })


    },
    resetFormAddGoods() {         // 重置表单
      this.hideMainUpload = false
      this.hideNextUpload = false
      this.mainFileList = []
      this.nextFileList = []
      this.unitNum = 1,
        this.goodsUnit = "份"
      this.formAddGoods = {
        storeId: null,
        categoryId: null,
        name: null,
        retailPrice: null,
        atype: null,
        sort: null,
        unit: null,
        details: null,
        headImg: "",
        imgList: [],
        freight: 0,
        skuList: [{
          name: '', //规格
          membershipPrice: 0, //原价
          price: 0, //现价
          vipPrice: 0, //vip价
          stock: 0, //库存
        }]
      }
    },
    // 编辑菜品单位选择
    changeSelectInput(e) {
      // console.log(e)
      this.formAddGoods.unit = e + this.goodsUnit.toString()
    },
    changeSelectUnit(e) {
      // console.log(e)
      this.formAddGoods.unit = this.unitNum.toString() + e
    },
    /**
     *  添加商品
    */
    addGoods() {
      this.title = "添加菜品"
      this.resetFormAddGoods()                  // 重置表单
      // 如果筛选门店id存在
      if (this.wScreen.storeId && this.wScreen.categoryId) {
        this.formAddGoods.storeId = this.wScreen.storeId
        this.formAddGoods.categoryId = this.wScreen.categoryId
      }
      if (this.wScreen.storeId && !this.wScreen.categoryId) {
        this.formAddGoods.storeId = this.wScreen.storeId
        this.placeholderCategory = "请选择分类"
        this.disabledCategory = false            // 开启分类选择下拉框
      }
      if (!this.wScreen.storeId) {
        this.formAddGoods.categoryId = null     // 筛选分类id赋为null
        this.placeholderCategory = "请先选择门店"
        this.disabledCategory = true             // 禁用分类选择下拉框
      }
      // 填充单位表单
      this.formAddGoods.unit = this.unitNum.toString() + this.goodsUnit.toString()
      this.visibleAddGoods = true
    },
    /**
     *  修改商品
    */
    updateGoods(e) {
      // 重置表单
      this.resetFormAddGoods()
      if (e.row.state == 1) {
        this.$message.error('请先下架商品再修改！')
        return
      } else {
        this.title = "修改菜品"
        // 获取商品详情参数
        goods_GCGetGoodsInfo({ goodsId: e.row.id }).then(res => {
          // console.log('菜品信息',res)
          // 获取当前商品所属门店的分类下拉框
          this.getOptionsCategory(res.storeId)
          console.log(res);
          if(res.atype != 16){
            this.onShowSkuList = true
          }

          // 表单赋值
          this.formAddGoods.id = res.id
          this.formAddGoods.atype = res.atype
          this.formAddGoods.categoryId = res.categoryId
          this.formAddGoods.details = res.details
          this.formAddGoods.name = res.name
          this.formAddGoods.retailPrice = res.retailPrice
          this.formAddGoods.sort = res.sort
          this.formAddGoods.storeId = res.storeId
          this.formAddGoods.freight = res.freight
          if (res.skuList && res.skuList.length > 0) {
            this.formAddGoods.skuList = res.skuList
          }
          if (res.headImg) {                                  // 主图处理
            let mainImg = [{ url: res.headImg }]
            this.mainFileList = mainImg                       // 主图上传预览赋值
            this.formAddGoods.headImg = res.headImg           // 主图上传表单赋值
            if (mainImg.length >= this.limitMainImg) {        // 如果商品主图超过上传最大数量则隐藏上传按钮
              this.hideMainUpload = true
            }
          }
          if (res.imgList) {                                  // 详情图处理
            let nextImg = []
            for (let i = 0; i < res.imgList.length; i++) {
              const obj = { url: res.imgList[i] }
              nextImg.push(obj)
            }
            this.nextFileList = nextImg                       // 详情图上传预览赋值
            this.formAddGoods.imgList = res.imgList           // 详情图上传表单赋值
            if (nextImg.length >= this.limitNextImg) {        // 如果详情图超过上传最大数量则隐藏上传按钮
              this.hideNextUpload = true
            }
          }
          // 如果商品单位存在则拆解单位，否则默认值 1份
          if (res.unit) {
            const m = res.unit.length
            this.unitNum = e.row.unit.slice(0, m - 1)
            this.goodsUnit = e.row.unit.slice(m - 1, m)
            // 填充单位表单
            this.formAddGoods.unit = this.unitNum.toString() + this.goodsUnit.toString()
          }
          this.placeholderCategory = "请选择分类"
          this.disabledCategory = false          // 开启分类选择下拉框
          this.visibleAddGoods = true            // 打开修改弹窗
        })
      }
    },
    deleteGoods(e) {             // 删除商品
      console.log(e.row.id)
      goods_GCRemoveGoodsById({ goodsId: e.row.id }).then(res => {
        this.$message.success('删除成功！')
        this.submitwScreen()
      })
    },
    /**
     * 文件上传
    */
    getUploadUrl() { return `${process.env.VUE_APP_URE}/file/uploadFile` },
    // 上传之前的钩子
    uploadBefore(file) {
      this.loading = true
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG 或者 PNG 格式!')
      }
      return isJPG || isPNG
    },
    // 上传成功回调
    uploadSuccessMain(file, fileList) {
      let obj = { url: file.data }
      this.mainFileList.push(obj)           // push已经上传的图片
      this.formAddGoods.headImg = file.data
      if (this.mainFileList.length >= this.limitMainImg) {
        this.hideMainUpload = true          // 隐藏上传按钮
      }
      this.$refs.mainUpload.clearFiles()    // 清空上传的文件列表
    },
    uploadSuccessNext(file, fileList) {
      let obj = { url: file.data }
      this.nextFileList.push(obj)           // push已经上传的图片
      this.formAddGoods.imgList.push(file.data)
      if (this.nextFileList.length >= this.limitNextImg) {
        this.hideNextUpload = true          // 隐藏上传按钮
      }
      this.$refs.nextUpload.clearFiles()    // 清空上传的文件列表
    },
    // 移除文件
    uploadRemoveMain(file) {
      console.log(file)
      for (let i = 0; i < this.mainFileList.length; i++) {
        if (this.mainFileList[i].url == file.url) {
          this.mainFileList.splice(i, 1)
          this.formAddGoods.headImg = null
          this.hideMainUpload = false
        }
      }
    },
    uploadRemoveNext(file) {
      console.log(file)
      for (let i = 0; i < this.nextFileList.length; i++) {
        if (this.nextFileList[i].url == file.url) {
          this.nextFileList.splice(i, 1)
          for (let j = 0; j < this.formAddGoods.imgList.length; j++) {
            if (this.formAddGoods.imgList[j] == file.url) {
              this.formAddGoods.imgList.splice(j, 1)
            }
          }
          this.hideNextUpload = false
        }
      }
    },
    /**
     * 提交
    */
    submitAddGoods(formAddGoods) {
      this.$refs[formAddGoods].validate((valid) => {
        if (valid) {
          // this.formAddGoods.headImg = this.formAddGoods.headImg[0].url.toString()  // 主图转字符串
          if (!this.formAddGoods.id) {
            // this.$message.success('添加成功！')
            console.log(this.formAddGoods)
            goods_GCAddGoods(this.formAddGoods).then(res => {
              this.$message.success('添加成功！')
              this.submitwScreen()
              this.visibleAddGoods = false
            })
          } else {
            // this.$message.success('修改成功！')
            console.log(this.formAddGoods)
            goods_GCEditGoods(this.formAddGoods).then(res => {
              this.$message.success('修改成功！')
              this.submitwScreen()
              this.visibleAddGoods = false
            })
          }
        } else {
          this.$message.error('请完善表单内容！')
          return false;
        }
      });
    },
    /**
     * 分页操作
    */
    resetPagination() {
      this.pagination.current = 1   // 重置分页参数
      // this.pagination.size = 20     // 重置分页参数
    },
    changePaginationCurrent(e) {
      this.pagination.current = e
      this.wScreen["pageModel.pageNo"] = this.pagination.current
      this.wScreen["pageModel.pageSize"] = this.pagination.size
      this.getGoodsData(this.wScreen)
    },
    changePaginationSize(e) {
      this.pagination.current = 1
      this.pagination.size = e
      this.wScreen["pageModel.pageNo"] = this.pagination.current
      this.wScreen["pageModel.pageSize"] = this.pagination.size
      this.getGoodsData(this.wScreen)
    },

    /**
     * 添加规格
    */
    addSpecification() {
      if (this.formAddGoods.skuList.length < 3) {
        this.formAddGoods.skuList.push({
          name: '', // 规格
          membershipPrice: 0, // 原价
          price: 0, // 现价
          vipPrice: 0, // VIP价
          stock: 0, // 库存
          key: Date.now()
        });
      } else {
        this.$message({
          message: '最多只能添加3个规格',
          type: 'warning'
        });
      }
    },
    removeSpecification(item) {
      let index = this.formAddGoods.skuList.indexOf(item)
      if (index !== -1) {
        this.formAddGoods.skuList.splice(index, 1)
      }
    }
  }
};
</script>

<style lang="less">
@import "@/assets/css/publicStyle.less";

.hide .el-upload--picture-card {
  display: none !important;
}

.wML-screen {
  display: block;
  width: 100%;
  user-select: none;
  margin-bottom: 20px;

  // 筛选列表
  .wMLS-list {
    display: block;
    width: 100%;
  }
}

// 商品列表区域
.wGoodsList-area {
  display: block;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  background-color: #ffffff;

  // 商品类别
  .wGL-categoryArea {
    display: flex;
    justify-content: space-between;

    .wGL-category {
      flex: none;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      user-select: none;
      margin-bottom: 15px;

      .wGLC-item {
        flex: none;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        margin-right: 10px;
        box-sizing: border-box;
        background-color: #f3f3f3;
        // border: 1px solid #999999;
        font-size: @wFontSize;
        cursor: pointer;
        transition: all 0.3s;

        &:hover,
        &.wActive {
          background-color: @wColor_theme;
          border-color: @wColor_theme;
          color: #ffffff;
        }
      }
    }
  }

  // 商品状态
  .wGL-state {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    margin-bottom: 15px;

    .wGLS-category {
      flex: none;
      display: flex;
      align-items: center;

      .wGLSC-btn {
        flex: none;
        margin-right: 15px;
        cursor: pointer;
        transition: all 0.3s;
        font-size: @wFontSize;
        color: #333333;

        &:hover,
        &.wActive {
          color: @wColor_theme;
          transition: all 0.3s;
        }
      }
    }

    .wGLS-operate {
      flex: none;
      display: flex;
      align-items: center;

      .wGLSO-btn {
        flex: none;
        margin-right: 7px;
        cursor: pointer;
        transition: all 0.3s;
        font-size: @wFontSize;
        color: #333333;

        &:hover {
          color: @wColor_theme;
          transition: all 0.3s;
        }
      }
    }
  }

  // 数据列表
  .wGL-goods {
    display: block;
    width: 100%;

    // table表
    .wGLG-table {
      display: block;
      width: 100%;
    }

    // 分页
    .wGLG-page {
      display: block;
      width: 100%;
      margin-top: 20px;
      text-align: center;
    }
  }
}

// 职务信息表单
.wDialogForm {
  display: block;
  width: 100%;
  height: 500px;
  position: relative;

  .wDialogForm-box {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
}

.specification {
  .el-form-item__content {
    display: flex !important;
  }
}
</style>