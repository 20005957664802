<template>
  <div>
    <!-- 页面布局 -->
    <div class="wPageCrumbs">
      <!-- 面包屑 -->
      <div class="wPCrumbs-crumbs" ref="wCrumbs">
        <wCrumbs></wCrumbs>
      </div>
      <!-- 主体内容 -->
      <div
        id="wPCrumbsMain"
        class="wPCrumbs-main"
        :class="{ isShow: isShow }"
        :style="{ top: `${wPos_top}px` }"
      >
        <!-- <vue-scroll id="vueScroll"> -->
        <div class="wPageContainer">
          <slot></slot>
        </div>
        <!-- </vue-scroll> -->
      </div>
    </div>
  </div>
</template>

<script>
import wCrumbs from "@/components/public/Crumbs.vue";
export default {
  components: {
    wCrumbs,
  },
  data() {
    return {
      wPos_top: 0,
      isShow: false,
    };
  },
  mounted() {
    // 计算头部的高度并且定位
    this.wPos_top = this.$refs.wCrumbs.offsetHeight || 0;

    // 延迟显示，错开二级导航展开动画时间导致的主体内容宽度初始化计算失误（正对element ui 的部分组件）
    let setT = setTimeout(() => {
      this.isShow = true;
      clearTimeout(setT);
    }, 300);
  },
};
</script>

<style lang="less" scoped>
// 页面布局
.wPageCrumbs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  // 面包屑
  .wPCrumbs-crumbs {
    display: block;
    width: 100%;
  }

  // 主体内容
  .wPCrumbs-main {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: all 0.3s;
    overflow: auto;

    &.isShow {
      display: block;
      opacity: 1;
      transition: all 0.3s;
    }
  }
}
</style>