<template>
  <div>
    <!-- 监听打印 -->
    <wCrumbs v-if="!noStore" />
    <!-- 首页头部 -->
    <div class="wHeader-area" ref="wHeader">
      <wHeader01></wHeader01>
    </div>
    <!-- 首页内容 -->
    <div class="wBodyer-area" :style="{ top: wPos_top + 'px' }" v-if="noStore">
      <!-- 欢迎页 -->
      <div class="wWelcome-box">
        <img class="wWelcome-img" src="@/assets/image/default/welcome.gif" alt="图片" draggable="false" />
      </div>
    </div>
    <!-- 门店桌子 -->
    <div v-else class="root">
      <div class="list">
        <div class="list-wrapper">
          <div class="list-item" id="item" v-for="(item, index) in tableList" :key="index">
            <div class="item-top" @click="clickTable({ data: item, index: index })" v-if="item.isBooking == 0"
              style="background-color: #ff6600;color:#fff">{{ item.tableName }}</div>
            <div class="item-top" @click="$message({ message: '请开桌后点击查看' })" v-else>{{ item.tableName }}</div>
            <div class="item-bottom" v-if="item.isBooking == 0"
              style="background-color: rgba(255, 102, 0, 0.2);color:#ff6600">
              <div>
                <!-- <i class="el-icon-s-custom" style="margin-right: 5px;" /> {{item.number}}人 -->
              </div>
              <el-popconfirm @confirm="closeTable({ data: item, index: index })" title="是否确定结束用餐？">
                <el-button type="primary" size="mini" slot="reference">结束</el-button>
              </el-popconfirm>
            </div>
            <div class="item-bottom" v-else style="background-color: rgba(101, 191, 56, 0.2);color: #67c23a">
              <div>
                <!-- <i class="el-icon-s-custom" style="margin-right: 5px;" /> {{item.number}}人 -->
              </div>
              <el-button @click="openTable({ data: item, index: index })" type="success" size="mini"
                slot="reference">开桌</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 查看桌子详情 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="400px" :append-to-body="true"
      :close-on-press-escape="false" :close-on-click-modal="false">
      <div>
        <div><i class="el-icon-s-custom" style="margin-right: 5px;" />用餐人数：{{ count }}</div>
        <el-table :data="goodsList" style="width: 100%">
          <el-table-column prop="name" label="菜品" width=""></el-table-column>
          <el-table-column prop="unitPrice" label="单价" width="80" align="center"></el-table-column>
          <el-table-column prop="num" label="数量" width="80" align="center"> </el-table-column>
          <!-- <el-table-column prop="amount"    label="金额" width="80" align="center"></el-table-column> -->
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">结束用餐</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import wCrumbs from "@/components/public/Crumbs.vue";
import wHeader01 from "@/components/Header01.vue";
import { getStoreTableApi, alterStoreTableApi, queryOrderByTableApi } from "@/network/wApi.js";
export default {
  components: {
    wHeader01,
    wCrumbs
  },
  data() {
    return {
      wPos_top: 0,
      noStore: true,
      // 桌子数据
      tableList: [],
      // 弹出层状态
      dialogVisible: false,
      // 弹出层标题
      title: "",
      // 弹出层菜品
      goodsList: [],
      // 首页内容定位
      count: "",
      nowTableId: ""
    };
  },
  mounted() {
    // 计算头部的高度并且定位
    this.wPos_top = this.$refs.wHeader.offsetHeight || 0;
    this.initPages()

    // // 定义一个ary空数组
    // let ary = new Array()
    // if (localStorage.getItem("printList") == null) {
    //   // 如果没有打印缓存,则创建
    //   localStorage.setItem("printList", JSON.stringify(ary))
    // } else {
    //   // 否则将打印缓存数据赋值给空数组ary
    //   ary = JSON.parse(localStorage.getItem("printList"))
    // }
    // // 将收到的打印消息push到ary数组
    // ary.push({id:"123",status: false})
    // // 将ary数据set到本地打印缓存
    // localStorage.setItem("printList", JSON.stringify(ary))
    // console.log("本地打印缓存",JSON.parse(localStorage.getItem("printList")))
  },
  destroyed() {

  },
  watch: {

  },
  methods: {
    // 初始化门店信息
    initPages() {
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      if (userInfo.roleName == '门店') {
        this.noStore = false
        // 获取桌子信息
        getStoreTableApi({ store: userInfo.storeId }).then(res => {
          let ary = []
          for (let i = 0; i < res.length; i++) {
            const obj = {
              tableName: res[i].tableName,
              tableId: res[i].id,
              number: '0',
              isBooking: res[i].isBooking,
              bingDate: res[i].bingDate
            }
            ary.push(obj)
          }
          this.tableList = ary
        })
        // console.log("是门店账户")
      } else {
        this.noStore = true
        // console.log("不是门店账户")
      }
    },
    // 修改桌子状态
    alterStoreTable(e) {
      alterStoreTableApi(e).then(res => {
        if (res == '修改成功') {
          this.initPages()
          this.dialogVisible = false
        }
      })
    },
    // 获取桌子对应订单菜品
    clickTable(e) {
      console.log(e)
      this.title = e.data.tableName
      this.nowTableId = e.data.tableId
      this.dialogVisible = true
      queryOrderByTableApi({ tableId: e.data.tableId, bingDate: e.data.bingDate }).then(res => {
        // console.log("桌子菜品",res)
        let count = 0
        let ary = []
        for (let i = 0; i < res.length; i++) {
          // 计算用餐人数
          if (res[i].goodsName.includes(`餐具`)) {
            count =  count + res[i].num
          }
          const obj = {
            name: res[i].goodsName,
            unitPrice: res[i].price,
            num: res[i].num
          }
          ary.push(obj)
        }
        this.count = count
        this.goodsList = ary
      })
    },
    // 开桌
    openTable(e) {
      const obj = {
        tableId: e.data.tableId,
        type: 0
      }
      this.alterStoreTable(obj)
      this.$message.success("开桌成功!");
    },
    // 结束
    closeTable(e) {
      const obj = {
        tableId: e.data.tableId,
        type: 1
      }
      this.alterStoreTable(obj)
      this.$message.info("结束用餐!");
    },
    // 确定提示
    confirm() {
      this.$confirm('是否继续确认结束用餐？')
        .then(_ => {
          const obj = {
            tableId: this.nowTableId,
            type: 1
          }
          this.alterStoreTable(obj)
        })
        .catch(_ => {

        });
    },
  }
};
</script>

<style lang='less' scoped>
.root {
  margin: 15px 5px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .list {
    width: 100%;
    flex: 1;
    height: 0;
    overflow: auto;

    .list-wrapper {
      display: flex;
      flex-wrap: wrap;

      .list-item:hover {
        border-radius: 8px;
      }

      .list-item {
        width: 150px;
        margin: 0 calc(4% / 6 / 2) 15px calc(4% / 6 / 2);

        .item-top {
          background-color: #e2e2e2;
          padding: 10px;
          height: 60px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        .item-bottom {
          background-color: #cccccc;
          padding: 5px 10px;
          height: 30px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}

.wHeader-area {
  display: block;
  width: 100%;
}

.wBodyer-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.wWelcome-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #ffffff;

  .wWelcome-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>