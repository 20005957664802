<template>
  <!-- 角色管理 -->
  <div>
    <div class="wSet-role">
      <div class="wSR-head">
        <el-button type="primary" @click="wDoOpenFrame('add')">新增</el-button>
      </div>
      <div class="wSR-body">
        <el-table :data="wRole.list" style="width: 100%">
          <el-table-column prop="sysRole.name" label="角色"> </el-table-column>
          <el-table-column label="操作" width="300" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                :disabled="scope.row.sysRole.isAdmin == 1"
                @click="wDoOpenFrame('edit', scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                :disabled="scope.row.sysRole.isAdmin == 1"
                @click="wDoDelete(scope.row.sysRole.id)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 角色添加弹框 -->
    <el-dialog
      :title="wFrameAdd.type == 0 ? '新增' : '编辑'"
      :visible.sync="wFrameAdd.isShow"
      width="400px"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form ref="wFrameAdd" :model="wFrameAdd.form" label-width="100px">
        <el-form-item label="角色名称:" prop="name">
          <el-input
            v-model="wFrameAdd.form.name"
            placeholder="角色名称"
            style="width: 100%; max-width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色权限:" prop="menu">
          <template>
            <!-- 通过 v-if重新渲染 -->
            <el-tree
              ref="tree"
              :data="wMenuArr"
              :show-checkbox="true"
              node-key="id"
              :accordion="true"
              :default-checked-keys="wFrameAdd.form.menu"
              :props="wFrameAdd.defaultProps"
              v-if="wFrameAdd.isShow"
            >
            </el-tree>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="wDoSaveMenu()">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  set_queryMenuAll,
  set_queryRoleAll,
  set_doRoleAdd,
  set_doRoleEdit,
  set_doRoleDelete,
} from "@/network/wApi.js";

export default {
  data() {
    return {
      // 字典-菜单列表
      wDic_menu: [],

      // 角色列表
      wRole: {
        list: [], // 数据
      },

      // 新增弹框
      wFrameAdd: {
        isShow: false, // 是否展示
        type: 0, // 弹框类型，默认0-新增，1-编辑修改
        form: {
          cid: 0, // 当前栏目id,默认0-新增
          name: "", // 角色昵称
          menu: [], // 选中的菜单列表数据
        }, // 表单数据
        defaultProps: {
          children: "children",
          label: "label",
        },
      },
    };
  },
  computed: {
    // 菜单列表数据集合
    wMenuArr: function () {
      let wDicMenu = this.wDic_menu;
      let wMenuArr = wDicMenu.map((item) => {
        let obj = {};
        obj.id = item.id;
        obj.label = item.title;
        obj.children = item.subs.map((item01) => {
          return {
            id: item01.id,
            label: item01.title,
          };
        });
        return obj;
      });
      return wMenuArr;
    },
  },
  mounted() {
    Promise.allSettled([this.wDicMenu()]).then((results) => {
      // 查询角色列表数据
      this.wQueryRoleList();
    });
  },
  methods: {
    /**
     * wDicMenu
     * 获取字典-菜单数据
     */
    wDicMenu() {
      return new Promise((resolve, reject) => {
        // 请求函数
        set_queryMenuAll()
          .then((res) => {
            // 更新数据
            this.wDic_menu = res;
            resolve("success");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * wQueryRoleList
     * 查询角色列表数据
     */
    wQueryRoleList() {
      // 请求函数
      set_queryRoleAll().then((res) => {
        this.wRole.list = res;
      });
    },

    /**
     * wDoOpenFrame
     * 打开新增编辑菜单弹框
     * wP_type 类型,
     * wP_info 修改的信息
     */
    wDoOpenFrame(wP_type, wP_info) {
      // 打开弹框
      this.wFrameAdd.isShow = true;
      // 清空数据
      this.wFrameAdd.form.menu = [];

      this.$nextTick(() => {
        // 对该表单项进行重置
        this.$refs["wFrameAdd"].resetFields();
        // 清空数据
        // this.$refs.tree.setCheckedKeys([]);

        // 数据处理
        switch (wP_type) {
          case "add":
            // 新增
            {
              this.wFrameAdd.type = 0;
            }
            break;
          case "edit":
            // 修改
            {
              if (!wP_info) {
                this.wFrameAdd.isShow = false;
                this.$message.error("系统繁忙，请稍后再试!");
                return;
              }
              // 赋值
              this.wFrameAdd.type = 1;
              this.wFrameAdd.form.cid = wP_info.sysRole.id;
              this.wFrameAdd.form.name = wP_info.sysRole.name;
              this.wFrameAdd.form.menu = wP_info.sysMenuVOList;
            }
            break;

          default:
            break;
        }
      });
    },

    /**
     * wDoSaveMenu
     * 保存菜单数据
     */
    wDoSaveMenu() {
      // 请求参数
      let wData = {};

      // 获取值
      let { type: wM_type, form: wM_form } = this.wFrameAdd;

      // 数据处理
      if (!wM_form.name) {
        this.$message.error("角色名称不能为空！");
        return;
      } else {
        wData.name = wM_form.name;
      }
      // let wTreeKeys = this.$refs.tree.getCheckedKeys(true);
      let wTreeCheckedNodes = this.$refs.tree.getCheckedNodes(false, true);
      let wTreeKeys = "";
      wTreeCheckedNodes.forEach((item, index) => {
        if (index == 0) {
          wTreeKeys = item.id;
        } else {
          wTreeKeys += `,${item.id}`;
        }
      });
      wData.menuIds = wTreeKeys.toString();

      // 弹框类型，默认0-新增，1-编辑修改
      switch (`${wM_type}`) {
        case "0":
          // 新增
          {
            // 请求函数
            set_doRoleAdd(wData).then((res) => {
              this.wFrameAdd.isShow = false;
              // 查询角色列表数据
              this.wQueryRoleList();
            });
          }
          break;
        case "1":
          // 编辑修改
          {
            // 请求函数
            wData.id = wM_form.cid;
            set_doRoleEdit(wData).then((res) => {
              this.wFrameAdd.isShow = false;
              // 查询角色列表数据
              this.wQueryRoleList();
            });
          }
          break;

        default:
          this.$message("功能待开发!");
          break;
      }
    },

    /**
     * wDoDelete
     * 删除菜单数据
     * wP_id
     */
    wDoDelete(wP_id) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 请求函数
          set_doRoleDelete({
            ids: [wP_id],
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            // 查询角色列表数据
            this.wQueryRoleList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 菜单管理
.wSet-role {
  display: block;
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  user-select: none;

  // 头部按钮
  .wSR-head {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  // 内容区
  .wSR-body {
    display: block;
    width: 100%;
  }
}
</style>