<template>
  <!-- 分销详情 -->
  <div>
    <!-- 个人资料 -->
    <div class="wMD-block" v-if="wUserInfo">
      <div class="wMDB-head">
        <div class="wMDBH-label">个人资料</div>
        <div class="wMDBH-btn" v-if="false">
          <span>更多</span><i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="wMDB-body">
        <!-- 个人资料 -->
        <div class="wMD-userInfo">
          <div class="wMDUI-block">
            <div class="wMDUI-imgHPBox">
              <img
                class="wMDUI-imgHP"
                :src="wUserInfo.avatar"
                alt="图片"
                draggable="false"
              />
            </div>
            <div class="wMDUI-box">
              <div class="wMDUI-item">用户：{{ wUserInfo.nickName }}</div>
              <div class="wMDUI-item">电话：{{ wUserInfo.phone }}</div>
              <div class="wMDUI-item">
                等级：{{ wUserInfo.identityName }}
                <span
                  v-if="
                    !(wUserInfo.positionName || wUserInfo.positionName == '无')
                  "
                >
                  , {{ wUserInfo.positionName }}
                </span>
              </div>
            </div>
          </div>
          <div class="wMDUI-block">
            <div class="wMDUI-box">
              <div class="wMDUI-item">注册时间：{{ wUserInfo.createDate }}</div>
              <div class="wMDUI-item" v-if="false">
                会员身份：{{ wUserInfo.positionName || "无" }}
              </div>
              <div class="wMDUI-item">
                <span>推荐人：</span>
                <span>{{ wUserInfo.superiorName || "无" }}</span>
                <!-- <el-button type="primary" size="mini">修改推荐人</el-button> -->
              </div>
              <div class="wMDUI-item">
                <span style="opacity: 0">推荐人：</span>
                <span v-if="wUserInfo.superiorPhone">
                  {{ wUserInfo.superiorPhone || "无" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合伙人记录 -->
    <div class="wMD-block" v-if="wPartnerRecord">
      <div class="wMDB-head">
        <div class="wMDBH-label">合伙人记录</div>
        <div class="wMDBH-btn" v-if="false">
          <span>更多</span><i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="wMDB-body">
        <div style="text-align：center;" v-if="wPartnerRecord.length == 0">
          <van-empty description="暂无数据" />
        </div>
        <!-- 合伙人记录 -->
        <div class="wMD-partnerRecords">
          <div class="wMDPR-list">
            <template v-for="(item, index) in wPartnerRecord">
              <div class="wMDPR-item" :key="index">
                <div class="wMDPR-label">
                  {{ item.info }}
                </div>
                <div class="wMDPR-time">{{ item.time }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 财务数据 -->
    <div class="wMD-block" v-if="wUserInfo">
      <div class="wMDB-head">
        <div class="wMDBH-label">财务数据</div>
        <div class="wMDBH-btn" v-if="false">
          <span>更多</span><i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="wMDB-body">
        <!-- 财务数据 -->
        <div class="wMD-financialData">
          <div class="wMDFD-list">
            <div class="wMDFD-item">
              <div class="wMDFD-num">¥{{ wUserInfo.totalCommission }}</div>
              <div class="wMDFD-txt">总佣金</div>
            </div>
            <div class="wMDFD-item" v-if="false">
              <div class="wMDFD-num">¥{{ wUserInfo.totalCommission }}</div>
              <div class="wMDFD-txt">未结算金额</div>
            </div>
            <div class="wMDFD-item">
              <div class="wMDFD-num">¥{{ wUserInfo.canIncome }}</div>
              <div class="wMDFD-txt">可提现金额</div>
            </div>
            <div class="wMDFD-item">
              <div class="wMDFD-num">¥{{ wUserInfo.hasIncome }}</div>
              <div class="wMDFD-txt">已提现金额</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 财务记录 -->
    <div class="wMD-block" v-if="wUserInfo">
      <div class="wMDB-head">
        <div class="wMDBH-label">财务记录</div>
        <div class="wMDBH-btn" @click="wToRecords">
          <span>查看财务记录</span><i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="wMDB-body">
        <!-- 财务记录 -->
        <div class="wMD-financialRecords">
          <div class="wMDFR-head">
            <div
              class="wMDFRH-btn"
              :class="{ wActive: wFRCurrent == 0 }"
              @click="wFRCurrent = 0"
            >
              资产收入
            </div>
            <div
              class="wMDFRH-btn"
              :class="{ wActive: wFRCurrent == 1 }"
              @click="wFRCurrent = 1"
            >
              分销收入
            </div>
          </div>
          <div class="wMDFR-body">
            <div class="wMDFR-block" v-show="wFRCurrent == 0">
              <div class="wMDFR-list">
                <div class="wMDFR-item">
                  <div class="wMDFR-num">
                    {{ wUserInfo.incomeTotalVO.orderSize || 0 }}
                  </div>
                  <div class="wMDFR-txt">付款订单数</div>
                </div>
                <div class="wMDFR-item">
                  <div class="wMDFR-num">
                    {{ wUserInfo.incomeTotalVO.turnover || 0 }}
                  </div>
                  <div class="wMDFR-txt">付款成交额</div>
                </div>
                <div class="wMDFR-item">
                  <div class="wMDFR-num">
                    {{ wUserInfo.incomeTotalVO.preIncome || 0 }}
                  </div>
                  <div class="wMDFR-txt">付款预收入</div>
                </div>
                <div class="wMDFR-item">
                  <div class="wMDFR-num">
                    {{ wUserInfo.incomeTotalVO.share || 0 }}
                  </div>
                  <div class="wMDFR-txt">股份</div>
                </div>
              </div>
              <!-- <div class="wMDFR-more" @click="wToAssets">
                <span>查看资产明细</span><i class="el-icon-arrow-right"></i>
              </div> -->
            </div>
            <div class="wMDFR-block" v-show="wFRCurrent == 1">
              <div class="wMDFR-list">
                <div class="wMDFR-item">
                  <div class="wMDFR-num">
                    {{ wUserInfo.sharePerformanceVO.totalPerformance || 0 }}
                  </div>
                  <div class="wMDFR-txt">总业绩</div>
                </div>
                <div class="wMDFR-item">
                  <div class="wMDFR-num">
                    {{ wUserInfo.sharePerformanceVO.directPerformance || 0 }}
                  </div>
                  <div class="wMDFR-txt">直推业绩</div>
                </div>
                <div class="wMDFR-item">
                  <div class="wMDFR-num">
                    {{ wUserInfo.sharePerformanceVO.indirectPerformance || 0 }}
                  </div>
                  <div class="wMDFR-txt">间推业绩</div>
                </div>
                <div class="wMDFR-item">
                  <div class="wMDFR-num">
                    {{ wUserInfo.sharePerformanceVO.totalIncome || 0 }}
                  </div>
                  <div class="wMDFR-txt">佣金收入</div>
                </div>
              </div>
              <div class="wMDFR-more" @click="wToDistribution">
                <span>查看分销明细</span><i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 上级变更记录 -->
    <div class="wMD-block" v-if="wUserInfo">
      <div class="wMDB-head">
        <div class="wMDBH-label">上级变更记录</div>
      </div>
      <div class="wMDB-body">
        <el-table
          :data="wUserInfo.partnerSuperiorChangeRecordVOList"
          style="width: 100%"
        >
          <el-table-column prop="superiorName" label="新上级">
          </el-table-column>
          <el-table-column prop="superiorPhone" label="新上级电话">
          </el-table-column>
          <el-table-column prop="oldSuperiorName" label="旧上级">
          </el-table-column>
          <el-table-column prop="oldSuperiorPhone" label="旧上级电话">
          </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
          <el-table-column prop="createTime" label="操作时间"> </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 他的团队 -->
    <div class="wMD-block" v-if="wUserInfo">
      <div class="wMDB-head">
        <div class="wMDBH-label">他的团队</div>
        <div class="wMDBH-btn" v-if="false">
          <span>更多</span><i class="el-icon-arrow-right"></i>
        </div>
        <!-- 搜索 -->
        <div class="wMDBH-searchBox">
          <el-input
            v-model="wTeam.searchVal"
            placeholder="请输入电话号码"
          ></el-input>
          <el-button type="primary" @click="wGetSearchTeam()">搜索</el-button>
        </div>
      </div>
      <div class="wMDB-body">
        <!-- 他的团队 -->
        <div class="wMD-team">
          <div class="wMDT-list">
            <div
              class="wMDT-item"
              :class="{ wActive: wTeam.type == 0 }"
              @click="wDoTeamChange(0)"
            >
              <span class="wMDT-label">团队总人数</span>
              <span class="wMDT-num">{{ wUserInfo.teamNum }}</span>
            </div>
            <div
              class="wMDT-item"
              :class="{ wActive: wTeam.type == 1 }"
              @click="wDoTeamChange(1)"
            >
              <span class="wMDT-label">直系人数</span>
              <span class="wMDT-num">{{ wUserInfo.directNum }}</span>
            </div>
            <div
              class="wMDT-item"
              :class="{ wActive: wTeam.type == 2 }"
              @click="wDoTeamChange(2)"
            >
              <span class="wMDT-label">非直系人数</span>
              <span class="wMDT-num">{{ wUserInfo.nonDirectNum }}</span>
            </div>
          </div>
        </div>
        <!-- 团队成员 -->
        <div class="wMDT-members">
          <el-table
            :data="wTeam.wList"
            empty-text="暂无数据"
            style="width: 100%"
          >
            <el-table-column
              prop="date"
              label="头像"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                <div class="wMDTM-imgHPBox">
                  <el-avatar :size="50" :src="scope.row.avatar"></el-avatar>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="nickName" label="昵称" align="center">
            </el-table-column>
            <el-table-column prop="phone" label="手机号" align="center">
            </el-table-column>
            <el-table-column
              prop="identityName"
              label="合伙人类型"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="inferiorsNum"
              label="下级人数"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="totalIncome" label="获得佣金" align="center">
            </el-table-column>
            <el-table-column
              prop="totalPerformance"
              label="团队金额"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="createTime" label="时间" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="wDoTableBtn(scope, 'FXXQ')"
                  ><span>分销详情</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="wPagination">
            <el-pagination
              background
              :layout="wC_layout"
              :current-page="wTeam.wPage"
              :page-size="wTeam.wLimit"
              :total="wTeam.wTotal"
              :page-sizes="wC_pageSizes"
              :hide-on-single-page="false"
              @current-change="wELPageCurrentChange"
              @size-change="wELPageSizeChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { member_distributionDetails } from "@/network/wApi.js";
import { wApi_member_queryMyTeam } from "@/plugins/wApi.js";

export default {
  data() {
    return {
      wFRCurrent: 0, // 财务记录选项卡，默认0-资产收入，1-分销收入
      // 用户信息
      wUserInfo: null,
      // 合伙人记录
      wPartnerRecord: null,
      // 他的团队
      wTeam: {
        type: 0, // 团队类型，默认0-团队总人数，1-直系人数，2-非直系人数
        searchVal: "", // 搜索关键字
        wPage: 1, // 当前页码
        wLimit: 10, // 每页条数
        wTotal: 0, // 总数据
        wList: [], /// 数据
      },
    };
  },
  computed: {
    // 获取分页配置数据
    wC_layout() {
      return this.$store.state.wPage.wLayout;
    },
    wC_pageSizes() {
      return this.$store.state.wPage.wPageSizes;
    },
  },
  created() {
    if (!this.$route.query.userid) {
      this.$message.error("不存在该用户");
      this.$router.go(-1);
    } else {
      // 获取会员分销详情
      this.wGetDistributionDetails(this.$route.query.userid);
    }
  },
  methods: {
    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange(wP_cPage) {
      this.wTeam.wPage = wP_cPage;
      this.$nextTick(() => {
        // 方法-通过用户id查询我的团队
        this.wFun_queryMyTeamByUserid();
      });
    },
    /**
     * wELPageSizeChange
     * 修改推荐人分页
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      this.wTeam.wLimit = wP_cPageSize;
      this.$nextTick(() => {
        // 方法-通过用户id查询我的团队
        this.wFun_queryMyTeamByUserid(true);
      });
    },
    /**
     * 获取会员分销详情
     * wGetDistributionDetails
     * wP_userId
     */
    wGetDistributionDetails(wP_userId) {
      // 请求函数
      member_distributionDetails({
        userId: wP_userId,
      }).then((res) => {
        // 更新数据
        this.wUserInfo = res;
        this.wPartnerRecord = res.partnerRecordList;
        // this.wTeam.list_base = res.partnerTeamVO.allList;

        this.$nextTick(() => {
          // 方法-通过用户id查询我的团队
          this.wFun_queryMyTeamByUserid(true);
        });
      });
    },
    /**
     * wDoTeamChange
     * 团队切换选择
     */
    wDoTeamChange(wP_type) {
      this.wTeam.type = wP_type;
      this.$nextTick(() => {
        // 方法-通过用户id查询我的团队
        this.wFun_queryMyTeamByUserid(true);
      });
    },

    /**
     * wGetSearchTeam
     * 获取搜索团队
     */
    wGetSearchTeam() {
      // 方法-通过用户id查询我的团队
      this.wFun_queryMyTeamByUserid(true);
    },

    /**
     * wDoTableBtn
     * 表格操作按钮
     */
    wDoTableBtn(wP_scope, wP_type) {
      console.log(wP_scope, wP_type);
      switch (`${wP_type}`) {
        case `FXXQ`:
          // 分销详情
          {
            // 初始化数据
            this.wFRCurrent = 0; // 财务记录选项卡，默认0-资产收入，1-分销收入
            // 用户信息
            this.wUserInfo = null;
            // 合伙人记录
            this.wPartnerRecord = null;
            // 他的团队
            this.wTeam = {
              type: 0, // 团队类型，默认0-团队总人数，1-直系人数，2-非直系人数
              searchVal: "", // 搜索关键字
              wPage: 1, // 当前页码
              wLimit: 10, // 每页条数
              wTotal: 0, // 总数据
              wList: [], /// 数据
            };
            this.$nextTick(() => {
              // this.$message("分销详情");
              // this.$router.push({
              //   name: "memberDistribution",
              //   query: {
              //     userid: wP_scope.row.userId,
              //   },
              // });
              // 获取会员分销详情
              this.wGetDistributionDetails(wP_scope.row.userId);
              // document.body.scrollTop = document.documentElement.scrollTop = 0;
              document.getElementById("wPCrumbsMain").scrollTop = 0;
              // let element = document.getElementById("vueScroll");
              // console.log(element);
              // element.scrollIntoView(true);
            });
          }
          break;

        default:
          this.$message("功能待开发~");
          break;
      }
    },

    /**
     * wToRecords
     * 查看财务记录数据
     */
    wToRecords() {
      // 跳转至财务数据
      this.$router.push({
        name: "memberFinancialRecords",
        query: {
          userid: this.$route.query.userid,
        },
      });
    },

    /**
     * wToAssets
     * 查看资产明细
     */
    wToAssets() {
      // 跳转至资产明细页面
      this.$router.push({
        name: "memberAssetsRecords",
        query: {
          userid: this.$route.query.userid,
        },
      });
    },

    /**
     * wToDistribution
     * 查看资产明细
     */
    wToDistribution() {
      // 跳转至资产明细页面
      this.$router.push({
        name: "memberDistributionRecords",
        query: {
          userid: this.$route.query.userid,
        },
      });
    },

    /**
     * wFun_queryMyTeamByUserid
     * 方法-通过用户id查询我的团队
     * @param wP_truthy 真值，true-查询第一页数据,false-查询下一页数据
     */
    wFun_queryMyTeamByUserid(wP_truthy) {
      console.log(this.wUserInfo);
      let [
        wData,
        {
          wUserInfo: { id: wUserId },
          wTeam: { type: wType, searchVal: wSearchVal, wPage, wLimit, wList },
        },
      ] = [{}, this];
      // 用户id
      if (!wUserId) {
        this.$message.error("系统繁忙，请稍后再试！");
        this.wTeam.wPage = 1;
        this.wTeam.wList = [];
        return;
      }
      wData.userId = wUserId;
      // 关键字
      if (wSearchVal) {
        wData.keyword = wSearchVal;
      }
      // 团队类型，默认0-团队总人数，1-直系人数，2-非直系人数
      switch (wType.toString()) {
        case "2":
          // 非直系人数
          {
            wData.isDirect = 0;
          }
          break;
        case "1":
          // 非直系人数
          {
            wData.isDirect = 1;
          }
          break;

        default:
          break;
      }
      // 分页
      wData.pageModel = {};
      wData.pageModel.pageNo = wP_truthy ? 1 : parseInt(wPage);
      wData.pageModel.pageSize = parseInt(wLimit);
      wData.pageModel.sortField = "createTime";
      wData.pageModel.sortWay = "desc";
      // 请求函数
      wApi_member_queryMyTeam({ data: JSON.stringify(wData) })
        .then((res) => {
          // 返回数据
          let [{ records: resData, total: wTotal }] = [res.data];
          // 做判断
          wList = [];
          // 数据处理
          resData.forEach((item, index) => {
            wList.push(item);
          });
          console.log(res);
          // 更新数据
          this.wTeam.wPage = wData.pageModel.pageNo;
          this.wTeam.wTotal = wTotal;
          this.wTeam.wList = wList;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 模块
.wMD-block {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0 30px;
  box-sizing: border-box;
  margin-bottom: 20px;

  .wMDB-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: 20px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #f3f3f3;

    .wMDBH-label {
      flex: none;
      height: 22px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }
    .wMDBH-btn {
      flex: none;
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: @wColor_theme;
        transition: all 0.3s;
      }
    }

    .wMDBH-searchBox {
      flex: none;
      display: flex;
      align-items: center;
    }
  }
}

// 个人资料
.wMD-userInfo {
  display: flex;
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  align-items: center;

  .wMDUI-block {
    flex: none;
    width: 50%;
    display: flex;
    align-items: center;

    .wMDUI-imgHPBox {
      flex: none;
      width: 85px;
      height: 85px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 20px;

      .wMDUI-imgHP {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .wMDUI-box {
      flex: auto;

      .wMDUI-item {
        display: flex;
        width: 100%;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        margin-top: 10px;
        align-items: center;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

// 合伙人记录
.wMD-partnerRecords {
  display: flex;
  padding: 10px 0 20px;
  box-sizing: border-box;

  .wMDPR-list {
    flex: auto;
    max-height: 300px;
    overflow-y: auto;

    .wMDPR-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      box-sizing: border-box;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;

      .wMDPR-label {
        flex: auto;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .wMDPR-time {
        flex: none;
        min-width: 200px;
        text-align: right;
        padding-right: 10px;
      }
    }
  }
}

// 财务数据
.wMD-financialData {
  display: block;
  padding: 20px 0;
  box-sizing: border-box;

  .wMDFD-list {
    flex: auto;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    .wMDFD-item {
      flex: none;
      text-align: center;

      .wMDFD-num {
        display: block;
        height: 29px;
        font-size: 21px;
        font-weight: 500;
        color: #333333;
        line-height: 29px;
      }

      .wMDFD-txt {
        display: block;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        margin-top: 10px;
        user-select: none;
      }
    }
  }
}

// 财务记录
.wMD-financialRecords {
  display: block;
  width: 100%;
  padding: 20px 0 10px;
  box-sizing: border-box;

  .wMDFR-head {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;
    box-sizing: border-box;

    .wMDFRH-btn {
      flex: none;
      width: 196px;
      height: 50px;
      border-radius: 4px;
      border: 1px solid #dddddd;
      line-height: 48px;
      box-sizing: border-box;
      text-align: center;
      user-select: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      transition: all 0.3s;

      &.wActive,
      &:hover {
        background-color: #fff7f3;
        border-color: #fff7f3;
        font-size: 16px;
        font-weight: 500;
        color: @wColor_theme;
        transition: all 0.3s;
      }
    }
  }

  .wMDFR-body {
    display: block;
    width: 100%;

    .wMDFR-block {
      display: block;

      .wMDFR-list {
        display: flex;
        width: 100%;
        padding: 20px 0;
        justify-content: space-around;
        align-items: center;
        border-bottom: 1px solid #f3f3f3;

        .wMDFR-item {
          flex: none;
          width: 25%;
          text-align: center;

          .wMDFR-num {
            display: block;
            width: 100%;
            height: 33px;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            line-height: 33px;
          }

          .wMDFR-txt {
            display: block;
            width: 100%;
            height: 22px;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
            margin-top: 10px;
          }
        }
      }

      .wMDFR-more {
        display: block;
        width: 200px;
        padding: 25px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        line-height: 22px;
        user-select: none;
        text-align: center;
        cursor: pointer;
        margin: 0 auto;

        &:hover {
          color: @wColor_theme;
        }
      }
    }
  }
}

// 他的团队
.wMD-team {
  display: block;
  width: 100%;
  padding: 20px 0 25px;
  box-sizing: border-box;

  .wMDT-list {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .wMDT-item {
      flex: none;
      display: flex;
      align-items: center;
      user-select: none;
      cursor: pointer;

      &:hover,
      &.wActive {
        .wMDT-label,
        .wMDT-num {
          color: #ff6321;
        }
      }

      .wMDT-label {
        flex: none;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        margin-right: 10px;
      }

      .wMDT-num {
        flex: none;
        height: 33px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 33px;
      }
    }
  }
}

// 团队成员
.wMDT-members {
  display: block;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  .wMDTM-imgHPBox {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>