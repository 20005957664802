<template>
  <!-- 上架管理 -->
  <div>
    <div class="wSet-shelf">
      <div class="wSS-head">
        <el-button type="primary" @click="wDoShelf_up()">一键上架</el-button>
        <el-button type="primary" @click="wDoShelf_down()">一健下架</el-button>
      </div>
      <div class="wSS-body">
        <el-table :data="wShelf.list" @selection-change="wSelectionChange">
          <el-table-column type="selection" width="50"> </el-table-column>
          <el-table-column prop="tableNameExplanation" label="板块">
          </el-table-column>
          <el-table-column prop="addUser" label="操作人"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  set_queryShelfAll,
  set_doShelf_down,
  set_doShelf_up,
} from "@/network/wApi.js";

export default {
  data() {
    return {
      // 上架列表
      wShelf: {
        list: [], // 所有数据
        selectedArr: [], // 选中的数据
      },
    };
  },
  mounted() {
    // 查询上架管理的列表数据
    this.wQueryShelfList();
  },
  methods: {
    /**
     * wQueryShelfList
     * 查询上架管理的列表数据
     */
    wQueryShelfList() {
      // 请求函数
      set_queryShelfAll().then((res) => {
        this.wShelf.selectedArr = [];
        this.wShelf.list = res;
      });
    },
    /**
     * wSelectionChange
     * table 选择框的切换
     */
    wSelectionChange(res) {
      this.wShelf.selectedArr = res;
    },
    /**
     * wDoShelf_up
     * 一键上架
     */
    wDoShelf_up() {
      // 请求参数
      let wData = {};

      // 请求值
      let { selectedArr: wSelectedArr } = this.wShelf;

      // 数据处理
      let ids = wSelectedArr.map((item) => {
        return item.id;
      });

      if (ids.length == 0) {
        this.$message.error("请选择需要操作的板块！");
        return;
      } else {
        wData.ids = ids;
      }

      // 请求函数
      set_doShelf_up(wData).then((res) => {
        this.$message.success("操作成功!");
        // 查询上架管理的列表数据
        this.wQueryShelfList();
      });
    },
    /**
     * wDoShelf_down
     * 一键下架
     */
    wDoShelf_down() {
      // 请求参数
      let wData = {};

      // 请求值
      let { selectedArr: wSelectedArr } = this.wShelf;

      // 数据处理
      let ids = wSelectedArr.map((item) => {
        return item.id;
      });

      if (ids.length == 0) {
        this.$message.error("请选择需要操作的板块！");
        return;
      } else {
        wData.ids = ids;
      }

      // 请求函数
      set_doShelf_down(wData).then((res) => {
        this.$message.success("操作成功!");
        // 查询上架管理的列表数据
        this.wQueryShelfList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 上架管理
.wSet-shelf {
  display: block;
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  user-select: none;

  // 头部按钮
  .wSS-head {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  // 内容区
  .wSS-body {
    display: block;
    width: 100%;
  }
}
</style>