<template>
  <!-- 编辑菜品 -->
  <div>
    <!-- 添加菜品 -->
    <div class="wGoods-add">
      <el-form
        ref="form"
        label-position="right"
        :model="wForm"
        label-width="130px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所在门店:" required>
              <el-select
                v-model="wForm.storeId"
                placeholder="请选择店铺"
                @change="changeStoreList($event)"
              >
                <el-option
                  v-for="(item, index) in wStore.list"
                  :key="index"
                  :label="item.storeName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜品分类:" required>
              <el-select
                v-model="wForm.category.value"
                placeholder="菜品分类"
                style="width: 230px"
              >
                <el-option
                  v-for="item in wForm.category.options"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜品名称:" required>
              <el-input
                style="width: 230px"
                v-model="wForm.name"
                placeholder="菜品名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜品售价:" required>
              <el-input
                style="width: 230px"
                v-model="wForm.retailPrice"
                placeholder="菜品售价"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜品类型:" required>
              <el-select
                v-model="wForm.atype"
                placeholder="菜品类型"
                style="width: 230px"
              >
                <el-option label="堂食" :value="1"></el-option>
                <el-option label="供应链" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜品序号:" required>
              <el-input
                style="width: 230px"
                v-model="wForm.sort"
                placeholder="当前分类下的排序"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜品规格:" required>
              <el-input
                style="width: 230px"
                v-model="wForm.unitProps.value"
                placeholder="菜品规格值，用于小程序端展示"
              ></el-input>
              <el-select
                v-model="wForm.unitProps.unit"
                placeholder="菜品规格单位"
                style="width: 230px"
              >
                <el-option label="位" value="位"></el-option>
                <el-option label="锅" value="锅"></el-option>
                <el-option label="碗" value="碗"></el-option>
                <el-option label="份" value="份"></el-option>
                <el-option label="斤" value="斤"></el-option>
                <el-option label="两" value="两"></el-option>
                <el-option label="盒" value="盒"></el-option>
                <el-option label="条" value="条"></el-option>
                <el-option label="根" value="根"></el-option>
                <el-option label="盘" value="盘"></el-option>
                <el-option label="包" value="包"></el-option>
                <el-option label="袋" value="袋"></el-option>
                <el-option label="件" value="件"></el-option>
                <el-option label="吨" value="吨"></el-option>
                <el-option label="箱" value="箱"></el-option>
                <el-option label="克" value="克"></el-option>
                <el-option label="罐" value="罐"></el-option>
                <el-option label="瓶" value="瓶"></el-option>
                <el-option label="罐" value="罐"></el-option>
                <el-option label="毫升" value="毫升"></el-option>
                <el-option label="公斤" value="公斤"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="品牌:" required>
              <el-input
                style="width: 230px"
                v-model="wForm.brand"
                placeholder="菜品品牌"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="8">
            <el-form-item label="菜品产地:" required>
              <el-input
                style="width: 230px"
                v-model="wForm.proplace"
                placeholder="菜品产地"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="8">
            <el-form-item label="供应商联系方式:">
              <el-input
                style="width: 230px"
                v-model="wForm.supplierPhone"
                placeholder="供应商联系方式"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
            <el-form-item label="搜索关键字:">
              <el-input
                style="width: 230px"
                v-model="wForm.keyword"
                placeholder="搜索关键字"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="8" v-if="wForm.buyType === 2">
            <el-form-item label="运费:">
              <el-select
                v-model="wForm.freight.value"
                placeholder="运费"
                style="width: 230px"
              >
                <el-option
                  v-for="item in wForm.freight.options"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="菜品推荐度:">
              <div style="display: flex; height: 40px; align-items: center">
                <el-rate v-model="wForm.score"></el-rate>
              </div>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="24">
            <el-form-item label="备注:">
              <el-input
                style="max-width: 800px"
                v-model="wForm.remark"
                placeholder="备注"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="菜品简介:" required>
              <el-input
                v-model="wForm.introduce"
                type="textarea"
                placeholder="请输入内容"
                maxlength="500"
                style="width: 50%"
                rows="6"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜品主图:" required>
              <el-upload
                :action="wGetUploadImgPath()"
                list-type="picture-card"
                :file-list="wForm.imgCover.list"
                :limit="wForm.imgCover.limit"
                :on-preview="wELUpload_preview"
                :on-remove="wELUpload_remove_cover"
                :on-success="wELUpload_success_cover"
                :on-error="wELUpload_error"
                :on-exceed="wELUpload_limit"
                :before-upload="wELUpload_beforeUpload"
              >
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，推荐尺寸: 750 * 750
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="菜品分享图:" required>
              <el-upload
                :action="wGetUploadImgPath()"
                list-type="picture-card"
                :limit="wForm.imgShare.limit"
                :on-preview="wELUpload_preview"
                :on-remove="wELUpload_remove_share"
                :on-success="wELUpload_success_share"
                :on-error="wELUpload_error"
                :on-exceed="wELUpload_limit"
                :before-upload="wELUpload_beforeUpload"
              >
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，推荐尺寸: 750 * 750
                </div>
              </el-upload>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="菜品配图:">
              <el-upload
                :action="wGetUploadImgPath()"
                list-type="picture-card"
                :file-list="wForm.imgAlbum.list"
                :limit="wForm.imgAlbum.limit"
                :on-preview="wELUpload_preview"
                :on-remove="wELUpload_remove_album"
                :on-success="wELUpload_success_album"
                :on-error="wELUpload_error"
                :on-exceed="wELUpload_limit"
                :before-upload="wELUpload_beforeUpload"
              >
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，推荐尺寸: 750 * 750
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="菜品介绍:" required>
              <div class="wUeditorArea" v-if="false">
                <vue-ueditor-wrap
                  v-model="wForm.describe"
                  :config="myConfig"
                ></vue-ueditor-wrap>
              </div>
              <div class="wUeditorArea" v-else>
                <tinymce-editor ref="editor" v-model="wForm.describe">
                </tinymce-editor>
              </div>
            </el-form-item>
          </el-col> -->
          <el-col :span="24" style="text-align: center">
            <el-button type="primary" @click="wDoSubmitForm()">保存</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 图片预览 -->
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
// 引入组件
import VueUeditorWrap from 'vue-ueditor-wrap' // ES6 Module
import TinymceEditor from '@/components/tinymce-editor/tinymce-editor' // 富文本
// 请求函数
import {
  goods_GCGetCategoryOptions,
  goods_GCAddGoods,
  goods_GCEditGoods,
  goods_GCGetGoodsInfo,
} from '@/network/wApi'
import { regexp } from '@/plugins/utils.js'
import { browser } from '@/plugins/utils.js'

import { getClassify } from '@/network/api'

export default {
  name: 'GoodAdd',
  components: {
    VueUeditorWrap,
    TinymceEditor,
  },
  data() {
    return {
      // 字典数据
      wDicCategory: [], // 菜品分类数据
      goodsInfo: null,
      // 表单数据
      wForm: {
        goodsId: null,
        storeId: null, //门店
        name: '', // 菜品名称
        retailPrice: '', // 菜品价格
        sort: null,
        atype: 1,
        // 菜品分类
        category: {
          value: '', // 选中值
          options: [], // 所有分类列表
        },
        brand: '', // 菜品品牌
        proplace: '', // 菜品产地
        keyword: '', // 搜索关键字
        supplierPhone: '', // 菜品供应商联系方式
        remark: '', // 备注
        unitProps: {
          value: '1',
          unit: '份',
        },
        // 菜品运费
        freight: {
          value: '0',
          options: [
            {
              id: '0',
              label: '包邮',
            },
          ],
        },
        score: 0, // 菜品推荐度(评分)
        introduce: '菜品简介', // 菜品简介
        imgCover: {
          limit: 1, // 数量限制
          list: [], // 图片集合
        }, // 菜品主图
        imgShare: {
          limit: 1, // 数量限制
          list: [], // 图片集合
        }, // 菜品分享图
        imgAlbum: {
          limit: 6, // 数量限制
          list: [], // 图片集合
        }, // 菜品相册图
        describe: '', // 菜品描述(富文本)
      },

      // 图片预览
      dialogImageUrl: '',
      dialogVisible: false,

      // 富文本框
      myConfig: {
        toolbars: [
          [
            'source',
            '|',
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            'fontborder',
            'strikethrough',
            'superscript',
            'subscript',
            'removeformat',
            'formatmatch',
            'autotypeset',
            'blockquote',
            'pasteplain',
            '|',
            'forecolor',
            'backcolor',
            'insertorderedlist',
            'insertunorderedlist',
            'selectall',
            '|',
            'rowspacingtop',
            'rowspacingbottom',
            'lineheight',
            '|',
            'customstyle',
            'paragraph',
            'fontfamily',
            'fontsize',
            '|',
            'directionalityltr',
            'directionalityrtl',
            'indent',
            '|',
            'justifyleft',
            'justifycenter',
            'justifyright',
            'justifyjustify',
            '|',
            'touppercase',
            'tolowercase',
            '|',
            'imagenone',
            'imageleft',
            'imageright',
            'imagecenter',
            '|',
            'emotion',
            'map',
            'simpleupload', //单图上传
            'insertimage', //多图上传
            'insertcode',
            '|',
            'link', //超链接
            'horizontal',
            'date',
            'time',
            'spechars',
            'preview',
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: '100%', // 初始容器宽度
        serverUrl: 'http://192.168.2.30:9200/ueditor/ueditorUpload',
        UEDITOR_HOME_URL: '/UEditor/',
        maximumWords: '10000000000',
      },
      // 门店
      wStore: {
        page: 1,
        limit: 1000,
        total: 0,
        list: [],
      },
      // headImgList: [],
      // imgList: [],
    }
  },
  created() {
    if (browser.getLocationOption('goodsId')) {
      this.wForm.goodsId = browser.getLocationOption('goodsId')
    }

    // Promise.allSettled()方法接受一组 Promise 实例作为参数，包装成一个新的 Promise 实例。只有等到所有这些参数实例都返回结果，不管是fulfilled还是rejected，包装实例才会结束。
    const allSettledPromise = Promise.allSettled([this.wGetStoreList()])
    allSettledPromise.then((results) => {
      // 绑定菜品的分类数据
      if (this.wForm.goodsId) {
        this.goods_GCGetGoodsInfo()
      }
    })
  },
  methods: {
    // 查菜品详细
    goods_GCGetGoodsInfo() {
      goods_GCGetGoodsInfo({ goodsId: this.wForm.goodsId }).then((res) => {
        this.wForm.id = res.id
        this.wForm.storeId = res.storeId
        this.wForm.category.value = res.categoryId
        this.wForm.name = res.name
        this.wForm.retailPrice = res.retailPrice
        this.wForm.introduce = res.details
        this.wForm.sort = res.sort;
        const unit = res.unit ? res.unit.match(/(\d+\.?(\d+)?)?(.{1,2})$/) : null;
        if (unit && unit.length > 3) {
          this.wForm.unitProps.value = unit[1] || 1;
          this.wForm.unitProps.unit = unit[3];
        }
        this.wDic_category(res.storeId)
        this.wForm.imgCover.list.push({
          name: 'headImg',
          url: res.headImg,
        })
        if (res.imgList && res.imgList.length > 0) {
          for (let a = 0; a < res.imgList.length; a++) {
            this.wForm.imgAlbum.list.push({
              // name: 'Img' + a,
              name: 'ImgList',
              url: res.imgList[a],
            })
          }
        }
      })
    },
    // 根据店铺切换分类
    changeStoreList(e) {
      this.wForm.category.value = null
      this.wForm.storeId = e
      this.wDic_category(e)
    },
    /**
     * wGetStoreList
     * 获取门店列表数据
     */
    wGetStoreList() {
      let { page, limit } = this.wStore

      // 请求参数
      let wData = {}

      wData['pageModel.pageNo'] = page
      wData['pageModel.pageSize'] = limit
      // wData["pageModel.sortField"] = "id";
      // wData["pageModel.sortWay"] = "asc";

      // 请求函数
      this.$api.store_list({
        data: wData,
        success: (res) => {

          // 更新数据
          this.wStore.total = res.total
          this.wStore.list = res.records
          // this.wStore.list.label = res.records.storeName;
          // this.wStore.list.value = res.records.id;
        },
      })
    },
    /**
     * wDic_category
     * 获取字典数据-菜品的分类
     */
    wDic_category(e) {
      return new Promise((resolve, reject) => {
        // 请求函数
        // goods_GCGetCategoryOptions()
        getClassify({ storeId: e })
          .then((res) => {
            // 更新数据
            this.wDicCategory = res
            this.wBindCategory()
            resolve('success')
          })
          .catch((err) => {
            reject('fail')
          })
      })
    },
    // 获取图片上传的地址
    wGetUploadImgPath() {
      return `${process.env.VUE_APP_URE}/file/uploadFile`
    },
    // 图片预览
    wELUpload_preview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 删除图片
    wELUpload_remove(file, fileList) {
    },
    // 图片上传成功
    wELUpload_success(response, file, fileList) {
    },
    // 图片上传失败
    wELUpload_error(err, file, fileList) {
      this.$message('图片上传失败')
    },
    // 超出图片上传限制
    wELUpload_limit(files, fileList) {
      this.$message('图片上传已达上限')
    },
    // 图片上传之前做限制
    wELUpload_beforeUpload(file) {
      this.loading = true
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      // const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG 或者 PNG 格式!')
      }
      // if (!isLt10M) {
      //   this.$message.error("上传图片大小不能超过 10MB!");
      // }
      // return (isJPG || isPNG) && isLt10M;
      return isJPG || isPNG
    },
    /**
     * wELUpload_success_cover
     * 菜品主图上传成功
     */
    wELUpload_success_cover(response, file, fileList) {
      // 数据处理
      let imgArr = this.wForm.imgCover.list
      imgArr.push({
        name: file.name,
        url: file.response.data,
      })
      // 更新数据
      this.wForm.imgCover.list = imgArr
    },
    /**
     * wELUpload_remove_cover
     * 删除菜品主图图片
     */
    wELUpload_remove_cover(file, fileList) {
      // 数据处理
      let imgArr = []
      for (let i = 0; i < fileList.length; i++) {
        imgArr.push({
          name: fileList[i].name,
          url: fileList[i].response.data,
        })
      }
      // 更新数据
      this.wForm.imgCover.list = imgArr
    },
    /**
     * wELUpload_success_share
     * 菜品分享图上传成功
     */
    wELUpload_success_share(response, file, fileList) {
      // 数据处理
      let imgArr = this.wForm.imgShare.list
      imgArr.push({
        name: file.name,
        url: file.response.data,
      })
      // 更新数据
      this.wForm.imgShare.list = imgArr
    },
    /**
     * wELUpload_remove_share
     * 删除菜品分享图图片
     */
    wELUpload_remove_share(file, fileList) {
      // 数据处理
      let imgArr = []
      for (let i = 0; i < fileList.length; i++) {
        imgArr.push({
          name: fileList[i].name,
          url: fileList[i].response.data,
        })
      }
      // 更新数据
      this.wForm.imgShare.list = imgArr
    },
    /**
     * wELUpload_success_album
     * 菜品相册图片上传成功
     */
    wELUpload_success_album(response, file, fileList) {
      // 数据处理
      let imgArr = this.wForm.imgAlbum.list
      imgArr.push({
        name: file.name,
        url: file.response.data,
      })
      // 更新数据
      this.wForm.imgAlbum.list = imgArr
    },
    /**
     * wELUpload_remove_album
     * 删除菜品相册图片
     */
    wELUpload_remove_album(file, fileList) {
      // 数据处理
      let imgArr = []
      for (let i = 0; i < fileList.length; i++) {
        imgArr.push({
          name: fileList[i].name,
          url: fileList[i].response.data,
        })
      }
      // 更新数据
      this.wForm.imgAlbum.list = imgArr
    },
    /**
     * wBindCategory
     * 绑定菜品的分类数据
     */
    wBindCategory() {
      let newArr = []
      for (let i = 0; i < this.wDicCategory.length; i++) {
        let item = this.wDicCategory[i]
        newArr.push({
          id: item.id,
          label: item.name,
        })
      }
      // 更新数据
      this.wForm.category.options = newArr
    },
    /**
     * wDoSubmitForm
     * 提交表单数据
     */
    wDoSubmitForm() {
      // 请求参数
      let wData = {}
      // 表单数据
      let {
        name: wGName,
        storeId: storeId,
        retailPrice: wGPrice,
        category: { value: wGCategoryId },
        brand: wGBrand,
        proplace: wGProplace,
        keyword: wGKeyword,
        freight: { value: wGFreightId },
        score: wGScore,
        atype: wAtype,
        sort,
        introduce: wGIntroduce,
        imgCover: { list: wGImgCover },
        imgShare: { list: wGImgShare },
        imgAlbum: { list: wGImgAlbum },
        describe: wGDescribe,
        unitProps,
        supplierPhone: wGSupplierPhone,
        remark: wGRemark,
      } = this.wForm
      // 菜品名称
      if (!wGName.trim()) {
        this.$message('菜品名称不能为空!')
        return
      }
      // 添加参数
      wData.id = this.wForm.goodsId
      wData.name = wGName
      // // 菜品价格
      // if (!wGPrice.trim()) {
      //   this.$message("菜品价格不能为空!");
      //   return;
      // }
      // // 添加参数
      wData.retailPrice = wGPrice
      // 菜品分类ID
      if (!`${wGCategoryId}`.trim()) {
        this.$message('菜品分类不能为空!')
        return
      }
      // 添加参数
      wData.categoryId = wGCategoryId

      // 菜品品牌
      // if (!wGBrand.trim()) {
      //   this.$message("菜品品牌不能为空!");
      //   return;
      // }
      // 添加参数
      // wData.brand = wGBrand;
      // 	菜品产地
      // if (!wGProplace.trim()) {
      //   this.$message("	菜品产地不能为空!");
      //   return;
      // }
      // 添加参数
      wData.proplace = wGProplace
      wData.sort = sort;
      wData.unit = (unitProps.value > 1 ? unitProps.value : '') + unitProps.unit;
      // 	搜索关键字
      // if (!wGKeyword.trim()) {
      //   this.$message("	搜索关键字不能为空!");
      //   return;
      // }
      // 添加参数
      // wData.search = wGKeyword;
      // 菜品简介
      // if (!wGIntroduce.trim()) {
      //   this.$message("菜品简介不能为空!");
      //   return;
      // }
      // 添加参数
      wData.details = wGIntroduce;
      // 菜品主图
      if (wGImgCover.length == 0) {
        this.$message('菜品主图不能为空!')
        return
      }
      wData.headImg = wGImgCover[0].url
      // 菜品分享图
      // if (wGImgShare.length == 0) {
      //   this.$message("菜品分享图不能为空!");
      //   return;
      // }
      // wData.shareImg = wGImgShare[0].url;
      // 菜品图片
      if (wGImgAlbum.length > 0) {
        let imgList = []
        for (let i = 0; i < wGImgAlbum.length; i++) {
          imgList.push(wGImgAlbum[i].url)
        }
        wData.imgList = imgList
        // } else {
        //   this.$message("菜品图片不能为空!");
        //   return;
      }
      // 菜品介绍-富文本
      // if (!wGDescribe.trim()) {
      //   this.$message("菜品介绍不能为空!");
      //   return;
      // }
      // wData.details = wGDescribe;
      // 菜品推荐度
      // wData.sort = wGScore;
      // 供应商联系方式
      // if (wGSupplierPhone.trim() != "") {
      //   // wData.supplierPhone = wGSupplierPhone;
      //   // 供应商联系方式
      //   if (regexp.phone(wGSupplierPhone.trim())) {
      //     wData.supplierPhone = wGSupplierPhone.trim();
      //   } else {
      //     this.$message("供应商联系方式格式不正确!");
      //     return;
      //   }
      // }
      // 备注
      // if (wGRemark.trim() != "") {
      //   wData.remark = wGRemark;
      // }

      // 推荐度

      wData.storeId = storeId
      wData.atype = wAtype
      // 请求函数
      wData.id
        ? goods_GCEditGoods(wData).then((res) => {
            this.$router.go(-1)
          })
        : goods_GCAddGoods(wData)
            .then((res) => {
              // this.$confirm("是否立即添加菜品的SKU", {
              //   confirmButtonText: "确定",
              //   cancelButtonText: "取消",
              // })
              //   .then(() => {
              //     // 跳转至菜品修改页面
              //     this.$router.push({
              //       name: "goodsEdit",
              //       query: {
              //         gid: res,
              //         type: 1,
              //       },
              //     });
              //   })
              //   .catch(() => {
              // 返回上一页
              this.$router.go(-1)
              // });
            })
            .catch((err) => {
            })
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/css/publicStyle.less';

// 添加菜品
.wGoods-add {
  display: block;
  width: 100%;
  padding: 40px 60px;
  box-sizing: border-box;
  background-color: #ffffff;
}

// 富文本框样式
.wUeditorArea {
  display: block;
  width: 100%;
  line-height: initial;
}
</style>
