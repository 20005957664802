<template>
  <!-- 认缴列表 -->
  <div>
    <!-- 筛选查询 -->
    <div class="wScreenArea">
      <el-card class="box-card">
        <div slot="header" class="wScreenCardHeader">
          <span>筛选查询</span>
          <el-button type="primary" size="mini" @click="wFun_wRJFrameOpen()"
            >添加</el-button
          >
        </div>
        <!-- 筛选列表 -->
        <el-form
          ref="wScreenForm"
          label-position="left"
          label-width="90px"
          :model="wScreen"
          @submit.native.prevent
        >
          <el-row :gutter="20">
            <!-- 手机号 -->
            <el-col :span="6">
              <el-form-item label="搜索关键字" prop="wKeywords">
                <el-input
                  style="width: 100%; max-width: 200px"
                  type="text"
                  v-model="wScreen.wKeywords"
                  placeholder="请输入手机号或昵称"
                  @keyup.enter.native="wDoEnter_keywords"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- 操作状态 -->
            <el-col :span="6">
              <el-form-item prop="wState" label="操作状态">
                <el-select
                  v-model="wScreen.wState"
                  placeholder="请选择操作状态"
                  @change="wchange_wState"
                >
                  <el-option
                    v-for="item in wC_state"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value + ''"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 是否完款 -->
            <el-col :span="6">
              <el-form-item prop="wIsAllMoney" label="是否完款">
                <el-select
                  v-model="wScreen.wIsAllMoney"
                  placeholder="请选择是否完款"
                  @change="wchange_wIsAllMoney"
                >
                  <el-option
                    v-for="item in wC_allMoney"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value + ''"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <!-- 操作按钮 -->
            <el-col :span="24">
              <el-button size="medium" @click="wFun_formScreenReset">
                重置
              </el-button>
              <el-button size="medium" @click="wFun_formScreenSubmit">
                查询
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </div>
    <!-- 表格数据 -->
    <div class="wTableArea">
      <!-- 订单列表 -->
      <template>
        <!-- 表格 -->
        <el-table border :data="wTable.wList">
          <el-table-column label="用户信息" align="center">
            <template slot-scope="scope">
              <div style="text-align: center">
                <div>名字：{{ scope.row.name }}</div>
                <div>手机号：{{ scope.row.phone }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="上级信息" align="center">
            <template slot-scope="scope">
              <div style="text-align: center">
                <div>名字：{{ scope.row.superiorName }}</div>
                <div>手机号：{{ scope.row.superiorPhone }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="认缴身份" align="center">
            <template slot-scope="scope">
              <div style="text-align: center">
                <div>原身份：{{ scope.row.oldIdentityName }}</div>
                <div>认缴身份：{{ scope.row.identityName }}</div>
                <div>冠名桌类型：{{ scope.row.tableTypeName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="缴纳金额" align="center">
            <template slot-scope="scope">
              <div style="text-align: center">
                <div>应缴金额：{{ scope.row.money }}</div>
                <div>实缴金额：{{ scope.row.realMoney }}</div>
                <div>股份：{{ scope.row.share }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="缴纳状态" align="center">
            <template slot-scope="scope">
              <div style="text-align: center">
                <div>
                  <span
                    style="color: #ff6321"
                    v-if="scope.row.isPaymentCompleted == 1"
                    >已完款</span
                  >
                  <span
                    style="color: #ff6321"
                    v-if="scope.row.isPaymentCompleted == 0"
                    >未完款</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
          </el-table-column>
          <el-table-column prop="operatorName" label="操作人员" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center">
          </el-table-column>
          <el-table-column label="认缴状态" align="center">
            <template slot-scope="scope">
              <div>
                <el-button type="text" v-if="scope.row.state == 0">
                  <span>已废弃</span>
                </el-button>
                <template v-else>
                  <el-button
                    type="text"
                    @click="wFun_doSubscription(scope)"
                    v-if="wIsAdmin && scope.row.state == 1"
                  >
                    <span>待录入</span>
                  </el-button>
                  <el-button type="text" v-if="scope.row.state == 2">
                    <span>已录入</span>
                  </el-button>
                  <el-button
                    type="text"
                    @click="wFun_doAbandoned(scope)"
                    v-else
                  >
                    <span>废弃</span>
                  </el-button>
                </template>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页 -->
      <div class="wPagination">
        <el-pagination
          background
          :layout="wC_layout"
          :current-page="wTable.wPage"
          :page-size="wTable.wLimit"
          :total="wTable.wTotal"
          :page-sizes="wC_pageSizes"
          :hide-on-single-page="false"
          @current-change="wELPageCurrentChange"
          @size-change="wELPageSizeChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 添加认缴人员 -->
    <el-dialog
      title="添加认缴人员"
      :visible.sync="wRJFrame.wIsShow"
      :append-to-body="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="wFun_wRJFrameCancel()"
    >
      <!-- 表单 -->
      <el-form
        ref="wRJFrameForm"
        :model="wRJFrame.wForm"
        label-width="120px"
        @submit.native.prevent
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="用户名字：" prop="wUserName">
              <el-input
                style="width: 100%; max-width: 200px"
                type="text"
                v-model="wRJFrame.wForm.wUserName"
                placeholder="请输入用户名字"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户手机号：" prop="wUserPhone">
              <el-input
                style="width: 100%; max-width: 200px"
                type="text"
                v-model="wRJFrame.wForm.wUserPhone"
                maxlength="11"
                placeholder="请输入用户手机号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上级名字：" prop="wSuperiorName">
              <el-input
                style="width: 100%; max-width: 200px"
                type="text"
                v-model="wRJFrame.wForm.wSuperiorName"
                placeholder="请输入上级名字"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上级手机号：" prop="wSuperiorPhone">
              <el-input
                style="width: 100%; max-width: 200px"
                type="text"
                v-model="wRJFrame.wForm.wSuperiorPhone"
                maxlength="11"
                placeholder="请输入上级手机号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="原身份：" prop="wOldIdentity">
              <el-select
                v-model="wRJFrame.wForm.wOldIdentity"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in wC_oldIdentity"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="认缴身份：" prop="wIdentity">
              <el-select
                v-model="wRJFrame.wForm.wIdentity"
                placeholder="请选择"
                @change="wchage_wIdentity"
              >
                <el-option
                  v-for="item in wC_identity"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应缴金额：" prop="wMoney">
              <el-input
                style="width: 100%; max-width: 200px"
                type="text"
                v-model="wRJFrame.wForm.wMoney"
                placeholder="请输入应缴金额"
                @input="winput_wMoney"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实缴金额：" prop="wRealMoney">
              <el-input
                style="width: 100%; max-width: 200px"
                type="text"
                v-model="wRJFrame.wForm.wRealMoney"
                placeholder="请输入实缴金额"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="股份：" prop="wShare">
              <el-input
                style="width: 100%; max-width: 200px"
                type="text"
                v-model="wRJFrame.wForm.wShare"
                placeholder="请输入股份"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="冠名桌类型：" prop="wTableType">
              <el-select
                v-model="wRJFrame.wForm.wTableType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in wC_tableType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否完款：" prop="wIsPaymentCompleted">
              <el-switch
                v-model="wRJFrame.wForm.wIsPaymentCompleted"
                active-text="已完款"
                inactive-text="未完款"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注：" prop="wRemark">
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="wRJFrame.wForm.wRemark"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- footer -->
      <div slot="footer">
        <el-button @click="wFun_wRJFrameCancel()">取 消</el-button>
        <el-button type="primary" @click="wFun_wRJFrameSubmit()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { regexp } from "@/plugins/utils.js";
import {
  wApi_member_queryApplyPage,
  wApi_member_queryPartnerIdentity,
  wApi_member_queryIdentityIsUsed,
  wApi_member_getTableConfigList,
  wApi_member_addPartnerSubscribedApplyRecord,
  wApi_member_upDatePartnerSubscribedApplyStateById,
} from "@/plugins/wApi.js";
export default {
  data() {
    return {
      // 字典数据
      wDic_oldIdentity: [], // 原身份（非必填）
      wDic_identity: [], // 认缴身份
      wDic_tableType: [], // 冠名桌类型
      // 筛选查询
      wScreen: {
        wKeywords: "", // 关键字
        wState: "1", // 操作状态
        wIsAllMoney: "-1", // 是否完款
      },
      // 表格数据
      wTable: {
        wPage: 1,
        wLimit: 10,
        wTotal: 0,
        wList: [],
      },
      // 添加认缴人员弹框
      wRJFrame: {
        wIsShow: false, // 是否展示
        wForm: {
          wUserName: "", // 用户名字
          wUserPhone: "", // 用户手机号
          wSuperiorName: "", // 上级名字
          wSuperiorPhone: "", // 上级手机号
          wOldIdentity: "", // 原身份（非必填）
          wIdentity: "", // 认缴身份
          wMoney: "", // 应缴金额
          wRealMoney: "", // 实缴金额
          wShare: "", // 	股份
          wTableType: "", // 冠名桌类型
          wIsPaymentCompleted: true, // 是否完款
          wRemark: "", // 备注
        }, // 表单数据
      },
    };
  },
  computed: {
    // 获取分页配置数据
    wC_layout() {
      return this.$store.state.wPage.wLayout;
    },
    wC_pageSizes() {
      return this.$store.state.wPage.wPageSizes;
    },
    // 获取操作状态数据
    wC_state() {
      let wState = [
        {
          label: "全部",
          value: "-1",
        },
        {
          label: "待录入",
          value: "1",
        },
        {
          label: "已录入",
          value: "2",
        },
        {
          label: "废弃",
          value: "0",
        },
      ];
      return wState;
    },
    // 获取是否完款数据
    wC_allMoney() {
      let wAllMoney = [
        {
          label: "全部",
          value: "-1",
        },
        {
          label: "已完款",
          value: "1",
        },
        {
          label: "未完款",
          value: "0",
        },
      ];
      return wAllMoney;
    },
    // 获取原身份数据
    wC_oldIdentity() {
      let arr = [];
      this.wDic_oldIdentity.forEach((item) => {
        let obj = {};
        obj.label = item.name;
        obj.value = item.identity;
        arr.push(obj);
      });
      return arr;
    },
    // 获取认缴身份数据
    wC_identity() {
      let arr = [];
      this.wDic_identity.forEach((item) => {
        let obj = {};
        obj.label = item.name;
        obj.value = item.identity;
        arr.push(obj);
      });
      return arr;
    },
    // 获取冠名桌类型数据
    wC_tableType() {
      let arr = [
        {
          label: "无",
          value: "",
        },
      ];
      this.wDic_tableType.forEach((item) => {
        let obj = {};
        obj.label = item.tableTypeName;
        obj.value = item.tableType;
        arr.push(obj);
      });
      return arr;
    },
  },
  mounted() {
    // 判断是否是admin用户
    if (JSON.parse(localStorage.getItem("LOGININFO")).account == "admin") {
      this.wIsAdmin = true;
    }
    // Promise.allSettled()方法，用来确定一组异步操作是否都结束了（不管成功或失败）。
    Promise.allSettled([
      this.wFun_queryOldIdentityData(),
      this.wFun_queryIdentityData(),
      this.wFun_queryTableTypeData(),
    ])
      .then((result) => {
        // 方法-查询表格数据
        this.wFun_queryTableData(true);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    /**
     * wFun_formScreenReset
     * 方法-重置表单数据
     */
    wFun_formScreenReset() {
      this.$refs["wScreenForm"].resetFields();
      this.$nextTick(() => {
        // 方法-查询表格数据
        this.wFun_queryTableData(true);
      });
    },
    /**
     * wFun_formScreenSubmit
     * 方法-提交表单数据
     */
    wFun_formScreenSubmit() {
      // 方法-查询表格数据
      this.wFun_queryTableData(true);
    },
    /**
     * wDoEnter_keywords
     * 关键字搜索回车事件
     */
    wDoEnter_keywords() {
      // 方法-查询表格数据
      this.wFun_queryTableData(true);
    },
    /**
     * wchange_wState
     * 选中值发生变化时触发事件-操作状态
     */
    wchange_wState(wP_cValue) {
      this.$nextTick(() => {
        // 方法-查询表格数据
        this.wFun_queryTableData(true);
      });
    },
    /**
     * wchange_wIsAllMoney
     * 选中值发生变化时触发事件-操作状态
     */
    wchange_wIsAllMoney(wP_cValue) {
      this.$nextTick(() => {
        // 方法-查询表格数据
        this.wFun_queryTableData(true);
      });
    },
    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange(wP_cPage) {
      this.wTable.wPage = wP_cPage;
      this.$nextTick(() => {
        // 方法-查询表格数据
        this.wFun_queryTableData();
      });
    },
    /**
     * wELPageSizeChange
     * 修改分页条数
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      this.wTable.wLimit = wP_cPageSize;
      this.$nextTick(() => {
        // 方法-查询表格数据
        this.wFun_queryTableData(true);
      });
    },
    /**
     * wFun_queryTableData
     * 方法-查询表格数据
     * @param wP_truthy 真值,true-初始值,false-加载更多
     */
    wFun_queryTableData(wP_truthy) {
      let [
        wData,
        {
          wScreen: { wKeywords, wState, wIsAllMoney },
          wTable: { wPage, wLimit, wTotal, wList },
        },
      ] = [{}, this];
      // 查询关键字
      if (wKeywords != "") {
        wData.keyWords = wKeywords;
      }
      // 操作状态
      if (wState != "-1") {
        wData.state = wState;
      }
      // 是否完款
      if (wIsAllMoney != "-1") {
        wData.isPaymentCompleted = wIsAllMoney;
      }
      // 添加分类参数
      wData.pageModel = {};
      wData.pageModel.pageNo = wP_truthy ? 1 : parseInt(wPage);
      wData.pageModel.pageSize = parseInt(wLimit);
      wData.pageModel.sortField = "createTime";
      wData.pageModel.sortWay = "desc";
      // 请求函数
      wApi_member_queryApplyPage({
        data: JSON.stringify(wData),
      })
        .then((res) => {
          let resData = res.data;
          let wList_new = resData.records;
          // 数据处理
          wList_new.forEach((item) => {});
          // 数据更新
          this.wTable.wTotal = resData.total;
          this.wTable.wPage = wData.pageModel.pageNo;
          this.wTable.wList = wList_new;
        })
        .catch((err) => {});
    },
    /**
     * wFun_queryOldIdentityData
     * 方法-查询原身份（非必填）的数据
     */
    wFun_queryOldIdentityData() {
      return new Promise((resolve, reject) => {
        // 请求函数
        wApi_member_queryPartnerIdentity()
          .then((res) => {
            let resData = res.data;
            // 更新数据
            this.wDic_oldIdentity = resData;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * wFun_queryIdentityData
     * 方法-查询认缴身份的数据
     */
    wFun_queryIdentityData() {
      return new Promise((resolve, reject) => {
        // 请求函数
        wApi_member_queryIdentityIsUsed()
          .then((res) => {
            let resData = res.data;
            // 更新数据
            this.wDic_identity = resData;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * wFun_queryTableTypeData
     * 方法-查询冠名桌类型的数据
     */
    wFun_queryTableTypeData() {
      return new Promise((resolve, reject) => {
        // 请求函数
        wApi_member_getTableConfigList()
          .then((res) => {
            let resData = res.data;
            // 更新数据
            this.wDic_tableType = resData;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * wFun_wRJFrameOpen
     * 方法-认缴人员弹框-打开弹框
     */
    wFun_wRJFrameOpen() {
      // 打开弹框-清空数据
      this.wRJFrame.wIsShow = true;
      this.$nextTick(() => {
        this.$refs["wRJFrameForm"].resetFields();
      });
    },
    /**
     * wFun_wRJFrameCancel
     * 方法-认缴人员弹框-取消关闭弹框
     */
    wFun_wRJFrameCancel() {
      // 清空数据,关闭弹框
      this.$refs["wRJFrameForm"].resetFields();
      this.$nextTick(() => {
        this.wRJFrame.wIsShow = false;
      });
    },
    /**
     * wFun_wRJFrameSubmit
     * 方法-认缴人员弹框-提交
     */
    wFun_wRJFrameSubmit() {
      let [
        wData,
        {
          wRJFrame: { wForm },
        },
      ] = [{}, this];

      console.log(wForm);
      // 用户名字
      if (wForm.wUserName.trim() == "") {
        this.$message.warning("用户名字不能为空！");
        return;
      }
      wData.name = wForm.wUserName.trim();
      // 用户手机号
      if (!regexp.phone(wForm.wUserPhone.trim())) {
        this.$message.warning("用户手机号格式不正确！");
        return;
      }
      wData.phone = wForm.wUserPhone.trim();
      // 上级名字
      if (wForm.wSuperiorName.trim() == "") {
        this.$message.warning("上级名字不能为空！");
        return;
      }
      wData.superiorName = wForm.wSuperiorName.trim();
      // 上级手机号
      if (!regexp.phone(wForm.wSuperiorPhone.trim())) {
        this.$message.warning("上级手机号格式不正确！");
        return;
      }
      wData.superiorPhone = wForm.wSuperiorPhone.trim();
      // 原身份
      if (wForm.wOldIdentity == "") {
      }
      wData.oldIdentity = wForm.wOldIdentity;
      // 认缴身份
      if (wForm.wIdentity == "") {
        this.$message.warning("请选择认缴身份！");
        return;
      }
      wData.identity = wForm.wIdentity;
      // 应缴金额
      if (!regexp.price(wForm.wMoney.trim())) {
        this.$message.warning("应缴金额格式不正确！");
        return;
      }
      wData.money = wForm.wMoney.trim();
      // 实缴金额
      if (!regexp.price(wForm.wRealMoney.trim())) {
        this.$message.warning("实缴金额格式不正确！");
        return;
      }
      wData.realMoney = wForm.wRealMoney.trim();
      // 股份
      if (!regexp.allNumber(wForm.wShare.trim())) {
        this.$message.warning("股份格式不正确！");
        return;
      }
      wData.share = wForm.wShare.trim();
      // 冠名桌类型
      if (wForm.wTableType != "") {
        wData.tableType = wForm.wTableType;
      }
      // 是否完款（1、已完款 0、未完款）
      if (wForm.wIsPaymentCompleted) {
        wData.isPaymentCompleted = 1;
      } else {
        wData.isPaymentCompleted = 0;
      }
      // 备注
      if (wForm.wIsPaymentCompleted == 0 && wForm.wRemark.trim() == "") {
        this.$message.warning("未完款情况下备注不能为空！");
        return;
      }
      wData.remark = wForm.wRemark.trim();

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 请求函数
      wApi_member_addPartnerSubscribedApplyRecord({
        data: JSON.stringify(wData),
      })
        .then((res) => {
          loading.close();
          // 方法-认缴人员弹框-取消关闭弹框
          this.wFun_wRJFrameCancel();
          this.$nextTick(() => {
            // 方法-查询表格数据
            this.wFun_queryTableData(true);
          });
        })
        .catch((err) => {
          loading.close();
        });
    },
    /**
     * wchage_wIdentity
     * 选中值发生变化时触发-认缴身份切换选择
     * @param wP_value 目前的选中值
     */
    wchage_wIdentity(wP_value) {
      this.wDic_identity.forEach((item) => {
        if (item.identity == wP_value) {
          this.wRJFrame.wForm.wMoney = item.value; // 应交金额
          this.wRJFrame.wForm.wRealMoney = item.value; // 实缴金额
          this.wRJFrame.wForm.wShare = item.share; // 股份
        }
      });
    },
    /**
     * winput_wMoney
     * 在 Input 值改变时触发-应缴金额
     * @param wP_value (value: string | number)
     */
    winput_wMoney(wP_value) {
      this.wRJFrame.wForm.wRealMoney = wP_value; // 实缴金额
    },
    /**
     * wFun_doAbandoned
     * 方法-废弃操作
     * @param wP_obj 数据集合
     */
    wFun_doAbandoned(wP_obj) {
      let [
        {
          row: { id: wId },
        },
      ] = [wP_obj];
      // 确认弹框
      this.$confirm("认缴人员信息有错误！", "废弃操作", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          // 方法-认缴操作
          this.wFun_doOperateState(wId, 0);
        })
        .catch(() => {});
    },
    /**
     * wFun_doSubscription
     * 方法-认缴操作
     * @param wP_obj 数据集合
     */
    wFun_doSubscription(wP_obj) {
      let [
        {
          row: { id: wId },
        },
      ] = [wP_obj];
      // 确认弹框
      this.$confirm("认缴人员信息已录入！", "认缴操作", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          // 方法-认缴操作
          this.wFun_doOperateState(wId, 2);
        })
        .catch(() => {});
    },
    /**
     * wFun_doOperateState
     * 方法-操作状态
     * @param wP_id	申请id
     * @param wP_state 状态
     */
    wFun_doOperateState(wP_id, wP_state) {
      if (wP_id == undefined || wP_state == undefined) {
        this.$message.warning("系统繁忙，请稍后再试！");
        return;
      }
      let wData = {};
      // 添加请求id
      wData.applyId = wP_id;
      // 添加状态
      wData.state = wP_state;
      // 请求函数
      wApi_member_upDatePartnerSubscribedApplyStateById({
        data: JSON.stringify(wData),
      })
        .then((res) => {
          this.$message.success("操作成功！");
          this.$nextTick(() => {
            // 方法-查询表格数据
            this.wFun_queryTableData(true);
          });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";
</style>