import { request } from './request.js'

/**
 * pub
 * 公共模块
 */
// 公共模块-上传文件的base64编码-接口
export function pub_fileUpload_base64(data) {
  return request({
    url: '/file/fileUploadByBase64',
    data
  })
}
// 公共模块-上传本地文件-接口
export function pub_fileUpload_file(data) {
  return request({
    url: '/file/uploadFile',
    data,
    isHeader: 2
  })
}
// 公共模块-获取左侧导航
export function pub_sidebarArr(data) {
  return request({
    url: '/manager/account/sysMenu',
    data
  })
}

/**
 * pub
 * 菜单模块
 */

// 订单管理
export function orderListByStore(params) {
  return request({
    url: '/noodle/order/list',
    params,
    method: 'GET'
    // isHeader: 2
  })
}

// 获取订单地址
export function getAddressApi(params) {
  return request({
    url: '/noodle/order/getAddress',
    params,
    method: 'GET',
    isHeader: 1
  })
}
// 订单发货
export function goDeliverGoodsApi(data) {
  return request({
    url: '/noodle/order/addLogistics',
    data,
    method: 'POST',
    isHeader: 1
  })
}
// 查询单号
export function queryLogisticsApi(params) {
  return request({
    url: '/noodle/order/queryLogistics',
    params,
    method: 'GET',
    isHeader: 1
  })
}
// 修改发货
export function updateLogisticsApi(data) {
  return request({
    url: '/noodle/order/updateLogistics',
    data,
    method: 'POST',
    isHeader: 1
  })
}

// 线下支付确认收款
export function confirmCashPayApi(data) {
  return request({
    url: '/noodle/order/proceeds',
    data,
    isHeader: 1
  })
}

/**
 * member
 * 会员模块
 */

// 会员列表-添加员工-接口
export function member_addMember(data) {
  return request({
    // baseUrl: process.env.VUE_APP_URE01,
    url: '/userAndPartner/creatUser',
    data
  })
}
// 会员列表-认缴-接口
export function member_payment(data) {
  return request({
    url: '/userAndPartner/subscribe',
    data
  })
}
// 会员列表-合伙人配置列表-接口
export function member_getPartner(data) {
  return request({
    url: '/userAndPartner/listSubscribeIdentity',
    data
  })
}
// 会员列表-购买合伙人-接口
export function member_addAccessory(data) {
  return request({
    url: '/userAndPartner/addAccessory',
    data
  })
}
// 会员列表-更改推荐人-接口
export function member_changeReferences(data) {
  return request({
    url: '/userAndPartner/updateSuperiorId',
    data
  })
}
// 会员列表-查询分销详情-接口
export function member_distributionDetails(data) {
  return request({
    url: '/userAndPartner/queryDistributionDetails',
    data
  })
}
// 会员列表-分页查询交易明细（财务记录）-接口
export function member_getFinancialRecords(data) {
  return request({
    url: '/userAndPartner/queryIncomeExpenditureDetails',
    data
  })
}
// 会员列表-分页查询分享明细列表-接口
export function member_getDistributionRecords(data) {
  return request({
    url: '/userAndPartner/queryShareDetailsPage',
    data
  })
}
// 提现审核-分页查询用户申请记录(用户端)-接口
export function member_withdrawalList(data) {
  return request({
    url: '/transfer/queryUserTransferApplication',
    data
  })
}
// 提现列表To-分页查询用户申请记录(用户端)-接口
export function member_withdrawalList1(data) {
  return request({
    url: '/transfer/listPartnerTransferRecord',
    isHeader: 1,
    data
  })
}
// 提现审核-大额提现审核-接口
export function member_withdrawalExamine(data) {
  return request({
    url: '/transfer/updateTransferApplication',
    data
  })
}

/**
 * goods
 * 商品模块
 */
// 赶场商品-查询商品分类列表-接口
export function goods_GCGetCategory(data) {
  return request({
    url: '/market/queryGoodsCategoryList',
    data
  })
}
// 赶场商品-查询商品分类下拉选项-接口
export function goods_GCGetCategoryOptions(data) {
  return request({
    url: '/market/queryGoodsCategoryOptions',
    data
  })
}
// 赶场商品-查询商品详情-接口
export function goods_GCGetGoodsInfo(data) {
  return request({
    url: '/market/queryMgtGoodsDetails',
    data
  })
}
// 赶场商品-添加商品-接口
export function goods_GCAddGoods(data) {
  return request({
    url: '/market/addNoodlesGoods',
    data: data,
    isHeader: 1
  })
}
// export function goods_GCAddGoods(data) {
//   return request({
//     url: '/market/addGoods',
//     data
//   })
// }
// 修改产品限购
export function changeGroupHotSaleApi(data) {
  return request({
    url: 'market/changeGroupHotSale',
    data
  })
}

// 赶场商品-修改商品数据-接口
export function goods_GCEditGoods(data) {
  return request({
    url: '/market/editGoodsData',
    data,data,
    isHeader:1
  })
}
// 赶场商品-删除商品-接口
export function goods_GCRemoveGoodsById(data) {
  return request({
    url: '/market/removeGoodsById',
    data
  })
}
// 赶场商品-添加sku-接口
export function goods_GCAddGoodsSKU(data) {
  return request({
    url: '/market/addSku',
    data
  })
}
// 赶场商品-获取sku信息-接口
export function goods_GCGetGoodsSKU(data) {
  return request({
    url: '/market/queryMgtSku',
    data
  })
}
// 赶场商品-修改sku数据-接口
export function goods_GCEditGoodsSKU(data) {
  return request({
    url: '/market/editSkuData',
    data
  })
}
// 赶场商品-删除sku-接口
export function goods_GCRemoveGoodsSKUById(data) {
  return request({
    url: '/market/removeSkuById',
    data
  })
}
// 赶场商品-sku上下架-接口
export function goods_GCChangeSKUState(data) {
  return request({
    url: '/market/changeSkuState',
    data
  })
}
// 赶场商品-修改商品分类-接口
export function goods_GCEditCategory(data) {
  return request({
    url: '/market/editCategory',
    data
  })
}
// 超值套餐-同步套餐列表数据-接口
export function goods_CZTCUpdateList(data) {
  return request({
    url: '/foods/syncPackage',
    data
  })
}
// 超值套餐-超值套餐详情-接口
export function goods_CZTCGetInfoById(data) {
  return request({
    url: '/foods/foodDetail',
    data
  })
}
// 超值套餐-修改套餐-接口
export function goods_CZTCUpdateInfoById(data) {
  return request({
    url: '/foods/update',
    data
  })
}
// 豪华套餐-分页查询套餐列表-接口
export function goods_HHTCGoodsList(data) {
  return request({
    url: '/vipGoods/listVipGoods',
    data
  })
}
// 豪华套餐-修改商品上下架状态-接口
export function goods_HHTCChangeSKUState(data) {
  return request({
    url: '/vipGoods/updateVipGoodsState',
    data
  })
}
// 豪华套餐-添加豪华套餐-接口
export function goods_HHTCAddGoods(data) {
  return request({
    url: '/vipGoods/addNoodles',
    data
  })
}
// export function goods_HHTCAddGoods(data) {
//   return request({
//     url: '/vipGoods/add',
//     data
//   })
// }
// 豪华套餐-通过套餐id查询套餐-接口
export function goods_HHTCGetGoodsInfoById(data) {
  return request({
    url: '/vipGoods/vipGoodsById',
    data
  })
}
// 豪华套餐-修改套餐-接口
export function goods_HHTCEditGoodsInfo(data) {
  return request({
    url: '/vipGoods/updateVipGoods',
    data
  })
}
// 豪华套餐-删除套餐-接口
export function goods_HHTCRemoveGoodsById(data) {
  return request({
    url: '/vipGoods/delVipGoods',
    data
  })
}

/**
 * store
 * 门店模块
 */
// 分页查询门店列表数据
export function store_storeList(data) {
  return request({
    url: '/store/managerList',
    data
  })
}
// // 修改门店是否开业
// export function store_operateBusiness(data) {
//   return request({
//     url: '/store/openOrClose',
//     data
//   })
// };
// 通过id查询详情
export function store_storeInfoById(data) {
  return request({
    url: '/store/query',
    data
  })
}
// 门店新增
export function store_addStoreInfo(data) {
  return request({
    url: '/store/add',
    data: data,
    isHeader: 1
  })
}
// 门店修改
export function store_editStoreInfo(data) {
  return request({
    url: '/store/edit',
    data
  })
}
// 通过门店id查询员工列表数据
export function store_staffList(data) {
  return request({
    url: '/manager/employee/queryByStoreId',
    data
  })
}
// 员工离职
export function store_quitWorking(data) {
  return request({
    url: '/manager/employee/employeeOutWork',
    data
  })
}
// 从客如云同步门店和台桌信息
export function store_storeUpdate(data) {
  return request({
    url: '/store/add',
    data: data
  })
}
// 通过门店id查询桌子
export function store_getTableBysid(data) {
  return request({
    url: '/table/list',
    data
  })
}
// 添加桌子
export function addTable(data) {
  return request({
    url: '/table/add',
    data: data,
    isHeader: 1
  })
}
// 编辑桌子
export function editTable(data) {
  return request({
    url: '/table/edit',
    data: data,
    isHeader: 1
  })
}
// 删除桌子
export function deleteTable(data) {
  return request({
    url: '/table/removeTableById',
    data
  })
}

// 生成桌码
export function QrCodeImage(data) {
  return request({
    url: '/table/generateQrCodeAndImage',
    data
  })
}
// 修改员工状态
export function store_staffState(data) {
  return request({
    url: '/manager/employee/updateEmployeeState',
    data
  })
}
// 查询员工职务列表
export function store_postList(data) {
  return request({
    url: '/manager/employeeJob/queryEmployeeJob',
    data
  })
}
// 添加员工
export function store_staffAdd(data) {
  return request({
    url: '/manager/employee/add',
    data
  })
}
// 修改员工基本信息
export function store_staffEdit(data) {
  return request({
    url: '/manager/employee/updateEmployee',
    data
  })
}
// 员工重置密码
export function store_staffResetPWD(data) {
  return request({
    url: '/manager/employee/resetPwd',
    data
  })
}
// 添加员工职务
export function store_postAdd(data) {
  return request({
    url: '/manager/employeeJob/addEmployeeJob',
    data
  })
}
// 修改员工职务
export function store_postEdit(data) {
  return request({
    url: '/manager/employeeJob/updateEmployeeJob',
    data
  })
}
// 查询员工权限
export function store_authorityList(data) {
  return request({
    url: '/employeePermission/queryEmployeePermission',
    data
  })
}

/**
 * set
 * 设置模块
 */
// 一键上下架列表数据
export function set_queryShelfAll(data) {
  return request({
    url: '/onclickOpenOrClose/queryAll',
    data
  })
}
// 一键修改（一键下架）
export function set_doShelf_down(data) {
  return request({
    url: '/onclickOpenOrClose/onclickUpdate',
    data
  })
}
// 一键还原（一键上架）
export function set_doShelf_up(data) {
  return request({
    url: '/onclickOpenOrClose/onclickReset',
    data
  })
}
// 分页查询用户列表
export function set_queryUserAll(data) {
  return request({
    url: '/manager/account/queryPage',
    data
  })
}
// 添加用户
export function set_doUserAdd(data) {
  return request({
    url: '/manager/account/add',
    data
  })
}
// 修改用户信息
export function set_doUserEdit(data) {
  return request({
    url: '/manager/account/edit',
    data
  })
}
// 重置密码
export function set_doUserRechangePWD(data) {
  return request({
    url: '/manager/account/resetPwdByAccount',
    data
  })
}
// 修改状态
export function set_doUserUpdateState(data) {
  return request({
    url: '/manager/account/updateState',
    data
  })
}
// 批量删除用户
export function set_doUserDelete(data) {
  return request({
    url: '/manager/account/del',
    data
  })
}
// 查询系统角色
export function set_queryRoleAll(data) {
  return request({
    url: '/role/query',
    data
  })
}
// 添加系统角色
export function set_doRoleAdd(data) {
  return request({
    url: '/role/add',
    data
  })
}
// 编辑系统角色
export function set_doRoleEdit(data) {
  return request({
    url: '/role/edit',
    data
  })
}
// 删除系统角色
export function set_doRoleDelete(data) {
  return request({
    url: '/role/del',
    data
  })
}
// 查询所有菜单
export function set_queryMenuAll(data) {
  return request({
    url: '/menu/queryAll',
    data
  })
}
// 添加菜单
export function set_doMenuAdd(data) {
  return request({
    url: '/menu/add',
    data
  })
}
// 修改菜单内容
export function set_doMenuEdit(data) {
  return request({
    url: '/menu/edit',
    data
  })
}
// 删除菜单内容
export function set_doMenuDelete(data) {
  return request({
    url: '/menu/del',
    data
  })
}

/**
 * other
 * 其他板块内容
 */
// 查询用户详情
export function other_queryUserInfo(data) {
  return request({
    url: '/manager/account/query',
    data
  })
}
// 用户修改信息
export function other_updateUserInfo(data) {
  return request({
    url: '/manager/account/query',
    data
  })
}
// 用户修改密码
export function other_doEditPWD(data) {
  return request({
    url: '/manager/account/modifyPwd',
    data
  })
}

/**
 * statistics
 * 统计模块
 */
// 查询财务统计主数据
export function statistics_financeMain(data) {
  return request({
    url: '/finance/queryFinanceMainData',
    data
  })
}
// 分页查询订单列表
export function statistics_queryOrderList(data) {
  return request({
    url: '/finance/queryOrderPage',
    data
  })
}
// 查询财务统计收支记录
export function statistics_queryFinanceList(data) {
  return request({
    url: '/finance/queryFinanceOrder',
    data,
    isHeader: 1
  })
}
// 查询门店收入统计
export function statistics_queryStoreList(data) {
  return request({
    url: '/finance/storeRevenueStatistics',
    data
  })
}
// 查询佣金类型
export function statistics_queryCommissionType(data) {
  return request({
    url: '/finance/queryCommissionType',
    isHeader: 1,
    data
  })
}
// 查询佣金记录
export function statistics_queryCommissionRecord(data) {
  return request({
    url: '/finance/queryCommissionRecord',
    isHeader: 1,
    data
  })
}
// 查询交易统计筛选参数
export function statistics_queryExpendType(data) {
  return request({
    url: '/finance/queryStatisticsFilterParameters',
    data
  })
}
// 查询财务统计收支记录(新-二级分类)
export function statistics_queryFinanceListNew(data) {
  return request({
    url: '/finance/queryFinanceOrderNew',
    data,
    isHeader: 1
  })
}
// 查询财务统计订单收入
export function statistics_queryIncomByOrder(data) {
  return request({
    url: '/finance/queryIncomeListByOrder',
    data,
    isHeader: 1
  })
}
// 查询财务统计充值记录
export function statistics_queryIncomByRecharge(data) {
  return request({
    url: '/finance/queryIncomeListByRecharge',
    data,
    isHeader: 1
  })
}
// 查询财务统计提现记录
export function statistics_queryExpendByReflect(data) {
  return request({
    url: '/finance/queryIncomeListByTransfer',
    data,
    isHeader: 1
  })
}

// 销量统计
export function statistics_salesApi(params) {
  return request({
    url: '/noodle/count/goods/part',
    method: 'GET',
    params
  })
}
// 获取门店统计收入
export function getStoreAmountApi(params) {
  return request({
    url: '/noodle/order/orderTotal',
    method: 'GET',
    params
  })
}

// 获取门店列表信息
export function getStoreListApi(params) {
  return request({
    url: '/noodle/order/orderTotalList',
    method: 'GET',
    params
  })
}

// 订单打印小票酒水分开查询分类id接口
export function getProductTypeApi(params) {
  return request({
    url: '/noodle/order/productType',
    method: 'GET',
    params
  })
}

// 获取桌子列表
export function getStoreTableApi(params) {
  return request({
    url: '/store/queryStoreTable',
    method: 'GET',
    params
  })
}
// 修改开桌状态
export function alterStoreTableApi(params) {
  return request({
    url: '/store/updateTable',
    method: 'POST',
    params
  })
}
// 查看桌子对应的订单菜品
export function queryOrderByTableApi(params) {
  return request({
    url: '/store/queryOrderByTable',
    method: 'GET',
    params
  })
}
// 修改用户等级
export function updateIdentityApi(params) {
  return request({
    url: '/userAndPartner/updateUserIdentity',
    method: 'POST',
    params
  })
}
