<template>
  <div>
    <!-- 首页头部 -->
    <div class="wHeader">
      <div class="wHeader-block">
        <!-- logo -->
        <img
          class="wHeader-logo"
          src="@/assets/image/ldhg_logo001.jpg"
          alt="logo"
          draggable="false"
        />
        <!-- 网站名 -->
        <div class="wHeader-website">后台业务系统</div>
      </div>
      <div class="wHeader-block">
        <!-- 用户头像 -->
        <img
          class="wHeader-userImg"
          :src="userImgHP"
          alt="图片"
          draggable="false"
          v-if="userImgHP"
        />
        <!-- 用户昵称 -->
        <el-dropdown @command="wELDropdown_user">
          <div class="wHeader-userName">
            <span class="el-dropdown-link">{{ nickname }}</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="personCenter">个人中心</el-dropdown-item>
            <el-dropdown-item command="changePWD">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 用户昵称
      nickname: "",
      userImgHP: ""
    };
  },
  mounted() {
    let LOGININFO = JSON.parse(localStorage.getItem("LOGININFO"));
    this.nickname = LOGININFO.nickName || "管理员";
    this.userImgHP =
      LOGININFO.userImgHP || require("@/assets/image/ldhg_logo001.jpg");
  },
  methods: {
    /**
     * wELDropdown_user
     * 用户信息下拉框
     */
    wELDropdown_user(wP_command) {
      switch (wP_command) {
        case "personCenter":
          // 个人中心
          {
            // 跳至个人中心
            this.$router.push({ name: "personal" });
          }
          break;
        case "changePWD":
          // 修改密码
          {
            // 跳至修改密码
            this.$router.push({ name: "password" });
          }
          break;
        case "logout":
          // 退出登录
          {
            // 清除登录
            localStorage.removeItem("USER_TOKEN");
            localStorage.removeItem("USER_INFO");
            localStorage.removeItem("printList");
            // 退出登录
            this.$router.replace({ name: "login" });
          }
          break;

        default:
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";
// 首页头部
.wHeader {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 0px 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  justify-content: space-between;
  box-shadow: rgb(134 134 134 / 15%) 0px 2px 6px 0px;
  user-select: none;

  .wHeader-block {
    flex: none;
    display: flex;
    align-items: center;

    // logo
    .wHeader-logo {
      flex: none;
      display: flex;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      font-size: @wFontSize + 8px;
      color: #999999;
      background-color: #f6f8fb;
      margin-right: 20px;
    }

    // 网站名
    .wHeader-website {
      flex: none;
      font-size: @wFontSize + 10px;
      font-weight: bolder;
      color: #333333;
      // color: @wColor_theme;
    }

    // 用户头像
    .wHeader-userImg {
      flex: none;
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }

    // 用户昵称
    .wHeader-userName {
      flex: none;
      padding: 0 10px;
      box-sizing: border-box;
      font-size: @wFontSize + 4px;
      cursor: pointer;
    }
  }
}
</style>