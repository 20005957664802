import axios from "axios"
import store from "@/store/index"
import router from '@/router/index'
import { Toast } from "vant"
import qs from 'qs'

let defaultConfig = {
  baseURL: process.env.VUE_APP_URE,
  url: "",
  method: "post",
  responseType: "json",
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded', // 默认请求头-内容类型
  },
  params: {},
  data: {},
  timeout: 30000,
  isLoading: false,
  isAlert: true,
  statusCode: () => { },
  complete: () => {
    // console.log('No completed callback function！');
  },
  success: (res) => {
    console.log('No successful callback function！', res);
  },
  fail: (err) => {
    console.log('No failed callback function！', err);
  },
}

export default (parameter = {}) => {
  let config = { ...defaultConfig, ...parameter };

  // 判断是否存在 USER_TOKEN 
  localStorage.getItem('USER_TOKEN') && !config.headers.token && (config.headers.token = localStorage.getItem('USER_TOKEN'));

  const instance = axios.create();
  store.commit('axiosRequest', true);
  if (store.state.loading && config.isLoading) {
    Toast.loading({
      overlay: true,
      duration: 0,
    });
  }
  instance.request({
    baseURL: config.baseURL,
    url: config.url,
    params: config.params,
    data: config.data,
    method: config.method,
    headers: config.headers,
    timeout: config.timeout,
    responseType: config.responseType,
    transformRequest: [function (data, headers) {
      // 对 data 进行任意转换处理
      // 根据 请求头-内容类型 对数据做处理
      if (headers['Content-Type'] == 'application/x-www-form-urlencoded') {
        data = qs.stringify(data);
      }
      return data;
    }],
    validateStatus: function (status) {
      config.statusCode(status);

      if (status === 500) {
        Toast.clear();
        // Toast("服务器内部错误!");
        // 跳转至500页面
        router.push({ name: 'error' });
      } else if (status === 404) {
        Toast.clear();
        // Toast("请求地址不存在!");
        // 跳转至404页面
        router.push({ name: 'notfound' });
      }

      return status === 200 || status === 204
    }
  }).then((res) => {
    store.commit('axiosRequest', false);
    config.complete();
    if (!store.state.loading) {
      Toast.clear();
    }

    const { code, data, msg } = res.data;

    // 返回数据预处理
    if (code == 200) {
      // 正常请求
      config.success(data);
      // try {
      //   config.success(data);
      // } catch {
      //   Toast.clear();
      //   Toast('请求数据格式异常!');
      // }
    } else if (code == 401) {
      // 请重新登录
      Toast.clear();
      config.isAlert && Toast(msg);
      let setT = setTimeout(() => {
        // 跳转至登录页面
        router.push({ name: 'login' });
        clearTimeout(setT);
      }, 800);
    }
    else {
      Toast.clear();
      config.isAlert && Toast(msg);
      config.fail(err);
    }

  }).catch((err) => {
    store.commit('axiosRequest', false);
    config.complete();

    if (err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1 && !err.config._retry) {
      Toast.clear();
      config.isAlert && Toast("请求超时!");
    }
    config.fail(err);

  });

}