<template>
  <!-- 商品分类 => 门店列表 => 桌子列表 -->
  <div>
    <!-- 页面布局 -->
    <!-- <wPageCrumbs> -->
    <!-- 商品分类区域 -->
    <div class="wGC-categoryArea">
      <div class="wGC-head">
        <!-- 栏目 -->
        <div class="wGCH-column">
          <!-- <template v-for="item in wColumn.options">
            <div
              class="wGCHC-item"
              :class="{ wActive: item.id == wColumn.value }"
              :key="item.id"
              @click="wDoChoiceColumn(item.id)"
            >
              {{ item.title }}
            </div>
          </template> -->
        </div>
        <!-- 操作按钮 -->
        <div class="wGCH-operate">
          <el-button
            type="primary"
            @click="addClassify"
          >新增桌子</el-button>
        </div>
      </div>

      <!-- 商品分类数据 -->
      <div class="wGC-categoryList">
        <!-- table表 -->
        <div class="wGLG-table">
          <el-table
            :data="wCategory.list"
            border
            style="width: 100%"
          >
            <el-table-column
              label="店铺名称"
              prop="storeName"
            >
            </el-table-column>
            <!-- <el-table-column label="桌台位置">
              <template slot-scope="scope">
                <span>{{ scope.row.place == "S" ? "包厢" : "大厅" }}</span>
              </template>
            </el-table-column> -->
            <el-table-column
              label="客桌名称"
              prop="tableName"
            >
            </el-table-column>
            <!-- <el-table-column label="人数" prop="number"> </el-table-column> -->
            <el-table-column
              label="状态"
              prop="tableStatus"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.tableStatus == 0">禁用</div>
                <div v-else-if="scope.row.tableStatus == 1">启用</div>
              </template>
            </el-table-column>
            <el-table-column
              label="二维码"
              prop="pic"
            >
              <template slot-scope="scope">
                <el-image
                  v-if="scope.row.pic"
                  style="width: 100px; height: 100px"
                  :src="scope.row.pic"
                  fit="cover"
                  :preview-src-list="[scope.row.pic]"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="wDoTableBtn(scope, 'edit')"
                ><span>修改</span>
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  @click="wDoTableBtn(scope, 'code')"
                ><span>生成码</span>
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  @click="wDoTableBtn(scope, 'delete')"
                ><span>删除</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="wPagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="wCategory.page"
            :page-size="wCategory.limit"
            :total="wCategory.total"
            :page-sizes="[5, 10, 15, 20, 50]"
            :hide-on-single-page="false"
            @current-change="wELPageCurrentChange"
            @size-change="wELPageSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- </wPageCrumbs> -->

    <!-- 门店桌位修改弹框 -->
    <el-dialog
      title="桌位信息"
      :visible.sync="wCategoryFrame.isShow"
      width="500px"
      append-to-body
      @close="wELDialogClose()"
    >
      <el-form
        ref="wCFForm"
        :model="wCategoryFrame"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="店名:"
              prop="storeName"
            >
              <el-input
                v-model="this.storeName"
                disabled
                style="width: 100%; max-width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="桌号:" prop="tableSerial">
              <el-input
                v-model="wCategoryFrame.tableSerial"
                placeholder="客桌编号"
                style="width: 100%; max-width: 200px"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item
              label="桌名:"
              prop="tableName"
            >
              <el-input
                v-model="wCategoryFrame.tableName"
                placeholder="客桌名称"
                style="width: 100%; max-width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="排序:" prop="sort">
              <el-input-number
                v-model="wCategoryFrame.sort"
                @change="handleChange"
                :min="0" :max="99"
                label="分类排序">
              </el-input-number>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item
              label="状态:"
              prop="tableStatus"
            >
              <el-switch
                v-model="wCategoryFrame.tableStatus"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value=1
                :inactive-value=0
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col
            :span="24"
            style="text-align: center"
          >
            <el-button
              type="primary"
              @click="wDoEditCategory()"
            >保存</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import wPageCrumbs from "@/components/layout/PageCrumbs.vue";
import { browser } from "@/plugins/utils.js";
import {
  addTable,
  deleteTable,
  editTable,
  QrCodeImage,
  store_storeUpdate,
  store_getTableBysid
} from "@/network/wApi.js";
export default {
  components: {
    wPageCrumbs
  },
  data() {
    return {
      wStoreId: null, // 门店id
      storeName: null, // 门店名称

      // 栏目
      wColumn: {
        value: 0,
        options: [
          {
            id: 0,
            title: "全部"
          }
        ]
      },
      // 商品分类数据
      wCategory: {
        page: 1,
        limit: 10,
        total: 0,
        list: []
      },
      wCategoryFrame: {
        isShow: false, // 是否显示
        id: null,
        tableName: "",
        tableSerial: "",
        tableStatus: 1
      }
    };
  },
  created() {
    // 判断是否存在门店id
    this.wStoreId = browser.getLocationOption("sid");
    let name = browser.getLocationOption("name");
    this.storeName = decodeURI(name);
    console.log("storeName", this.storeName);
    if (this.wStoreId) {
      // Promise.allSettled()方法接受一组 Promise 实例作为参数，包装成一个新的 Promise 实例。只有等到所有这些参数实例都返回结果，不管是fulfilled还是rejected，包装实例才会结束。
      const allSettledPromise = Promise.allSettled([]);
      allSettledPromise.then(results => {
        console.log(results);
        // 获取门店桌子列表数据
        this.wGetStoreList();
      });
    } else {
      this.$message({
        message: "该门店不存在~",
        type: "error"
      });
      // 返回上一页
      let setT = setTimeout(() => {
        this.$router.back();
        clearTimeout(setT);
      }, 800);
    }
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    /**
     * wDoChoiceColumn
     * 栏目选择
     */
    wDoChoiceColumn(wP_id) {
      this.wColumn.value = wP_id;
      // 获取门店列表数据
      this.wGetStoreList();
    },
    /**
     * wGetStoreList
     * 获取门店列表数据
     */
    wGetStoreList() {
      let { value: wStoreType } = this.wColumn;
      let { page, limit } = this.wCategory;

      // 请求参数
      let wData = {};

      // 门店id
      wData.storeId = this.wStoreId;

      // 门店类型 1-旗舰店 2-中心店 3-社区店
      if (wStoreType != 0) {
        wData.type = wStoreType;
      }
      wData["pageModel.pageNo"] = page;
      wData["pageModel.pageSize"] = limit;
      // wData["pageModel.sortField"] = "id";
      // wData["pageModel.sortWay"] = "asc";

      // 请求函数
      store_getTableBysid(wData).then(res => {
        // 更新数据
        this.wCategory.total = res.total;
        this.wCategory.list = res.records;
      });

      // 请求函数
      // this.$api.store_list({
      //   data: wData,
      //   success: (res) => {
      //     console.log(res);

      //     // 更新数据
      //     this.wCategory.total = res.total;
      //     this.wCategory.list = res.records;
      //   },
      // });
    },
    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发 - 当前页
     */
    wELPageCurrentChange(wP_cPage) {
      this.wCategory.page = wP_cPage;
      // 获取门店列表数据
      this.wGetStoreList();
    },
    /**
     * wELPageSizeChange
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      this.wCategory.page = 1;
      this.wCategory.limit = wP_cPageSize;
      // 获取门店列表数据
      this.wGetStoreList();
    },
    // 添加桌位
    addClassify() {
      this.wCategoryFrame.isShow = true;
    },
    /**
     * wDoTableBtn
     * 表格操作按钮
     */
    wDoTableBtn(wP_scope, wP_type) {
      let wIndex = wP_scope.$index;
      switch (wP_type) {
        case `business`:
          {
            // 门店开业与关业的管理
            let { id } = wP_scope.row;
            // 请求函数
            this.$api.store_operateBusiness({
              data: {
                id: id
              },
              success: res => {
                console.log(res);
                this.$message({
                  type: "success",
                  message: "修改成功!"
                });
                this.wCategory.list[wIndex].openOrClose = res.openOrClose;
                this.wCategory.list[wIndex].openOrCloseName =
                  res.openOrCloseName;
              }
            });
          }
          break;

        case `delete`:
          {
            // 桌台删除
            let { id } = wP_scope.row;
            this.$confirm("此操作将永久删除该桌台, 是否继续?", "删除桌台", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                // 请求函数
                deleteTable({
                  id: id
                }).then(res => {
                  this.wGetStoreList();
                  this.$message({
                    type: "success",
                    message: "删除成功!"
                  });
                  // },
                  // fail: (err) => {
                  //   this.$message({
                  //     type: "error",
                  //     message: "删除失败!",
                  //   });
                  // },
                });
              })
              .catch(() => {});
          }
          break;

        case `edit`:
          // 修改
          {
            // 修改数据
            this.wCategoryFrame.id = wP_scope.row.id;
            this.wCategoryFrame.tableName = wP_scope.row.tableName;
            this.wCategoryFrame.tableSerial = wP_scope.row.tableSerial;
            this.wCategoryFrame.tableStatus = wP_scope.row.tableStatus;

            // 打开修改弹框
            this.wCategoryFrame.isShow = true;
          }
          break;

        case `code`:
          {
            let { storeId } = wP_scope.row;
            let { storeName } = wP_scope.row;
            let { id } = wP_scope.row;
            let { tableSerial } = wP_scope.row;
            this.$confirm(
              "此操作将重新生成该桌台二维码, 是否继续?",
              "生成桌码",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
              }
            )
              .then(() => {
                // 请求函数
                QrCodeImage({
                  storeId: storeId, // 门店ID
                  storeName: storeName, // 门店名称
                  id: id, // 客桌ID
                  tableSerial: tableSerial // 客桌桌号
                }).then(res => {
                  this.wGetStoreList();
                  this.$message({
                    type: "success",
                    message: "生成成功!"
                  });
                  // },
                  // fail: (err) => {
                  //   this.$message({
                  //     type: "error",
                  //     message: "删除失败!",
                  //   });
                  // },
                });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消生成"
                });
              });
          }
          break;

        default:
          this.$message("功能待开发~");
          break;
      }
    },

    /**
     * wDoUpdateStore
     * 更新门店数据
     */
    wDoUpdateStore() {
      // 请求函数
      store_storeUpdate().then(res => {
        this.$message({
          message: "门店数据更新成功~",
          type: "success"
        });
        // 获取门店列表数据
        this.wGetStoreList();
      });
    },
    wELDialogClose() {
      // 关闭修改弹框
      this.wCategoryFrame.isShow = false;
      this.wCategoryFrame.id = null;
      this.wCategoryFrame.tableName = "";
      this.wCategoryFrame.tableSerial = "";
      this.wCategoryFrame.tableStatus = 1;
    },
    wDoEditCategory() {
      // 请求参数
      let wData = {};
      //店铺ID
      wData.storeId = this.wStoreId;
      wData.storeName = this.storeName;
      // 客桌信息
      let {
        id: id,
        tableName: tableName,
        tableSerial: tableSerial,
        tableStatus: tableStatus
      } = this.wCategoryFrame;

      // 桌号ID
      wData.id = id + "";

      wData.tableName = tableName;
      wData.type = 1;
      wData.tableSerial = tableSerial;
      wData.tableStatus = tableStatus;
      // 请求函数
      // wData.id?editTable(wData).then
      // ((res) => {
      //   this.$message({
      //     message: `修改成功！`,
      //     type: `success`,
      //   });
      //   // 关闭修改弹框
      //   this.wCategoryFrame.isShow = false;
      //   this.wCategoryFrame.id = null;
      //   this.wCategoryFrame.tableName = null;
      //   this.wCategoryFrame.tableSerial = null;
      //   this.wCategoryFrame.tableStatus = null;
      //   // 查询商品分类列表
      //   this.wGetStoreList();
      // }):
      addTable(wData).then(res => {
        this.$message({
          message: `添加成功！`,
          type: `success`
        });
        // 关闭修改弹框
        this.wCategoryFrame.isShow = false;
        this.wCategoryFrame.id = null;
        this.wCategoryFrame.tableName = null;
        this.wCategoryFrame.tableSerial = null;
        this.wCategoryFrame.tableStatus = null;
        // 查询列表
        this.wGetStoreList();
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 商品分类区域
.wGC-categoryArea {
  display: block;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  background-color: #ffffff;

  .wGC-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    // 栏目
    .wGCH-column {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      user-select: none;

      .wGCHC-item {
        flex: none;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        margin-right: 10px;
        box-sizing: border-box;
        background-color: #f3f3f3;
        // border: 1px solid #999999;
        font-size: @wFontSize;
        cursor: pointer;
        transition: all 0.3s;

        &:hover,
        &.wActive {
          background-color: @wColor_theme;
          border-color: @wColor_theme;
          color: #ffffff;
        }
      }
    }
  }

  // 商品分类数据
  .wGC-categoryList {
    display: block;
    width: 100%;

    // table表
    .wGLG-table {
      display: block;
      width: 100%;

      .isOpenOrClose {
        color: @wColor_theme;
      }
    }

    // 分页
    .wGLG-page {
      display: block;
      width: 100%;
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>