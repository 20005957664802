import wRequest from "./wRequest";
/**
 * 模块-XXX
 * wApi_XXX
 */
// XXXXXXX
export function wApi_XXX_XXXXX(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : ''; // 存放接口
  return wRequest(wP_obj);
}

/**
 * 模块-会员
 * wApi_member
 */
// 查询我的团队
export function wApi_member_queryMyTeam(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/queryTeam'; // 存放接口
  return wRequest(wP_obj);
}
// 分页查询认缴申请记录
export function wApi_member_queryApplyPage(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/queryApplyPage'; // 存放接口
  return wRequest(wP_obj);
}
// 查询用户身份等级
export function wApi_member_queryPartnerIdentity(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/listPartnerIdentityConfig'; // 存放接口
  return wRequest(wP_obj);
}
// 查询用户可以认缴的身份
export function wApi_member_queryIdentityIsUsed(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/listSubscribeIdentity'; // 存放接口
  return wRequest(wP_obj);
}
// 查询冠名桌子类型
export function wApi_member_queryVipGoodsTypeEnum(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/table/queryVipGoodsTypeEnum'; // 存放接口
  return wRequest(wP_obj);
}
// 查询桌子类型配置
export function wApi_member_getTableConfigList(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/table/getTableConfigList'; // 存放接口
  return wRequest(wP_obj);
}
// 添加认缴申请
export function wApi_member_addPartnerSubscribedApplyRecord(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/addPartnerSubscribedApplyRecord'; // 存放接口
  return wRequest(wP_obj);
}
// 认缴申请状态变更
export function wApi_member_upDatePartnerSubscribedApplyStateById(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/upDatePartnerSubscribedApplyStateById'; // 存放接口
  return wRequest(wP_obj);
}
// 添加-减少 用户测评积分
export function wApi_member_updatePartnerAssessScore(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/updatePartnerAssessScore'; // 存放接口
  return wRequest(wP_obj);
}
// 添加-减少 用户积分  全部积分类型
export function wApi_member_queryIntegralType(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/listPartnerIntegral'; // 存放接口
  wP_obj.headers = wP_obj.headers ? wP_obj.headers : {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  return wRequest(wP_obj);
}
// 添加-减少 用户余额
export function wApi_member_updatePartnerBalance(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/updatePartnerBalance'; // 存放接口
  return wRequest(wP_obj);
}
// 添加-减少 用户积分
export function wApi_member_updatePartnerIntegration(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/updatePartnerIntegration'; // 存放接口
  return wRequest(wP_obj);
}
// 添加-减少 用户股份
export function wApi_member_updatePartnerShare(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/updatePartnerShare'; // 存放接口
  return wRequest(wP_obj);
}
// 修改用户基本信息
export function wApi_member_updateUserInfo(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/updateUserInfo'; // 存放接口
  return wRequest(wP_obj);
}
// 添加-减少 用户业绩 查询用户业绩列表
export function wApi_member_queryAchievementsType(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/listPartnerPerformance'; // 存放接口
  wP_obj.headers = wP_obj.headers ? wP_obj.headers : {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  return wRequest(wP_obj);
}
// 添加-减少 用户业绩
export function wApi_member_updatePartnerPerformance(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/updatePartnerPerformance'; // 存放接口
  return wRequest(wP_obj);
}
// 查询用户测评积分记录
export function wApi_member_queryPointsRecords(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/listPartnerAssessScoreRecord'; // 存放接口
  return wRequest(wP_obj);
}
// 查询全部需要绑定券的配置
export function wApi_member_couponTypeList(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/coupon/couponTypeList'; // 存放接口
  return wRequest(wP_obj);
}
// 用户添加优惠券
export function wApi_member_addCouponByUserId(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/coupon/addCouponByUserId'; // 存放接口
  wP_obj.timeout = (wP_obj.timeout ? wP_obj.timeout : 90000)
  return wRequest(wP_obj);
}


/**
 * 模块-订单
 * wApi_order
 */

// 订单管理列表
export function orderListByStore(wP_obj) {
  // wP_obj || (wP_obj = {});
  (wP_obj && Object.prototype.toString.call(wP_obj) === '[object Object]') || (wP_obj = {}); // 判断参数是否是对象
  wP_obj.url = wP_obj.url ? wP_obj.url : '/noodle/order/list'; // 存放接口
  // wP_obj.headers = wP_obj.headers ? wP_obj.headers : {
  //   'Content-Type': 'application/x-www-form-urlencoded',
  // };
  return wRequest(wP_obj);
}