<template>
  <!-- 分销明细 -->
  <div>
    <div class="wMember-distributionRecords">
      <div class="wMDR-head">
        <div class="wMDRH-list">
          <template v-for="item in wCategory.options">
            <div
              class="wMDRH-item"
              :class="{ wActive: wCategory.value == item.id }"
              :key="item.id"
              @click="wChangeCategory(item.id)"
            >
              {{ item.label }}
            </div>
          </template>
        </div>
      </div>
      <div class="wMDR-line"></div>
      <div class="wMFR-body">
        <!-- table表格 -->
        <div class="wMFRB-table">
          <el-table
            :data="wDRecords.list"
            border
            style="width: 100%"
            :header-cell-style="headerCellStyle"
          >
            <el-table-column
              prop="date"
              label="账单图片"
              align="center"
              width="150"
            >
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.goodsHeadImg"
                  fit="cover"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="goodsName"
              label="账单名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="orderMoney"
              label="付款金额"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="commissionMoney"
              label="预估收入"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="proportion"
              label="提成比例"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="payTime"
              label="时间"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="付款人"
              align="center"
            >
              <template slot-scope="scope">
                <div class="wMFRBT-payUser">
                  <el-avatar
                    size="medium"
                    :src="scope.row.avatar"
                  ></el-avatar>
                  <div class="wMFRBT-payUName">
                    {{ scope.row.nickName }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="wPagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="wDRecords.page"
            :page-size="wDRecords.limit"
            :total="wDRecords.total"
            :page-sizes="[5, 10, 15, 20, 50]"
            :hide-on-single-page="false"
            @current-change="wELPageCurrentChange"
            @size-change="wELPageSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { member_getDistributionRecords } from "@/network/wApi.js";

export default {
  data() {
    return {
      // 分类
      wCategory: {
        value: 1,
        options: [
          {
            id: 1,
            label: "待到账"
          },
          {
            id: 2,
            label: "已到账"
          },
          {
            id: 3,
            label: "退款订单"
          }
        ]
      },

      // 分销明细
      wDRecords: {
        userId: null, // 用户id
        page: 1, // 页码
        limit: 10, // 每页条数
        total: 0, // 总计多少条数据
        list: [] // 数据
      }
    };
  },
  computed: {
    // 设置表头样式
    headerCellStyle() {
      return {
        background: "#EEEEEE"
      };
    }
  },
  created() {
    // 用户id
    let userId = this.$route.query.userid;
    if (userId) {
      this.wDRecords.userId = userId;
    } else {
      this.$message.error("系统繁忙，请稍后再试!");
    }
    // 分类
    let type = this.$route.query.type;
    let wTypeIndex = this.wCategory.options.findIndex(item => {
      return item.id == type;
    });
    this.wCategory.value =
      wTypeIndex == -1 ? this.wCategory.options[0].id : type;

    this.$nextTick(() => {
      // 根据用户id来获取分销记录
      this.wGetDistributionRecordsByUserId();
    });
  },
  methods: {
    /**
     * wChangeCategory
     * 栏目切换
     * wP_id
     */
    wChangeCategory(wP_id) {
      this.wCategory.value = wP_id;
      this.wDRecords.page = 1;

      this.$nextTick(() => {
        // 根据用户id来获取分销记录
        this.wGetDistributionRecordsByUserId();
      });
    },
    /**
     * wGetDistributionRecordsByUserId
     * 根据用户id来获取分销记录
     */
    wGetDistributionRecordsByUserId() {
      // 请求参数
      let wData = {};
      // 获取值
      let { value: wC_type } = this.wCategory;
      let { userId: wDR_userId, page, limit } = this.wDRecords;

      // 数据处理-状态:1-待到账 2-已到账 3-已退款
      switch (`${wC_type}`) {
        case "1":
          wData.state = 1;
          break;
        case "2":
          wData.state = 2;
          break;
        case "3":
          wData.state = 3;
          break;

        default:
          this.$message.error(`状态值错误:${wC_type}`);
          break;
      }
      wData.userId = wDR_userId;
      wData["pageModel.pageNo"] = page;
      wData["pageModel.pageSize"] = limit;
      // 请求函数
      member_getDistributionRecords(wData).then(res => {
        // 更新数据
        this.wDRecords.total = res.total;
        this.wDRecords.list = res.records;
      });
    },

    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange(wP_cPage) {
      // 更改当前页码数据
      this.wDRecords.page = wP_cPage;
      // // 获取列表数据
      this.$nextTick(() => {
        // 根据用户id来获取分销记录
        this.wGetDistributionRecordsByUserId();
      });
    },

    /**
     * wELPageSizeChange
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      // 更改当前页码数据
      this.wDRecords.page = 1;
      this.wDRecords.limit = wP_cPageSize;
      // // 获取列表数据
      this.$nextTick(() => {
        // 根据用户id来获取分销记录
        this.wGetDistributionRecordsByUserId();
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 分销明细
.wMember-distributionRecords {
  display: block;
  width: 100%;
  padding: 40px 60px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.wMDR-head {
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: space-between;

  .wMDRH-list {
    flex: none;
    display: flex;
    align-items: center;
    background: #eeeeee;

    .wMDRH-item {
      flex: none;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #dddddd;
      cursor: pointer;
      font-size: 16rpx;
      font-weight: 400;
      color: #666666;

      &.wActive,
      &:hover {
        background-color: #ff6321;
        border-color: #ff6321;
        color: #ffffff;
      }
    }
  }
}

.wMDR-line {
  display: block;
  width: 100%;
  height: 1px;
  margin: 20px 0 36px;
  background-color: #dddddd;
}

.wMFR-body {
  display: block;
  width: 100%;

  // table表格
  .wMFRB-table {
    display: block;
    width: 100%;

    .wMFRBT-payUser {
      display: flex;
      align-items: center;

      .wMFRBT-payUName {
        height: 22px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-left: 10px;
      }
    }
  }
}
</style>