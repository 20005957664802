<template>
  <!-- 会员列表 => 提现审核 -->
  <div>
    <!-- 页面布局 -->
    <!-- <wPageCrumbs> -->
    <!-- 筛选查询 -->
    <div class="wML-screen">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>筛选查询</span>
        </div>
        <!-- 筛选列表 -->
        <div class="wMLS-list">
          <el-form
            ref="wScreenForm"
            label-position="left"
            label-width="90px"
            :model="wScreen"
          >
            <el-row :gutter="20">
              <!-- 手机号 -->
              <el-col :span="6" v-if="false">
                <el-form-item label="手机号" prop="phone">
                  <el-input
                    style="width: 100%; max-width: 200px"
                    type="text"
                    v-model="wScreen.phone"
                    maxlength="11"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 合伙人等级 -->
              <el-col :span="6" v-if="false">
                <el-form-item label="合伙人等级" prop="level.value">
                  <el-select
                    v-model="wScreen.level.value"
                    placeholder="全部合伙人"
                  >
                    <el-option
                      v-for="item in wScreen.level.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 提现类型 -->
              <el-col :span="6">
                <el-form-item label="提现类型" prop="type.value" v-show="false">
                  <el-select
                    v-model="wScreen.type.value"
                    placeholder="全部类型"
                    @change="wchange({ wType: 'wS_type' })"
                  >
                    <el-option
                      v-for="item in wScreen.type.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 审核状态 -->
              <el-col :span="6">
                <el-form-item label="审核状态" prop="state.value">
                  <el-select
                    v-model="wScreen.state.value"
                    placeholder="全部状态"
                    @change="wchange({ wType: 'wS_state' })"
                  >
                    <el-option
                      v-for="item in wScreen.state.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 创建时间 -->
              <el-col :span="6" v-if="false">
                <el-form-item label="创建时间" prop="timeGroup">
                  <el-date-picker
                    style="width: 100%"
                    v-model="wScreen.timeGroup"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 操作按钮 -->
              <el-col :span="24">
                <el-button size="medium" @click="wResetForm"> 重置 </el-button>
                <el-button size="medium" @click="wSubmitForm"> 查询 </el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-card>
    </div>

    <!-- 提现审核数据列表 -->
    <div class="wMW-withdrawal">
      <!-- table表 -->
      <div class="wML-table">
        <el-table :data="wWithdrawal.list" border style="width: 100%">
          <el-table-column align="center" label="头像">
            <template slot-scope="scope">
              <el-avatar :src="scope.row.avatar"></el-avatar> </template
          ></el-table-column>
          <el-table-column align="center" label="昵称" prop="nickName">
          </el-table-column>
          <el-table-column align="center" label="手机号" prop="phone">
          </el-table-column>
          <el-table-column align="center" label="会员等级" prop="identityName">
            <template slot-scope="scope">
              <span v-html="scope.row.identityName"></span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="申请金额" prop="money">
          </el-table-column>
          <el-table-column align="center" label="手续费" prop="charge">
          </el-table-column>
          <el-table-column align="center" label="实际到账金额" prop="realMoney">
          </el-table-column>
          <el-table-column align="center" label="提现类型" v-if="false">
            <template slot-scope="scope">
              <div>{{ scope.row.type == 1 ? "小额提现" : "大额提现" }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="提现账号" v-if="false">
            <template slot-scope="scope">
              <div v-show="scope.row.type == 2">
                {{
                  scope.row.way == 1
                    ? "微信"
                    : scope.row.way == 2
                    ? "支付宝"
                    : ""
                }}
              </div>
              <div v-show="scope.row.type == 2">
                {{ scope.row.accountNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="提现方式">
            <template slot-scope="scope">
                {{
                  scope.row.way == 1
                    ? "微信"
                    : scope.row.way == 2
                    ? "支付宝"
                    : ""
                }}
            </template>
          </el-table-column>
          <el-table-column prop="accountNumber" align="center" label="提现账号">
          </el-table-column>
          <el-table-column
            align="center"
            label="申请时间"
            prop="applicationTime"
            width="200px"
          >
          </el-table-column>
          <el-table-column align="center" label="审核状态">
            <template slot-scope="scope">
              <el-button
                type="primary"
                v-if="scope.row.state == 1"
                @click="wDoExamine(scope)"
                >审核</el-button
              >
              <el-link
                type="success"
                :underline="false"
                v-if="scope.row.state == 2"
                >审核已通过</el-link
              >
              <el-link
                type="info"
                :underline="false"
                v-if="scope.row.state == 3"
                >审核已拒绝</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="wPagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="wWithdrawal.page"
          :page-size="wWithdrawal.limit"
          :total="wWithdrawal.total"
          :page-sizes="[5, 10, 15, 20, 50]"
          :hide-on-single-page="false"
          @current-change="wELPageCurrentChange"
          @size-change="wELPageSizeChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- </wPageCrumbs> -->

    <!-- 审核弹框 -->
    <el-dialog
      title="审核"
      :visible.sync="wPayment.isShow"
      width="400px"
      append-to-body
    >
      <el-form ref="wPaymentForm" :model="wPayment" label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="头像:" prop="imgHP">
              <el-avatar size="large" :src="wPayment.imgHP"></el-avatar>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="名称:" prop="name">
              <span>{{ wPayment.name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="申请金额:" prop="money_SQ">
              <span>{{ wPayment.money_SQ }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="税点:" prop="money_SD">
              <span>{{ wPayment.money_SD }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="手续费:" prop="money_SXF">
              <span>{{ wPayment.money_SXF }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="实际到账金额:" prop="money_DZ">
              <span>{{ wPayment.money_DZ }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="审核选项:" prop="state">
              <template>
                <el-radio v-model="wPayment.state" label="2">通过</el-radio>
                <el-radio v-model="wPayment.state" label="3">拒绝</el-radio>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="审核说明:" prop="describe">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="wPayment.describe"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="text-align: center">
            <el-button type="primary" @click="wDoWithdrawal">确定</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import wPageCrumbs from "@/components/layout/PageCrumbs.vue";
import {
  member_withdrawalList,
  member_withdrawalExamine,
} from "@/network/wApi.js";
export default {
  components: {
    wPageCrumbs,
  },
  data() {
    return {
      // 筛选查询
      wScreen: {
        phone: "",
        timeGroup: "",
        level: {
          value: "",
          options: [
            {
              value: "1",
              label: "火锅达人",
            },
            {
              value: "2",
              label: "普通合伙人",
            },
            {
              value: "3",
              label: "中级合伙人",
            },
            {
              value: "4",
              label: "高级合伙人",
            },
            {
              value: "5",
              label: "分公司",
            },
          ],
        },
        state: {
          value: "",
          options: [
            {
              value: "",
              label: "全部状态",
            },
            {
              value: "1",
              label: "待审核",
            },
            {
              value: "2",
              label: "已通过",
            },
            {
              value: "3",
              label: "已拒绝",
            },
          ],
        },
        type: {
          value: "0",
          options: [
            {
              value: "0",
              label: "全部类型",
            },
            {
              value: "1",
              label: "普通提现",
            },
            {
              value: "2",
              label: "大额提现",
            },
          ],
        },
      },
      // 提现审核数据
      wWithdrawal: {
        page: 1,
        limit: 10,
        total: 0,
        list: [],
      },

      // 认缴弹框
      wPayment: {
        isShow: false, // 是否展示
        id: null, // 主键
        imgHP: "", // 用户头像
        name: "", // 用户昵称
        money_SQ: "", // 申请金额
        money_SD: "", // 税点
        money_SXF: "", // 手续费
        money_DZ: "", // 实际到账金额
        state: "2", // 审核选项，2-通过，3-拒绝
        describe: "", // 审核说明
      },
    };
  },
  created() {
    // 获取提现审核列表数据
    this.wGetWithdrawalList();
  },
  methods: {
    /**
     * wResetForm
     * 重置表单数据
     */
    wResetForm() {
      this.$refs["wScreenForm"].resetFields();
      this.wWithdrawal.page = 1;
      this.$nextTick(() => {
        // 获取提现审核列表数据
        this.wGetWithdrawalList();
      });
    },
    /**
     * wSubmitForm
     * 提交表单数据
     */
    wSubmitForm() {
      this.wWithdrawal.page = 1;
      this.$nextTick(() => {
        // 获取提现审核列表数据
        this.wGetWithdrawalList();
      });
    },
    /**
     * wGetWithdrawalList
     * 获取提现审核列表数据
     */
    wGetWithdrawalList() {
      // 请求参数
      let wData = {};

      // 筛选条件
      let {
        state: { value: wW_state },
        type: { value: wW_type },
      } = this.wScreen;

      // 提现类型 类型 1-普通提现 2-大额提现
      switch (`${wW_type}`) {
        case "0":
          // 全部类型
          break;
        case "1":
          // 1-普通提现
          wData.type = 1;
          break;
        case "2":
          // 2-大额提现
          wData.type = 2;
          break;

        default:
          break;
      }

      // 状态
      switch (`${wW_state}`) {
        case "1":
          // 1.待审核
          wData.state = 1;
          break;
        case "2":
          // 2.审核通过
          wData.state = 2;
          break;
        case "3":
          // 3.审核拒绝
          wData.state = 3;
          break;

        default:
          break;
      }

      // 提现审核数据
      let { page, limit } = this.wWithdrawal;

      // 分页数据
      wData["pageModel.pageNo"] = page;
      wData["pageModel.pageSize"] = limit;
      // wData["pageModel.sortField"] = 'id';
      // wData["pageModel.sortWay"] = 'asc';

      // 请求函数
      member_withdrawalList(wData).then((res) => {
        // 数据处理
        res.records.map((item, index) => {
          item.locked = !item.locked;
        });

        // 更新数据
        this.wWithdrawal.total = res.total;
        this.wWithdrawal.list = res.records;
      });
    },
    /**
     * wDoExamine
     * 审核操作
     */
    wDoExamine(wP_scope) {
      console.log(wP_scope);
      // 更新数据
      // wPayment: {
      //   isShow: false, // 是否展示
      //   id: null, // 主键
      //   imgHP: "", // 用户头像
      //   name: "", // 用户昵称
      //   money_SQ: "", // 申请金额
      //   money_SD: "", // 税点
      //   money_SXF: "", // 手续费
      //   money_DZ: "", // 实际到账金额
      //   state: "2", // 审核选项，2-通过，3-拒绝
      //   describe: "", // 审核说明
      // },
      this.wPayment.isShow = true;
      this.wPayment.id = wP_scope.row.id;
      this.wPayment.imgHP = wP_scope.row.avatar;
      this.wPayment.name = wP_scope.row.nickName;
      this.wPayment.money_SQ = wP_scope.row.money;
      this.wPayment.money_SD = wP_scope.row.taxPoint;
      this.wPayment.money_SXF = wP_scope.row.charge;
      this.wPayment.money_DZ = wP_scope.row.realMoney;
      this.wPayment.state = "2";
      this.wPayment.describe = "";
    },
    /**
     * wDoWithdrawal
     * 提现审核
     */
    wDoWithdrawal() {
      // 请求参数
      let wData = {};

      // 表单
      let { id, state, describe } = this.wPayment;
      wData.id = id;
      wData.state = state;
      if (state == 3 && describe.trim() == "") {
        this.$message({
          message: "请填写拒绝说明！",
          type: "warning",
        });
        return;
      }
      wData.reason = describe;

      // 确认弹框 // 审核选项，2-通过，3-拒绝
      this.$confirm(
        `此操作将${state == 2 ? "通过" : "拒绝"}提现申请, 是否继续`,
        "审核",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        // 请求函数
        member_withdrawalExamine(wData)
          .then((res) => {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            // 获取提现审核列表数据
            this.wGetWithdrawalList();
            // 关闭弹框
            this.wPayment.isShow = false;
          })
          .catch(() => {});
      });
    },
    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange(wP_cPage) {
      this.wWithdrawal.page = wP_cPage;
      this.$nextTick(() => {
        // 获取提现审核列表数据
        this.wGetWithdrawalList();
      });
    },
    /**
     * wELPageSizeChange
     * 修改推荐人分页
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      // 更改当前页码数据
      this.wWithdrawal.page = 1;
      this.wWithdrawal.limit = wP_cPageSize;
      this.$nextTick(() => {
        // 获取提现审核列表数据
        this.wGetWithdrawalList();
      });
    },

    /**
     * wchange
     * 标签内置事件
     * @param {Object} wP_obj 参数集合
     * @param {String} wP_obj.wType 类型，用于区分操作对象
     */
    wchange(wP_obj) {
      let [{ wType }] = [wP_obj];
      switch (wType) {
        case "wS_type":
        // 提现类型
        case "wS_state":
          // 审核状态
          {
            this.wWithdrawal.page = 1;
            this.$nextTick(() => {
              // 获取提现审核列表数据
              this.wGetWithdrawalList();
            });
          }
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
// 筛选查询
.wML-screen {
  display: block;
  width: 100%;
  user-select: none;
  margin-bottom: 20px;

  // 筛选列表
  .wMLS-list {
    display: block;
    width: 100%;
  }
}

// 提现审核数据列表
.wMW-withdrawal {
  display: block;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  background-color: #ffffff;
}
</style>